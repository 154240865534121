var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "button",
      staticClass: "actions-list__action bookmark-action  btn--text",
      class: _vm.textMode ? "" : "btn",
      attrs: {
        tabindex: "0",
        "data-cy": "actions-list-bookmark",
        "aria-label": "bookmark action",
        href: "#"
      },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.toggleBookmark($event)
        },
        click: _vm.toggleBookmark
      }
    },
    [
      _vm.bookmarked
        ? [
            _c("i", {
              staticClass: "bookmark-action__toggle-icon--full fas fa-bookmark",
              attrs: { "data-jest": "unbookmark-icon" }
            }),
            _vm._v(" "),
            _vm.textMode
              ? _c("span", [
                  _vm._v(_vm._s(_vm._f("trans")("action.unbookmark")))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-tooltip",
              {
                attrs: {
                  target: function() {
                    return _vm.$refs.button
                  },
                  disabled: _vm.screenWidth <= 900 || !_vm.showTooltip,
                  delay: { show: 500, hide: 0 },
                  triggers: "hover"
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("trans")("action.unbookmark")) +
                    "\n        "
                )
              ]
            )
          ]
        : [
            _c("i", {
              staticClass: "bookmark-action__toggle-icon--open far fa-bookmark",
              attrs: { "data-jest": "bookmark-icon" }
            }),
            _vm._v(" "),
            _vm.textMode
              ? _c("span", [_vm._v(_vm._s(_vm._f("trans")("action.bookmark")))])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-tooltip",
              {
                attrs: {
                  target: function() {
                    return _vm.$refs.button
                  },
                  disabled: _vm.screenWidth <= 900 || !_vm.showTooltip,
                  delay: { show: 500, hide: 0 }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("trans")("action.bookmark")) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "modal-component",
              {
                staticClass: "bookmark-action__confirm-modal",
                attrs: { show: _vm.isModalVisible, "data-jest": "modal" },
                on: { close: _vm.hideModal }
              },
              [
                _c("template", { slot: "title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("trans")("bookmark.category.name")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "content" },
                  [
                    _c(
                      "v-select",
                      {
                        attrs: {
                          options: _vm.categories,
                          placeholder: _vm._f("trans")(
                            "bookmark.category.placeholder"
                          ),
                          dir: _vm.dir,
                          taggable: "",
                          "push-tags": "",
                          "select-on-tab": ""
                        },
                        on: { input: _vm.input }
                      },
                      [
                        _c("template", { slot: "no-options" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "bookmark.category.no_categories"
                                )
                              ) +
                              "\n                    "
                          )
                        ])
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.hasValidationErrors && _vm.validate
                      ? _c(
                          "span",
                          {
                            staticClass: "bookmark-action__error error-text",
                            attrs: { "data-jest": "validation-error" }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("trans")("bookmark.category.error")
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--secondary",
                    attrs: { type: "button", "data-jest": "cancel" },
                    on: { click: _vm.hideModal }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("trans")("action.cancel")) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary",
                    attrs: {
                      type: "button",
                      disabled:
                        _vm.selectedCategory === null ||
                        _vm.hasValidationErrors,
                      "data-jest": "submit"
                    },
                    on: { click: _vm.bookmark }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("trans")("action.ok")) +
                        "\n            "
                    )
                  ]
                )
              ],
              2
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }