<template>
    <div class="pagination">
        <a aria-label="pagination previous" class="pagination__link pagination__link--previous" :class="{ 'pagination__link--disabled': currentPage <= 1 }" href="#" @click="goToPage(currentPage - 1)">
            <i class="pagination__link-icon far fa-angle-left" />
        </a>

        <div class="pagination__pages">
            <template v-if="showFirstDots">
                <a class="pagination__link pagination__link--page" href="#" @click="goToPage(1)">
                    1
                </a>
                <span class="pagination__ellipsis">
                    ...
                </span>
            </template>

            <template v-for="page in pageList">
                <a
                    :aria-label="'paginate to page' + page.number"
                    :key="page.number"
                    class="pagination__link pagination__link--page"
                    :class="{ 'pagination__link--active': currentPage === page.number }"
                    href="#"
                    @click="goToPage(page.number)"
                >
                    {{ page.number }}
                </a>
            </template>

            <template v-if="showLastDots">
                <span class="pagination__ellipsis">
                    ...
                </span>
                <a :aria-label="'Go to page' + numberOfPages" class="pagination__link pagination__link--page" href="#" @click="goToPage(numberOfPages)">
                    {{ numberOfPages }}
                </a>
            </template>
        </div>

        <a aria-label="pagination next" class="pagination__link pagination__link--next" :class="{ 'pagination__link--disabled': currentPage >= numberOfPages }" href="#" @click="goToPage(currentPage + 1)">
            <i class="pagination__link-icon far fa-angle-right" />
        </a>
    </div>
</template>

<script>
    const ellipsisThreshold = 3;

    // Make an array of N to N+X
    function makePageArray(startNum, numPages) {
        return Array.from({ length: numPages }).map((value, index) => ({ number: index + startNum }));
    }

    export default {
        name: 'PaginationComponent',
        props: {
            currentPage: {
                type: Number,
                required: true,
            },
            totalRows: {
                type: Number,
                required: true,
            },
            perPage: {
                type: Number,
                required: false,
                default: 10,
            },
        },
        computed: {
            numberOfPages() {
                return Math.ceil(this.totalRows / this.perPage);
            },
            pageList() {
                let numLinks = 5;
                let startNum = 1;

                if (this.numberOfPages <= 5) {
                    // Special Case: Less pages available than the limit of displayed pages
                    numLinks = this.numberOfPages;
                } else if (this.currentPage < 5 - 1 && ellipsisThreshold < 5) {
                    // We are near the beginning of the page list
                    numLinks = 5 - 1;
                } else if (((this.numberOfPages - this.currentPage) + 2) < 5 && ellipsisThreshold < 5) {
                    // We are near the end of the list
                    numLinks = 5 - 1;
                    startNum = (this.numberOfPages - numLinks) + 1;
                } else {
                    // We are somewhere in the middle of the page list
                    if (ellipsisThreshold < 5) {
                        numLinks = 5 - 2;
                    }
                    startNum = this.currentPage - Math.floor(numLinks / 2);
                }

                // Sanity checks
                if (startNum < 1) {
                    startNum = 1;
                } else if (startNum > this.numberOfPages - numLinks) {
                    startNum = (this.numberOfPages - numLinks) + 1;
                }

                // Return generated list of page numbers
                return makePageArray(startNum, numLinks);
            },
            showFirstDots() {
                return (this.numberOfPages > 5 && this.currentPage >= 4);
            },
            showLastDots() {
                return (this.numberOfPages > 5 && this.currentPage <= (this.numberOfPages - 3));
            },
            startEllipsisThreshold() {
                return (this.numberOfPages > 5 && this.currentPage > 3);
            },
            endEllipsisThreshold() {
                return (this.numberOfPages > 5 && this.currentPage < this.numberOfPages - 3);
            },
        },
        methods: {
            goToPage(index) {
                if (this.currentPage === index || index < 1 || index > this.numberOfPages) {
                    return;
                }

                this.$emit('change', index);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .pagination {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: $gutter-m;
        margin-top: $gutter-m;

        &__pages {
            align-items: center;
            display: flex;
            margin-left: $gutter-s;
            margin-right: $gutter-s;
        }

        &__link {
            align-items: center;
            border-radius: 15px;
            color: $color-grey-darker;
            cursor: pointer;
            display: inline-flex;
            font-size: $font-s;
            justify-content: center;
            line-height: 30px;
            margin: 0 $gutter-xxs;
            min-height: 30px;
            min-width: 30px;
            padding: 0 6px;
            transition: all .2s;
            user-select: none;

            &:hover {
                background-color: $color-grey-lighter;
                color: var(--color-button-primary);
                text-decoration: none;
            }

            &--active {
                background-color: var(--color-button-primary);
                color: #fff;

                &:hover {
                    background-color: var(--color-button-primary);
                    color: #fff;
                }
            }

            &--disabled {
                cursor: not-allowed;
                opacity: .3;

                &:hover {
                    background: transparent;
                    color: #555;
                }
            }
        }

        &__link-icon {
            line-height: 30px;
        }

        &__ellipsis {
            align-items: center;
            border-radius: 15px;
            color: $color-grey-darker;
            display: inline-flex;
            font-size: $font-s;
            justify-content: center;
            line-height: 30px;
            min-height: 30px;
            min-width: 20px;
        }
    }
    .square-pagination {
        float: right;
        margin-right: 95px;
        .pagination__link {
            align-items: center ;
            /* border-radius: 15px ; */
            color: #555 ;
            line-height: 30px ;
            margin: 1px 6px ;
            padding: 0px 6px ;
            border: 1px solid #EFEFEF ;
            margin-bottom: 10px ;
            background: #ffffff ;
            border-radius: 5px ;
            font-size: 15px ;
        }
        .pagination__link--active, .pagination__link--active:hover {
            background-color: var(--color-primary) ;
            color: white ;
        }
    }

</style>

<style lang="scss">
    [dir='rtl'] {
        .pagination {
            &__link {
                &--previous,
                &--next {
                    transform: rotate(180deg);
                }
            }
        }
    }
</style>
