var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filters.length > 1 && _vm.activeFilter
    ? _c(
        "div",
        { staticClass: "custom-filter" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "custom-filter__dropdown-container",
              attrs: {
                variant: "link",
                right: "",
                "toggle-class": "btn--sm btn--soft"
              }
            },
            [
              _c("template", { slot: "button-content" }, [
                _vm.showIcon
                  ? _c("i", {
                      staticClass:
                        "custom-filter__dropdown-filter-icon fas fa-filter"
                    })
                  : _vm._e(),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.activeFilter.name) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.filters, function(filter) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: filter.id,
                    attrs: {
                      "data-cy":
                        "filter-" + (filter.id ? filter.id : filter.name),
                      "data-jest": "filter"
                    },
                    on: {
                      click: function($event) {
                        return _vm.changeFilter(filter)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(filter.name) + "\n        "
                    )
                  ]
                )
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }