var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usergroup-labels" }, [
    _c("i", { staticClass: "usergroup-labels__icon fa fa-users" }),
    _vm._v(" "),
    _c(
      "ol",
      { staticClass: "usergroup-labels__list" },
      _vm._l(_vm.usergroups, function(usergroup) {
        return _c(
          "li",
          { key: usergroup.id, staticClass: "usergroup-labels__item" },
          [_vm._v("\n            " + _vm._s(usergroup.name) + "\n        ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }