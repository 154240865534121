var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "actions-list__action documents-action" },
    [
      _c(
        "b-dropdown",
        {
          staticClass: "documents-action__dropdown",
          attrs: {
            variant: "link",
            "no-caret": "",
            "toggle-class":
              "documents-action__toggle btn btn--text btn--action",
            "data-cy": "actions-list-documents",
            "aria-label": "document action"
          }
        },
        [
          _c("template", { slot: "button-content" }, [
            _c("i", {
              staticClass:
                "actions-list__action-icon documents-action__action-icon fas fa-paperclip"
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "actions-list__action-count documents-action__action-count",
                attrs: { "data-jest": "count" }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.documents.length) +
                    "\n            "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.documents, function(document, index) {
            return _c(
              "b-dropdown-item",
              {
                key: index,
                attrs: { href: document.original_url, "data-jest": "document" }
              },
              [
                _c("i", {
                  staticClass: "documents-action__dropdown-icon fas fa-file-pdf"
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "documents-action__dropdown-label",
                    attrs: { "data-jest": "filename" }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(document.original_name) +
                        "\n            "
                    )
                  ]
                )
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }