<template>
    <transition name="nps-component">
        <div v-show="isVisible" class="nps-component">
            <transition name="nps-component-page" mode="out-in">
                <div v-if="!rated" key="step-one" class="nps-component__step nps-component__step-one">
                    <p class="nps-component__question">
                        {{ 'question'|trans(translationParameters, translationDomain) }}
                    </p>

                    <nps-input :rated="rated" :rating="rating" @rate="setRating" />

                    <div class="nps-component__rating-labels">
                        <span class="nps-component__rating-label-left">
                            {{ 'not_likely'|trans(translationParameters, translationDomain) }}
                        </span>
                        <span class="nps-component__rating-label-right">
                            {{ 'extremely_likely'|trans(translationParameters, translationDomain) }}
                        </span>
                    </div>

                    <footer class="nps-component__footer">
                        <button class="nps-component__button btn btn--primary" :disabled="rated || rating === null" @click="submitRating">
                            {{ 'submit'|trans(translationParameters, translationDomain) }}
                        </button>
                        <button class="nps-component__button btn btn--text" @click="notNow">
                            {{ 'not_now'|trans(translationParameters, translationDomain) }}
                        </button>
                    </footer>
                </div>
                <div v-else-if="rated && !submittedFeedback" key="step-two" class="nps-component__step nps-component__step-two">
                    <p class="nps-component__question">
                        {{ `${npsType}_feedback`|trans(translationParameters, translationDomain) }}
                    </p>

                    <textarea v-model="feedback" class="nps-component__textarea" rows="3" title="Feedback" />

                    <footer class="nps-component__footer">
                        <button class="nps-component__button btn btn--primary" :disabled="submittedFeedback || feedback === ''" @click="submitFeedback">
                            {{ 'submit'|trans(translationParameters, translationDomain) }}
                        </button>
                        <button class="nps-component__button btn btn--text" @click="skipFeedback">
                            {{ 'skip'|trans(translationParameters, translationDomain) }}
                        </button>
                    </footer>
                </div>
                <div v-else key="step-three" class="nps-component__step nps-component__step-two">
                    <p class="nps-component__thank-you">
                        {{ 'thank_you'|trans(translationParameters, translationDomain) }}
                    </p>

                    <p class="nps-component__thank-you-description">
                        {{ `${npsType}_thank_you`|trans(translationParameters, translationDomain) }}
                    </p>

                    <footer class="nps-component__footer">
                        <button class="nps-component__button btn btn--primary" @click="closeWindow">
                            {{ 'close'|trans(translationParameters, translationDomain) }}
                        </button>
                    </footer>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
    import NpsInput from './NpsInput.vue';
    import NpsApi from './NpsApi';

    export default {
        name: 'NpsComponent',
        components: {
            NpsInput,
        },
        props: {
            scope: {
                type: String,
                required: true,
            },
            translationParameters: {
                type: Object,
                default() {
                    return {};
                },
                validator(parameters) {
                    return Object.values(parameters).every((translation) => typeof translation === 'string');
                },
            },
        },
        data() {
            return {
                rated: false,
                rating: null,
                feedback: '',
                submittedFeedback: false,
                csrfToken: document.querySelector('meta[name="cmnty-nps-csrf-token"]').content,
            };
        },
        computed: {
            isVisible() {
                return this.$store.getters['nps/isVisible'](this.scope);
            },
            translationDomain() {
                return `nps_${this.scope}`;
            },
            npsType() {
                if (!this.rated) {
                    return 'passive';
                }

                if (this.rating <= 6) {
                    return 'detractor';
                }

                if (this.rating > 8) {
                    return 'promoter';
                }

                return 'passive';
            },
        },
        created() {
            this.$translator.load(this.translationDomain);
        },
        methods: {
            setRating(rating) {
                this.rating = rating;
            },
            async submitRating() {
                if (this.rated) {
                    return;
                }

                this.rated = true;

                await this.submit({ score: this.rating });
            },
            async submitFeedback() {
                if (this.submittedFeedback) {
                    return;
                }

                this.submittedFeedback = true;

                await this.submit({ reason: this.feedback });
            },
            skipFeedback() {
                this.submittedFeedback = true;
            },
            async notNow() {
                await this.submit();

                this.rating = null;
                this.feedback = '';

                await this.closeWindow();
            },
            async closeWindow() {
                this.$store.dispatch('nps/hideQuestion', { scope: this.scope });

                if (this.rating) {
                    await this.submitRating();
                }
            },
            async submit(body = {}) {
                await NpsApi.submit({
                    scope: this.scope,
                    ...body,
                    _token: this.csrfToken,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .nps-component {
        background: #fff;
        border-radius: var(--main-border-radius);
        bottom: $gutter-m;
        box-shadow: 0 3px 15px rgba(0, 0, 0, .2);
        display: inline-flex;
        flex-direction: column;
        font-family: 'Open Sans', sans-serif;
        min-height: 250px;
        padding: $gutter-m;
        position: fixed;
        right: $gutter-m;
        width: 500px;
        z-index: 1052; // 1 Higher than the Toasts

        @media (max-width: 767px) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            bottom: 0;
            max-width: 100%;
            right: 0;
        }

        &__step {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        &__footer {
            margin-top: auto;
        }

        &__question {
            line-height: 1.5;
            margin-bottom: $gutter-l;
        }

        &__rating-labels {
            color: $color-grey-dark;
            display: flex;
            font-size: 12px;
            justify-content: space-between;
            margin-bottom: $gutter-l;
        }

        &__rating-label-left {
            text-align: left;
        }

        &__rating-label-right {
            text-align: right;
        }

        &__textarea {
            border-color: $color-grey-light;
            border-radius: 4px;
            margin-bottom: $gutter-l;
            padding: 8px;
            width: 100%;

            &:hover,
            &:active,
            &:focus {
                border-color: var(--color-primary);
                box-shadow: none;
                outline: none;
            }
        }

        &__thank-you {
            font-size: 36px;
            font-weight: 300;
            margin-bottom: $gutter-m;
        }

        &__thank-you-description {
            margin-bottom: $gutter-l;
        }

        &__button {
            align-items: center;
            cursor: pointer;
            display: inline-flex;
            margin-bottom: 0;
            touch-action: manipulation;
        }

        // Vue transition classes

        &-enter-active,
        &-leave-active {
            transition: transform .5s cubic-bezier(.175, .885, .32, 1.275);
        }

        &-enter,
        &-leave-to {
            transform: translateY(calc(100% + 20px));
        }

        &-page {
            &-enter-active,
            &-leave-active {
                transition: opacity .5s;
            }

            &-enter,
            &-leave-to {
                opacity: 0;
            }
        }
    }

    @keyframes selectRating {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }
</style>
