import AuthenticationError from './AuthenticationError';
import AuthorizationError from './AuthorizationError';

export default {
    install(Vue, { router }) {
        const oldErrorHandler = Vue.config.errorHandler;
        // eslint-disable-next-line no-param-reassign
        Vue.config.errorHandler = (error, vm, info) => {
            if (error instanceof AuthenticationError) {
                window.location.href = '/authorize/login?sess_expired=true';
            }

            if (error instanceof AuthorizationError && Vue.toaster) {
                Vue.toaster.error(Vue.translator.trans('access_denied.general'), true);
            }

            if (typeof oldErrorHandler === 'function') {
                oldErrorHandler.call(Vue, error, vm, info);
            }

            Vue.util.warn(`Error in ${info}: "${error.toString()}"`, vm);
        };

        router.onError((error) => {
            if (error instanceof AuthenticationError) {
                window.location.href = '/authorize/login?sess_expired=true';
            }

            if (error instanceof AuthorizationError && Vue.toaster) {
                Vue.toaster.error(Vue.translator.trans('access_denied.general'), true);
            }
        });

        window.addEventListener('error', ({ error }) => {
            if (error instanceof AuthenticationError) {
                window.location.href = '/authorize/login?sess_expired=true';
            }

            if (error instanceof AuthorizationError && Vue.toaster) {
                Vue.toaster.error(Vue.translator.trans('access_denied.general'), true);
            }
        });

        window.addEventListener('unhandledrejection', ({ reason: error }) => {
            if (error instanceof AuthenticationError) {
                window.location.href = '/authorize/login?sess_expired=true';
            }

            if (error instanceof AuthorizationError && Vue.toaster) {
                Vue.toaster.error(Vue.translator.trans('access_denied.general'), true);
            }
        });
    },
};
