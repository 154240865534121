import ViewScheduledAssignments from './ViewScheduledAssignments';
import ViewPastAssignments from './ViewPastAssignments';
import CanBookmarkJournal from './CanBookmarkJournal';
import CanDeleteJournal from './CanDeleteJournal';
import CanDeleteComment from './CanDeleteComment';
import CanEditComment from './CanEditComment';
import CanCreateJournal from './CanCreateJournal';
import CanEditJournal from './CanEditJournal';
import CanSubscribeJournal from './CanSubscribeJournal';
import CanAddComment from './CanAddComment';
import CanLikeComment from './CanLikeComment';
import CanLikeJournal from './CanLikeJournal';

export default {
    'view-scheduled-assignments': ViewScheduledAssignments,
    'view-past-assignments': ViewPastAssignments,
    'can-bookmark-journal': CanBookmarkJournal,
    'can-delete-journal': CanDeleteJournal,
    'can-delete-comment': CanDeleteComment,
    'can-like-comment': CanLikeComment,
    'can-edit-comment': CanEditComment,
    'can-add-comment': CanAddComment,
    'can-create-journal': CanCreateJournal,
    'can-edit-journal': CanEditJournal,
    'can-like-journal': CanLikeJournal,
    'can-subscribe-journal': CanSubscribeJournal,
};
