var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "blog-module__main-item main-item",
      class: { overlay: _vm.filterSidebarExpanded }
    },
    [
      _vm.$can("access-admin")
        ? _c(
            "div",
            { staticClass: "setting-dropdown" },
            [
              _c(
                "b-dropdown",
                {
                  ref: "actionDropdown",
                  staticClass: "documents-action__dropdown",
                  attrs: {
                    variant: "link",
                    "no-caret": "",
                    "toggle-class":
                      "documents-action__toggle btn btn--text btn--action",
                    "data-cy": "actions-list-documents"
                  }
                },
                [
                  _c("template", { slot: "button-content" }, [
                    _c("i", { staticClass: "action-icon fa-solid fa-wrench" })
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.settingsMenu, function(setting, index) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: index,
                        attrs: { href: setting.link, "data-jest": "document" }
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(setting.icon) }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "documents-action__dropdown-label",
                            attrs: { "data-jest": "filename" }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(setting.label) +
                                "\n                "
                            )
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h1",
        {
          staticClass: "blog-module__title main-item__title heading-one",
          attrs: { "data-cy": "main-item-title" }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.currentAssignment.subject) + "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("breadcrumbs-component", { attrs: { crumbs: _vm.breadcrumbs } }),
      _vm._v(" "),
      _vm.$can("access-admin") && !_vm.showUserPreview
        ? _c("journal-alert-message", {
            attrs: { content: _vm.journalAdminAlertMessage },
            on: { click: _vm.changeToUserView }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("access-admin") && _vm.showUserPreview
        ? _c("journal-alert-message", {
            attrs: { content: _vm.journalParticipantsAlertMessage },
            on: { click: _vm.changeToAdminView }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("assignment-component", {
        attrs: { "current-assignment": _vm.currentAssignment }
      }),
      _vm._v(" "),
      _vm.showJournalAdminView && !_vm.showUserPreview
        ? _c(
            "div",
            { staticClass: "journal-entries-container flex space-between" },
            [
              _c(
                "section",
                { staticClass: "journal-posts journal-overview" },
                [
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("trans")(
                            "journal.submissions",
                            {},
                            "journal_frontend"
                          )
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "journal-top-bar" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.closeFilterSideBar,
                            expression: "closeFilterSideBar"
                          }
                        ],
                        staticClass: "mobile-navigation",
                        class: {
                          "mobile-navigation--hidden": !_vm.filterSidebarExpanded
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "participant-toggle-right",
                            class: { expanded: _vm.filterSidebarExpanded }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "form__button btn btn--primary",
                                class: {
                                  "mobile-navigation__toggle--expanded":
                                    _vm.filterSidebarExpanded
                                },
                                attrs: { type: "button", tabindex: "0" },
                                on: { click: _vm.toggleNavigation }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "mobile-navigation__admin-icon fas fa-search"
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: _vm.filterSidebarExpanded
                                  ? _vm.closeFilterSideBar
                                  : null,
                                expression:
                                  "filterSidebarExpanded ? closeFilterSideBar : null"
                              }
                            ],
                            staticClass: "participant-section-right"
                          },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "slide-left-in" } },
                              [
                                _c(
                                  "nav",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.filterSidebarExpanded,
                                        expression: "filterSidebarExpanded"
                                      }
                                    ],
                                    staticClass: "participant-right-side-bar"
                                  },
                                  [
                                    _c("journal-sidebar", {
                                      attrs: {
                                        "participants-list":
                                          _vm.participants.list,
                                        "participants-pagination":
                                          _vm.participants.pagination
                                      },
                                      on: {
                                        "fetch-participants":
                                          _vm.fetchParticipants
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "smooth-reflow",
                    {
                      staticClass: "journal-overview__entries",
                      attrs: { tag: "div" }
                    },
                    [
                      _vm.isEntryLoading
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "journal-overview__entries-loader"
                              },
                              [_c("loading-spinner")],
                              1
                            )
                          ]
                        : _vm._l(_vm.entries, function(entry, index) {
                            return _c("journal-entries", {
                              key: index,
                              attrs: { entry: entry }
                            })
                          })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.entries.length &&
                  _vm.entriesPagination.total > _vm.entriesPagination.limit
                    ? _c("pagination-component", {
                        staticClass: "article-overview__pagination",
                        attrs: {
                          "current-page": _vm.entriesPagination.page,
                          "total-rows": _vm.entriesPagination.total,
                          "per-page": _vm.entriesPagination.limit
                        },
                        on: { change: _vm.changeEntriesPage }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "journal-sidebar-container" },
                [
                  _c("journal-sidebar", {
                    attrs: {
                      "participants-list": _vm.participants.list,
                      "participants-pagination": _vm.participants.pagination
                    },
                    on: { "fetch-participants": _vm.fetchParticipants }
                  })
                ],
                1
              )
            ]
          )
        : _c("journal-entry-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }