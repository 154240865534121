var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-avatar" }, [
    _vm.$can("view-profile") && _vm.linked && _vm.user.id !== 0
      ? _c(
          "a",
          {
            staticClass: "user-avatar__link",
            attrs: { href: "/user/view_profile/" + _vm.user.id }
          },
          [
            _c("figure", { staticClass: "user-avatar__avatar-figure" }, [
              _c("img", {
                staticClass:
                  "user-avatar__avatar user-avatar__avatar--linked js-object-fit",
                attrs: {
                  src: _vm.user.avatar.thumbnails["150x150-cropped"],
                  alt:
                    (_vm.user.name || _vm.user.display_name) + "profile image"
                }
              })
            ])
          ]
        )
      : _vm.user.id === 0
      ? _c(
          "figure",
          { staticClass: "user-avatar__avatar-figure" },
          [_c("default-avatar", { staticClass: "user-avatar__avatar" })],
          1
        )
      : _c("figure", { staticClass: "user-avatar__avatar-figure" }, [
          _c("img", {
            staticClass: "user-avatar__avatar js-object-fit",
            attrs: {
              src: _vm.user.avatar.thumbnails["150x150-cropped"],
              alt: (_vm.user.name || _vm.user.display_name) + "profile image"
            }
          })
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }