<template>
    <section class="custom-element">
        <div class="custom-element__content redactor-content" v-html="content" />
    </section>
</template>

<script>
    export default {
        name: 'CustomElement',
        props: {
            title: {
                type: String,
                required: true,
            },
            content: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .custom-element {
        &:not(:first-child) {
            margin-top: $gutter-xl;
        }
    }
</style>
