<template>
    <div class="custom-filter">
        <b-dropdown
            variant="link"
            no-caret
            right
            class="custom-filter__dropdown-container"
            toggle-class="btn--sm btn--soft"
        >
            <template slot="button-content">
                <i v-if="showIcon" class="custom-filter__dropdown-filter-icon fas fa-filter" />

                <template v-if="activeFilter.id === 0">
                    {{ 'filter.all'|trans({}, 'frontend') }}
                </template>
                <template v-else>
                    {{ activeFilter.name }}
                </template>

                <i class="custom-filter__dropdown-caret-icon fas fa-caret-down" />
            </template>
            <b-dropdown-item v-for="filter in filters" :key="filter.id" @click="changeFilter(filter)" data-jest="filter">
                <template v-if="filter.id === 0">
                    {{ 'filter.all'|trans({}, 'frontend') }}
                </template>
                <template v-else>
                    {{ filter.name }}
                </template>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        name: 'CustomFilter',
        props: {
            filters: {
                type: Array,
                required: true,
            },
            initialFilter: {
                type: Object,
                required: false,
                default() {
                    return this.filters[0];
                },
            },
            showIcon: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        data() {
            return {
                activeFilter: '',
            };
        },
        created() {
            this.activeFilter = this.initialFilter;
        },
        methods: {
            changeFilter(filter) {
                if (this.activeFilter.id === filter.id) {
                    return;
                }

                this.activeFilter = filter;

                this.$emit('filter', this.activeFilter);
            },
        },
    };
</script>

<style lang="scss">
    .custom-filter {
        .dropdown-menu {
            max-height: 250px;
            overflow: auto;
        }
    }
</style>
