<template>
    <div class="app-wrapper">
        <div v-if="meta.routerOutletOnly" class="main-container__content">
            <router-view />
            <blueimp-modal />
            <toasts-container />
            <cookie-modal />
        </div>
        <template v-else>
            <navigation-wrapper />
            <div class="main-background" />

            <div id="wrapper" class="main-wrapper">
                <status-messages />
                <main-header />

                <div class="header-container">
                    <widgets-container class="header-container__items" configuration="header" />
                </div>

                <div class="main-container">
                    <div class="main-container__box">
                        <div class="top-container main-container__top">
                            <widgets-container class="top-container__items" configuration="top" />
                        </div>
                        <div class="main-container__content">
                            <router-view />
                        </div>
                        <div class="bottom-container main-container__bottom">
                            <widgets-container class="bottom-container__items" configuration="bottom" />
                        </div>
                    </div>

                    <sidebar-component />
                </div>

                <div class="footer-container">
                    <widgets-container class="footer-container__items" configuration="footer" />
                </div>

                <main-footer />
            </div>

            <toasts-container />
            <cookie-modal />
            <blueimp-modal />
            <nps-component
                scope="focus_community_manager_finished_meeting"
                :translation-parameters="{ '{name}': $store.getters['user/getUser'].display_name, '{module_name}': $trans('module_name', {}, 'focus_frontend') }"
            />
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueScrollTo from 'vue-scrollto';
    import {
        TooltipPlugin,
        CollapsePlugin,
        DropdownPlugin,
        ModalPlugin,
    } from 'bootstrap-vue';
    import lineClamp from 'vue-line-clamp';
    import PortalVue from 'portal-vue';

    import './Core/Forms/FormComponents';

    import NavigationWrapper from './Core/Layout/Navigation/NavigationWrapper.vue';
    import MainHeader from './Core/Layout/MainHeader.vue';
    import MainFooter from './Core/Layout/MainFooter.vue';
    import SidebarComponent from './Core/Layout/Sidebar/SidebarComponent.vue';
    import SidebarExpander from './Core/Layout/Sidebar/SidebarExpander.vue';
    import WidgetsContainer from './Widget/WidgetsContainer.vue';
    import PaginationComponent from './Core/Pagination/PaginationComponent.vue';
    import SmoothReflow from './Core/SmoothReflow/SmoothReflow.vue';
    import BreadcrumbsComponent from './Core/Breadcrumbs/BreadcrumbsComponent.vue';
    import VisualsCarousel from './Core/Attachments/VisualsCarousel.vue';
    import DefaultFillImage from './Core/Template/DefaultFillImage.vue';
    import ToastsContainer from './Core/Toasts/ToastsContainer.vue';
    import UsergroupsList from './Core/Usergroups/UsergroupsList.vue';
    import ModalComponent from './Core/Modals/ModalComponent.vue';
    import DefaultAvatar from './Core/Template/DefaultAvatar.vue';
    import UserName from './Core/User/UserName.vue';
    import UserAvatar from './Core/User/UserAvatar.vue';
    import LoadingSpinner from './Core/Progress/LoadingSpinner.vue';
    import LoadingDoubleBounce from './Core/Progress/LoadingDoubleBounce.vue';
    import BlueimpModal from './Core/Modals/BlueimpModal.vue';
    import LanguageSwitch from './Core/Localization/LanguageSwitch.vue';
    import ActionsList from './Core/Actions/ActionsList.vue';
    import CookieModal from './Core/CookieConsent/CookieModal.vue';
    import ClickOutsidePlugin from './Core/Layout/ClickOutsidePlugin';
    import StatusMessages from './Core/StatusMessage/StatusMessages.vue';
    import NpsComponent from './Core/Nps/NpsComponent.vue';
    import CustomFilter from './Core/Filter/CustomFilter.vue';
    import FilterDropdown from './Core/Filter/FilterDropdown.vue';
    import ImageComponent from './Core/Image/ImageComponent.vue';
    import AutoResizeTextarea from './Core/Forms/AutoResizeTextarea.vue';
    import PromoteSolution from './Core/PromoteSolutions/PromoteSolution.vue';
    import NotificationComponent from './Core/Notification/NotificationComponent.vue';
    import ButtonComponent from './Core/Button/ButtonComponent.vue';

    Vue.component(SidebarExpander.name, SidebarExpander);
    Vue.component(PaginationComponent.name, PaginationComponent);
    Vue.component(SmoothReflow.name, SmoothReflow);
    Vue.component(BreadcrumbsComponent.name, BreadcrumbsComponent);
    Vue.component(VisualsCarousel.name, VisualsCarousel);
    Vue.component(DefaultFillImage.name, DefaultFillImage);
    Vue.component(UsergroupsList.name, UsergroupsList);
    Vue.component(ModalComponent.name, ModalComponent);
    Vue.component(DefaultAvatar.name, DefaultAvatar);
    Vue.component(UserName.name, UserName);
    Vue.component(UserAvatar.name, UserAvatar);
    Vue.component(LoadingSpinner.name, LoadingSpinner);
    Vue.component(LoadingDoubleBounce.name, LoadingDoubleBounce);
    Vue.component(BlueimpModal.name, BlueimpModal);
    Vue.component(LanguageSwitch.name, LanguageSwitch);
    Vue.component(ActionsList.name, ActionsList);
    Vue.component(StatusMessages.name, StatusMessages);
    Vue.component(NpsComponent.name, NpsComponent);
    Vue.component(CustomFilter.name, CustomFilter);
    Vue.component(FilterDropdown.name, FilterDropdown);
    Vue.component(ImageComponent.name, ImageComponent);
    Vue.component(AutoResizeTextarea.name, AutoResizeTextarea);
    Vue.component(PromoteSolution.name, PromoteSolution);
    Vue.component(NotificationComponent.name, NotificationComponent);
    Vue.component(ButtonComponent.name, ButtonComponent);

    Vue.use(TooltipPlugin);
    Vue.use(CollapsePlugin);
    Vue.use(DropdownPlugin);
    Vue.use(ModalPlugin);
    Vue.use(VueScrollTo, {
        duration: 1000,
        easing: [0.455, 0.03, 0.515, 0.955],
    });
    Vue.use(ClickOutsidePlugin);
    Vue.use(lineClamp);
    Vue.use(PortalVue);

    export default {
        name: 'App',
        components: {
            CookieModal,
            NavigationWrapper,
            MainHeader,
            MainFooter,
            SidebarComponent,
            WidgetsContainer,
            ToastsContainer,
        },
        computed: {
            meta() {
                return this.$route.meta;
            },
        },
        created() {
            this.$translator.load('frontend');
            this.$translator.load('challenge_frontend');
            this.$translator.load('webshop_frontend');
            window.addEventListener('resize', () => {
                this.$store.commit('configuration/setSetting', { setting: 'screenWidth', value: window.innerWidth });
            });
            window.addEventListener('beforeunload', (event) => {
                if (this.$store.getters['forms/isClean']) {
                    return;
                }

                event.preventDefault();
                // eslint-disable-next-line no-param-reassign
                event.returnValue = this.$trans('action.confirm_leave');
            });
        },
    };
</script>
