<template>
    <div v-click-outside="closeNavigations" class="mobile-navigation" :class="{ 'mobile-navigation--hidden': hideMenu && !expanded && !expandedUserNavigation }">
        <div class="mobile-navigation__inner">
            <a class="mobile-navigation__toggle" :class="{ 'mobile-navigation__toggle--expanded': expanded }" tabindex="0" @click="toggleNavigation">
                <i class="mobile-navigation__mobile-toggle-icon fas fa-bars" />
            </a>

            <transition name="slide-right-in">
                <nav v-show="expanded" class="mobile-navigation__main-navigation">
                    <ul class="mobile-navigation__main-list">
                        <li v-for="(item, key) in items" :key="key" :class="`mobile-navigation__main-item mobile-navigation__main-item--${key}`">
                            <router-link
                                v-if="['challenge', 'blog', 'focus', 'journal', 'discussion-board'].includes(item.key)"
                                :to="item.url"
                                tag="a"
                                active-class="mobile-navigation__main-link--active"
                                class="mobile-navigation__main-link"
                            >
                                <span class="mobile-navigation__main-label">
                                    {{ item.label }}
                                </span>
                            </router-link>
                            <a v-else class="mobile-navigation__main-link" :class="{ 'mobile-navigation__main-link--active': item.active }" :href="item.url">
                                <span class="mobile-navigation__main-label">
                                    {{ item.label }}
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </transition>

            <div class="nav-logo" />

            <div class="mobile-navigation__right">
                <language-switch v-if="$can('switch-locale', user)" class="mobile-navigation__language-switch" variant="compact" direction="right" />

                <div v-if="$can('access-pulse', user) && isObserver" class="mobile-navigation__pulse">
                    <a v-b-tooltip.bottom class="mobile-navigation__pulse-link" href="/pulse" title="Pulse">
                        <i class="mobile-navigation__pulse-icon fas fa-wave-sine" />
                    </a>
                </div>

                <div v-if="$can('access-admin', user)" class="mobile-navigation__admin">
                    <a class="mobile-navigation__admin-link" href="/admin3">
                        <i class="mobile-navigation__admin-icon fas fa-key" />
                    </a>
                </div>

                <platform-navigation v-if="$can('navigate-platform')" />

                <a class="mobile-navigation__user-navigation-toggle" :class="{ 'mobile-navigation__user-navigation-toggle--expanded': expandedUserNavigation }" tabindex="0" @click="toggleUserNavigation">
                    <figure class="mobile-navigation__avatar-figure">
                        <img v-if="user && user.avatar" class="mobile-navigation__avatar" :src="user.avatar.thumbnail_urls['150x150_cropped']" :alt="`${user.display_name}'s Avatar`">
                        <span class="mobile-navigation__notifications-indicator" />
                    </figure>
                </a>

                <transition name="slide-left-in">
                    <nav v-show="expandedUserNavigation" class="mobile-navigation__user-navigation">
                        <ul class="mobile-navigation__user-navigation-list">
                            <template v-if="user.is_logged_in">
                                <li v-for="item in userItems" :key="item.key" :class="`mobile-navigation__user-navigation-item mobile-navigation__user-navigation-item--${item.key}`">
                                    <a class="mobile-navigation__user-navigation-link" :href="item.url">
                                        <span class="mobile-navigation__user-navigation-label">
                                            {{ item.label }}
                                        </span>

                                        <template v-if="item.key === 'notification-center' && user.stats && user.stats.notifications_count > 0">
                                            <span class="mobile-navigation__user-navigation-indicator mobile-navigation__user-navigation-indicator--notifications">
                                                {{ user.stats.notifications_count }}
                                            </span>
                                        </template>
                                        <template v-if="item.key === 'private-messages' && user.stats && user.stats.private_messages_count > 0">
                                            <span class="mobile-navigation__user-navigation-indicator mobile-navigation__user-navigation-indicator--private-messages">
                                                {{ user.stats.private_messages_count }}
                                            </span>
                                        </template>
                                    </a>
                                </li>
                            </template>
                            <template v-else>
                                <li class="mobile-navigation__user-navigation-item mobile-navigation__user-navigation-item--login">
                                    <a class="mobile-navigation__user-navigation-link" :href="$path('login')">
                                        <span class="mobile-navigation__user-navigation-label">
                                            {{ 'user.login'|trans }}
                                        </span>
                                    </a>
                                </li>
                                <li class="mobile-navigation__user-navigation-item mobile-navigation__user-navigation-item--register">
                                    <a class="mobile-navigation__user-navigation-link" :href="$path('register')">
                                        <span class="mobile-navigation__user-navigation-label">
                                            {{ 'user.register'|trans }}
                                        </span>
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </nav>
                </transition>

                <sidebar-expander class="mobile-navigation__sidebar-toggle" />
            </div>
        </div>
    </div>
</template>

<script>
    import PlatformNavigation from './Types/PlatformNavigation.vue';

    export default {
        name: 'MobileNavigation',
        components: { PlatformNavigation },
        props: {
            items: {
                type: Array,
                required: true,
            },
            userItems: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                expanded: false,
                expandedUserNavigation: false,
                hideMenu: false,
                previousScrollPosition: 0,
            };
        },
        computed: {
            user() {
                return this.$store.getters['user/getUser'];
            },
            isObserver() {
                return this.user.role.type === 'observer';
            },
            sidebarExpanded() {
                return this.$store.getters['configuration/getSetting']('sidebarExpanded');
            },
        },
        created() {
            window.addEventListener('scroll', this.changeNavigationVisibility);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.changeNavigationVisibility);
        },
        methods: {
            toggleNavigation() {
                this.expanded = !this.expanded;

                if (this.expanded) {
                    this.expandedUserNavigation = false;
                }
            },
            toggleUserNavigation() {
                this.expandedUserNavigation = !this.expandedUserNavigation;

                if (this.expandedUserNavigation) {
                    this.expanded = false;
                }
            },
            closeNavigations() {
                this.expanded = false;
                this.expandedUserNavigation = false;
            },
            changeNavigationVisibility() {
                const currentScrollPosition = window.pageYOffset;

                // 154 = height of menu + height of header
                if (currentScrollPosition > this.previousScrollPosition && currentScrollPosition > 154) {
                    this.hideMenu = true;

                    if (this.sidebarExpanded) {
                        this.$store.commit('configuration/setSetting', { setting: 'sidebarExpanded', value: false });
                    }
                } else {
                    this.hideMenu = false;
                }

                this.previousScrollPosition = currentScrollPosition;
            },
        },
    };
</script>

<style scoped lang="scss">
    .mobile-navigation {
        background-color: var(--color-menu-background);
        box-shadow: 0 0 10px 5px rgba(#000, .3);
        color: var(--color-menu-text);
        display: flex;
        height: 54px;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: all .2s ease-in-out;
        z-index: 3;

        @include min-breakpoint(screen-sm-min) {
            display: none;
        }

        &--hidden {
            transform: translateY(-100%);
        }

        &__inner {
            display: flex;
            height: 100%;
            position: relative;
            width: 100%;
            .nav-logo{
                background: no-repeat left center;
                background-image: var(--image-logo);
                background-size: cover;
                width: 200px;
                margin: 6px 10px;
                display: var(--logo-display-menu);
            }
        }

        &__toggle {
            align-items: center;
            color: var(--color-menu-text);
            cursor: pointer;
            display: inline-flex;
            flex-shrink: 0;
            font-size: 16px;
            justify-content: center;
            transition: all .2s ease-in-out;
            width: 50px;

            &:hover,
            &:focus,
            &:active {
                color: var(--color-menu-text);
                border-bottom: 2px solid var(--color-menu-text);
                outline: none;
                transform: scale(1.05);
            }

            &--expanded {
                background: rgba(#fff, .075);
            }
        }

        // Main navigation

        &__main-navigation {
            background: var(--color-menu-background);
            display: inline-flex;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            position: absolute;
            top: 100%;
        }

        &__main-list {
            @include reset-list();
            background: rgba(#fff, .075);
            display: flex;
            flex-direction: column;
            font-size: $font-m;
            max-height: calc(100vh - 54px - #{$gutter-l});
            overflow: auto;
            padding: $gutter-m 0;

            @media (max-width: 500px) {
                max-height: calc(100vh - 54px);
            }
        }

        &__main-item {
            display: inline-flex;
            flex-shrink: 0;
            word-break: break-word;

            &--active {
                .explore-navigation {
                    &__main-link {
                        background: rgba(#fff, .075);
                        color: var(--color-menu-text);
                    }
                }
            }
        }

        &__main-link {
            align-items: center;
            color: var(--color-menu-text);
            display: inline-flex;
            padding: $gutter-s $gutter-l;
            text-align: center;
            text-decoration: none;
            width: 100%;

            &:hover,
            &:focus,
            &:active,
            &[aria-expanded='true'] {
                background: rgba(#fff, .075);
                color: var(--color-menu-text);
            }

            &--active {
                background: rgba(#fff, .075);
                color: var(--color-menu-text);
            }
        }

        // Right container

        &__right {
            display: flex;
            margin-left: auto;
        }

        // Admin button

        &__admin,
        &__pulse {
            flex-shrink: 0;
        }

        &__admin-link,
        &__pulse-link {
            align-items: center;
            color: var(--color-menu-text);
            display: inline-flex;
            height: 100%;
            justify-content: center;
            transition: all .2s ease-in-out;
            width: 50px;

            &:hover,
            &:focus,
            &:active {
                color: var(--color-menu-text);
                border-bottom: 2px solid var(--color-menu-text);
                outline: none;
                transform: scale(1.05);
            }
        }

        &__admin-icon,
        &__pulse-icon {
            font-size: 18px;
        }

        // User dropdown

        &__user-navigation-toggle {
            align-items: center;
            cursor: pointer;
            display: inline-flex;
            height: 100%;
            justify-content: center;
            transition: all .2s ease-in-out;
            width: 54px;

            &:hover,
            &:focus,
            &:active {
                color: var(--color-menu-text);
                border-bottom: 2px solid var(--color-menu-text);
                outline: none;
                transform: scale(1.05);
            }

            &--expanded {
                background: rgba(#fff, .075);
            }
        }

        &__avatar-figure {
            margin: 0;
            transition: all .2s ease-in-out;
        }

        &__avatar {
            border-radius: var(--avatar-border-radius);
            height: 34px;
            width: 34px;
        }

        &__user-navigation {
            background-color: var(--color-menu-background);
            position: absolute;
            right: 0;
            top: 100%;
        }

        &__user-navigation-list {
            @include reset-list();
            background: rgba(#fff, .075);
            padding: 8px 0;
        }

        &__user-navigation-link {
            align-items: center;
            border-radius: 0;
            color: var(--color-menu-text);
            display: inline-flex;
            padding: $gutter-xs $gutter-m;
            width: 100%;

            &:hover,
            &:focus,
            &:active {
                background: rgba(#fff, .075);
                color: var(--color-menu-text);
            }
        }

        &__user-navigation-indicator {
            align-items: center;
            background-color: var(--color-primary);
            border-radius: 50%;
            color: #fff;
            display: flex;
            font-size: $font-s;
            height: 20px;
            justify-content: center;
            margin-left: $gutter-m;
            width: 20px;

            &--notifications {
                background-color: #d9534f;
            }
        }

        &__sidebar-toggle {
            width: 35px;
        }

        &__language-switch {
            align-items: center;
            display: inline-flex;
            height: 100%;
            justify-content: center;
            transition: all .2s ease-in-out;
            width: 50px;

            &:hover,
            &:focus,
            &:active {
                background: rgba(#fff, .075);
                color: var(--color-menu-text);
                outline: none;
                text-decoration: none;
            }
        }
    }
</style>

<style lang="scss">
    .slide-left-in {
        &-enter-active,
        &-leave-active {
            transition: transform .3s ease-in-out;
        }

        &-enter,
        &-leave-to {
            transform: translateX(100%);
        }
    }

    .slide-right-in {
        &-enter-active,
        &-leave-active {
            transition: transform .3s ease-in-out;
        }

        &-enter,
        &-leave-to {
            transform: translateX(-100%);
        }
    }

    @include max-breakpoint(screen-xs-max) {
        .platform-layout--astra .main-wrapper {
            padding-top: 54px;
        }
    }
</style>
