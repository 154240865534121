import getIdsFromChannelName, { canSendMessageInModeratorsWithIndividualObserver } from '../../../../../Module/Focus/MeetingView/Chat/utils/ChannelNameHelpers';
import {
    OBSERVER, MANAGERS_WITH_OBSERVERS_AND_CMNTY, MANAGERS_WITH_CMNTY,
} from '../../../../User/Roles';

export default ({ user, subject }) => {
    switch (subject) {
        case 'chat_general':
            return user.role.type !== OBSERVER;
        case 'chat_backroom':
            return MANAGERS_WITH_OBSERVERS_AND_CMNTY.includes(user.role.type);
        case 'chat_support':
            return MANAGERS_WITH_CMNTY.includes(user.role.type);
        default: {
            if (subject.includes('chat_private-')) {
                // Has to be "chat_private-X-Y"
                // Removes "chat_private"
                const ids = getIdsFromChannelName(subject);
                return ids.includes(user.id);
            }

            if (subject.includes('chat_moderators_observer-')) {
                return canSendMessageInModeratorsWithIndividualObserver(subject, user);
            }
            return false;
        }
    }
};
