var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.menuView, {
    tag: "component",
    attrs: { items: _vm.items, "user-items": _vm.userItems }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }