import TaskListApi from '../../../Widget/TaskList/TaskListApi';
import store from '../../../Store';

export default function (to, from, next) {
    if (to.matched.some((record) => record.meta.tasklist)) {
        const itemId = to.meta.tasklist.itemIdParam ? to.params[to.meta.tasklist.itemIdParam] : '';
        const module = to.meta.tasklist.module ?? '';
        const actions = to.meta.tasklist.actions ?? '';
        const taskBlockedStatusCalls = [];
        actions.forEach((action) => {
            taskBlockedStatusCalls.push(TaskListApi.isBlockedByTask(itemId, module, action));
        });

        Promise.all(taskBlockedStatusCalls).then((responses) => {
            let redirected = false;
            responses.forEach((response) => {
                if (response.body.data !== false) {
                    window.location.href = store.getters['configuration/getSetting']('openingPageUrl');
                    redirected = true;
                }
            });

            if (!redirected) {
                next();
            }
        });
    } else {
        next();
    }
}
