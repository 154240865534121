var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.buttonTag,
    {
      ref: "button",
      tag: "component",
      staticClass: "button-component",
      class: [
        "button-component--" + _vm.variant,
        "button-component--" + _vm.size,
        "button-component--align-" + _vm.alignment,
        {
          "button-component--disabled": _vm.disabled
        }
      ],
      attrs: { disabled: _vm.disabled, type: "button", "data-jest": "button" },
      on: { click: _vm.handleClick }
    },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$slots.prependedIcon,
              expression: "$slots.prependedIcon"
            }
          ],
          staticClass:
            "button-component__icon button-component__icon--prepended"
        },
        [_vm._t("prependedIcon")],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "button-component__label" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.$slots.appendedIcon
        ? _c(
            "span",
            {
              staticClass:
                "button-component__icon button-component__icon--appended"
            },
            [_vm._t("appendedIcon")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }