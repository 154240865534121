var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sidebarEnabledElements.length > 0
    ? _c(
        "a",
        {
          staticClass: "sidebar__toggle",
          class: { "sidebar__toggle--open": _vm.sidebarExpanded },
          attrs: { tabindex: "0" },
          on: { click: _vm.toggleSidebar }
        },
        [
          _c("i", {
            staticClass: "sidebar__toggle-icon far fa-angle-double-left"
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }