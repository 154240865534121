const QuestionnaireModule = () => import(/* webpackChunkName: 'questionnaire-module' */ './QuestionnaireModule.vue');
const QuestionnaireOverview = () => import(/* webpackChunkName: 'questionnaire-overview' */ './QuestionnaireOverview/QuestionnaireOverview.vue');
const QuestionnaireView = () => import(/* webpackChunkName: 'questionnaire-view' */ './QuestionnaireView/QuestionnaireView.vue');

export default {
    path: '/questionnaire',
    component: QuestionnaireModule,
    meta: { bodyClass: 'module-questionnaire' },
    children: [
        {
            path: '',
            name: 'QuestionnaireOverview',
            component: QuestionnaireOverview,
            meta: { bodyClass: 'module-questionnaire__questionnaire-overview' },
        },
        {
            path: ':slug?-:id(\\d+)',
            name: 'QuestionnaireView',
            component: QuestionnaireView,
            meta: { bodyClass: 'module-questionnaire__questionnaire' },
            props: true,
        },
    ],
};
