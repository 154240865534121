import { render, staticRenderFns } from "./UserName.vue?vue&type=template&id=0948c1fa&scoped=true&"
import script from "./UserName.vue?vue&type=script&lang=js&"
export * from "./UserName.vue?vue&type=script&lang=js&"
import style0 from "./UserName.vue?vue&type=style&index=0&id=0948c1fa&lang=scss&scoped=true&"
import style1 from "./UserName.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0948c1fa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/platform/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0948c1fa')) {
      api.createRecord('0948c1fa', component.options)
    } else {
      api.reload('0948c1fa', component.options)
    }
    module.hot.accept("./UserName.vue?vue&type=template&id=0948c1fa&scoped=true&", function () {
      api.rerender('0948c1fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Core/User/UserName.vue"
export default component.exports