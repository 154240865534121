<template>
    <b-dropdown
        v-if="languages.length > 0"
        :id="`languageSwitch-${variant}`"
        class="language-switch"
        :class="`language-switch--${variant}`"
        toggle-class="language-switch__toggle"
        menu-class="language-switch__dropdown"
        aria-label="language switch"
        ref="languageSwitchBtn"
        :right="direction === 'right'"
        :variant="variant === 'compact' ? 'link' : ''"
        :no-caret="variant === 'compact'"
    >
        <template v-if="variant === 'compact'" slot="button-content">
            <svg class="language-switch__icon language-switch__icon--compact">
                <use xlink:href="/front-end/pf4/images/icons/icons.svg#language" />
            </svg>
        </template>
        <template v-else slot="button-content">
            <svg class="language-switch__icon language-switch__icon--default">
                <use xlink:href="/front-end/pf4/images/icons/icons.svg#language" />
            </svg>

            <span class="language-switch__label">
                {{ `common_native_lang_${activeLanguage}`|trans({}, 'ci_global') }}
            </span>

            <span class="language-switch__caret fas fa-caret-down" />
        </template>
        <b-dropdown-item v-for="language in languages" :key="language" :href="destination(language)" class="language-switch__dropdown-link">
            {{ `common_native_lang_${language}`|trans({}, 'ci_global') }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
    import Router from '../../Router';

    Router.beforeEach((to, from, next) => {
        if (!to.query.locale) {
            return next();
        }

        return next({ ...to, query: { ...to.query, locale: undefined } });
    });

    export default {
        name: 'LanguageSwitch',
        props: {
            variant: {
                type: String,
                required: false,
                default: 'default',
                validator(value) {
                    return ['default', 'compact'].indexOf(value) !== -1;
                },
            },
            direction: {
                type: String,
                required: false,
                default: 'left',
                validator(value) {
                    return ['left', 'right'].indexOf(value) !== -1;
                },
            },
        },
        computed: {
            languages() {
                return this.$store.getters['configuration/getSetting']('activeLangs').filter((language) => language !== this.activeLanguage);
            },
            activeLanguage() {
                return this.$store.getters['configuration/getSetting']('locale');
            },
        },
        created() {
            this.$translator.load('ci_global');
        },
        mounted() {
            this.setAriaLabel();
        },
        methods: {
            destination(language) {
                const url = new URL(window.location);
                url.searchParams.set('locale', language);

                return url.href;
            },
            setAriaLabel() {
                const languageSwitch = this.$refs.languageSwitchBtn.$el;
                const languageSwitchBtn = languageSwitch.querySelector('button');
                languageSwitchBtn.setAttribute('aria-label', 'language switch button');
            },
        },
    };
</script>

<style lang="scss">
    .language-switch {
        &__icon {
            height: 22px;
            width: 22px;
        }

        &__toggle {
            background: none;
            color: #fff;
            font-size: $font-m;
            height: 100%;
            padding: 0;
            width: 100%;

            &:after {
                display: none;
            }
        }

        &__caret {
            font-size: $font-xs;
            margin-left: $gutter-m;
        }

        &--default {
            .language-switch {
                &__icon {
                    fill: var(--color-primary);
                    margin-right: $gutter-m;
                }
            }
        }

        &--compact {
            .language-switch {
                &__toggle {
                    align-items: center;
                    background: transparent;
                    border: 0;
                    border-radius: 0;
                    color: var(--color-menu-text);
                    display: inline-flex;
                    fill: var(--color-menu-text);
                    height: 60px;
                    justify-content: center;
                    padding: $gutter-s;
                    text-decoration: none;
                    transition: all .2s ease-in-out;

                    svg {
                        transition: transform .2s ease-in-out;
                    }

                    &:hover,
                    &:focus,
                    &:active,
                    &[aria-expanded="true"] {
                        color: var(--color-menu-text);
                        border-bottom: 2px solid var(--color-menu-text);
                        outline: none;
                        background: rgba(#fff, .075);
                        fill: var(--color-menu-text);

                        svg {
                            transform: scale(1.05);
                        }
                    }

                    &:after {
                        display: none;
                    }
                }

                &__dropdown {
                    background-color: var(--color-menu-background);
                    border: 0;
                    border-radius: 0;
                    margin: 0;
                    padding: $gutter-s 0;

                    &:after {
                        background: rgba(255, 255, 255, .075);
                        content: ' ';
                        display: inline-block;
                        height: 100%;
                        left: 0;
                        pointer-events: none;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }
                }

                &__dropdown-link a {
                    align-items: center;
                    border-radius: 0;
                    color: var(--color-menu-text);
                    display: inline-flex;
                    font-size: $font-s;
                    line-height: 1.2;
                    padding: $gutter-s;
                    width: 100%;

                    &:hover,
                    &:focus,
                    &:active {
                        background: rgba(#fff, .075);
                        color: var(--color-menu-text);
                    }
                }
            }
        }
    }
</style>
