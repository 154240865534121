<template>
    <div class="upload-v2__inner">
        <slot name="top" />

        <ul class="upload-v2__errors">
            <li v-for="(error, index) in errors" :key="index" class="upload-v2__error">
                <i class="upload-v2__error-icon fas fa-exclamation-circle" />
                <span class="upload-v2__error-text">
                    {{ error }}
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'UploadErrors',
        props: {
            errors: {
                type: Array,
                required: true,
            },
        },
    };
</script>
