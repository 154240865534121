<template>
    <component
        :is="buttonTag"
        ref="button"
        :class="[
            `button-component--${variant}`,
            `button-component--${size}`,
            `button-component--align-${alignment}`,
            {
                'button-component--disabled': disabled,
            },
        ]"
        :disabled="disabled"
        class="button-component"
        type="button"
        data-jest="button"
        @click="handleClick"
    >
        <span v-show="$slots.prependedIcon" class="button-component__icon button-component__icon--prepended">
            <slot name="prependedIcon" />
        </span>
        <span class="button-component__label">
            <slot />
        </span>
        <span v-if="$slots.appendedIcon" class="button-component__icon button-component__icon--appended">
            <slot name="appendedIcon" />
        </span>
    </component>
</template>

<script>
    export default {
        name: 'ButtonComponent',
        props: {
            variant: {
                type: String,
                default: 'primary',
            },
            size: {
                type: String,
                default: 'medium',
            },
            alignment: {
                type: String,
                default: 'center',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isAnchor: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            buttonTag() {
                if (this.isAnchor) {
                    return 'a';
                }
                return 'button';
            },
        },
        methods: {
            handleClick() {
                if (this.disabled) {
                    return;
                }
                this.$emit('click');
                this.$refs.button.blur();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .button-component {
        align-items: center;
        border-radius: 4px;
        display: inline-flex;
        font-size: 16px;
        font-weight: 300;
        justify-content: center;
        padding: 10px 16px;
        text-align: center;
        transition: all .2s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            box-shadow: none;
            cursor: pointer;
            outline: none;
            text-decoration: none;
        }

        &--primary {
            background-color: var(--color-primary);
            border: 1px solid var(--color-primary);
            color: #fff;

            &:not([disabled]) {
                &:hover,
                &:focus,
                &:active {
                    background-color: var(--color-primary-shade-20);
                    border-color: var(--color-primary-shade-20);
                    color: #fff;
                }
            }
        }

        &--delete {
            background-color: $color-red-flamingo;
            border: 1px solid $color-red-flamingo;
            color: #fff;

            &:not([disabled]) {
                &:hover,
                &:focus,
                &:active {
                    background-color: shade($color-red-flamingo, 20);
                    border-color: shade($color-red-flamingo, 20);
                    color: #fff;
                }
            }
        }

        &--text {
            background: transparent;
            border: 1px solid transparent;
            color: $color-grey-darker;

            &:not([disabled]) {
                &:hover,
                &:focus,
                &:active {
                    background: $color-grey-lighter;
                    border-color: $color-grey-lighter;
                    color: $color-grey-darkest;
                }
            }
        }

        &--disabled {
            cursor: not-allowed;
            opacity: .3;

            &:hover,
            &:active,
            &:focus {
                cursor: not-allowed;
            }
        }

        &--small {
            font-size: 14px;
            padding: 5px 8px;
        }

        &--align-left {
            justify-content: flex-start;
            text-align: left;
        }

        &--align-center {
            justify-content: center;
            text-align: center;
        }

        &--align-right {
            justify-content: flex-end;
            text-align: right;
        }

        &__icon {
            &--prepended {
                margin-right: $gutter-s;
            }

            &--appended {
                margin-left: $gutter-s;
            }
        }
    }
</style>
