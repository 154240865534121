var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.languages.length > 0
    ? _c(
        "b-dropdown",
        {
          ref: "languageSwitchBtn",
          staticClass: "language-switch",
          class: "language-switch--" + _vm.variant,
          attrs: {
            id: "languageSwitch-" + _vm.variant,
            "toggle-class": "language-switch__toggle",
            "menu-class": "language-switch__dropdown",
            "aria-label": "language switch",
            right: _vm.direction === "right",
            variant: _vm.variant === "compact" ? "link" : "",
            "no-caret": _vm.variant === "compact"
          }
        },
        [
          _vm.variant === "compact"
            ? _c("template", { slot: "button-content" }, [
                _c(
                  "svg",
                  {
                    staticClass:
                      "language-switch__icon language-switch__icon--compact"
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          "/front-end/pf4/images/icons/icons.svg#language"
                      }
                    })
                  ]
                )
              ])
            : _c("template", { slot: "button-content" }, [
                _c(
                  "svg",
                  {
                    staticClass:
                      "language-switch__icon language-switch__icon--default"
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          "/front-end/pf4/images/icons/icons.svg#language"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "language-switch__label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("trans")(
                          "common_native_lang_" + _vm.activeLanguage,
                          {},
                          "ci_global"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "language-switch__caret fas fa-caret-down"
                })
              ]),
          _vm._v(" "),
          _vm._l(_vm.languages, function(language) {
            return _c(
              "b-dropdown-item",
              {
                key: language,
                staticClass: "language-switch__dropdown-link",
                attrs: { href: _vm.destination(language) }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("trans")(
                        "common_native_lang_" + language,
                        {},
                        "ci_global"
                      )
                    ) +
                    "\n    "
                )
              ]
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }