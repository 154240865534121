<template>
    <aside id="sidebar" class="journal-sidebar allow-swipe js-allow-scroll">
        <div class="widget-filters">
            <h2>
                {{ 'journal.filter_options'|trans({}, 'journal_frontend') }}
            </h2>
            <div>
                <div>
                    <b-form-group
                        id="journal-sidebar-input-group-1"
                        :label="$trans('journal.sidebar.specific_keyword', {}, 'journal_frontend')"
                        label-for="journal-sidebar-input-1"
                        class="mb-4"
                    >
                        <b-form-input
                            id="journal-sidebar-input-1"
                            v-model="formValues.keyword"
                            type="text"
                            placeholder="Search for"
                            required
                        />
                    </b-form-group>

                    <b-form-group
                        id="journal-sidebar-input-group-2"
                        :label="$trans('journal.specific_groups', {}, 'journal_frontend')"
                        label-for="journal-sidebar-input-2"
                        class="mb-4"
                    >
                        <b-form-select
                            id="input-2"
                            v-model="formValues.userGroup"
                            :options="userGroupList"
                            :label="$trans('journal.specific_groups', {}, 'journal_frontend')"
                            :aria-label="$trans('journal.specific_groups', {}, 'journal_frontend')"
                            size="md"
                            class=" form-control custom-select"
                        />
                    </b-form-group>

                    <b-form-group
                        id="input-group-3"
                        :label="$trans('journal.startdate', {}, 'journal_frontend')"
                        label-for="date_start"
                        class="mb-4"
                    >
                        <input
                            id="date_start"
                            v-model="formValues.startDate"
                            class="datepicker form-control"
                            type="date"
                            name="date_start"
                            size="15"
                            placeholder="dd/mm/yyyy"
                        >
                    </b-form-group>

                    <b-form-group
                        id="input-group-4"
                        :label="$trans('journal.enddate', {}, 'journal_frontend')"
                        label-for="date_end"
                        class="mb-4"
                    >
                        <input
                            id="date_end"
                            v-model="formValues.endDate"
                            class="datepicker form-control"
                            type="date"
                            name="date_end"
                            size="15"
                            placeholder="dd/mm/yyyy"
                        >
                    </b-form-group>
                </div>
                <div class="button-group mb-4 form_buttons">
                    <button class="form__button btn btn--primary" type="submit" @click="updateResults">
                        <i class="fa-light fa-arrows-rotate mr-xs" />
                        {{ 'journal.update_results'|trans({}, 'journal_frontend') }}
                    </button>

                    <button class="form__button btn btn--text" @click="resetFilter">
                        {{ 'journal.reset_filters'|trans({}, 'journal_frontend') }}
                    </button>
                </div>
            </div>
        </div>

        <div class="widget-participants">
            <h2>
                {{ 'journal.participants'|trans({}, 'journal_frontend') }}
            </h2>
            <div>
                <ul
                    name="likes-list"
                    class="liking__list"
                    tag="ul"
                >
                    <li
                        v-for="(participant, index) in participants"
                        :key="index"
                        class="liking__list-item"
                        @click="setUserFilter(participant.id)"
                    >
                        <user-avatar class="liking__list-avatar" :user="participant" />
                        <user-name class="liking__list-username" :user="participant" />
                    </li>
                </ul>
                <pagination-component
                    v-if="participants.length && participantsPagination.total > participantsPagination.limit"
                    class="article-overview__pagination"
                    :current-page="participantsPagination.page"
                    :total-rows="participantsPagination.total"
                    :per-page="participantsPagination.limit"
                    @change="changePage"
                />
            </div>
        </div>
    </aside>
</template>

<script>
    import { BFormGroup, BFormInput, BFormSelect } from 'bootstrap-vue';
    import { mapActions } from 'vuex';
    import JournalApi from '../../JournalApi';

    export default {
        components: { BFormGroup, BFormInput, BFormSelect },
        props: {
            participantsList: {
                type: Array,
                default: () => {},
            },
            participantsPagination: {
                type: Object,
                default: () => {},
            },
            fetchParticipants: {
                type: Function,
                default: () => {},
            },
        },
        data() {
            return {
                participants: [],
                formValues: {
                    keyword: '',
                    userGroup: '',
                    startDate: '',
                    endDate: '',
                    user: '',
                },
                pagination: {
                    page: 1,
                    limit: 10,
                    total: 0,
                },
                userGroupList: [],
            };
        },
        watch: {
            participantsList(newValue) {
                this.participants = newValue;
            },
            participantsPagination(newValue) {
                this.pagination = { ...newValue };
            },
        },
        async created() {
            const { body } = await JournalApi.fetchUserGroups();
            this.userGroupList = body.data.map((option) => ({
                value: option.name,
                text: option.name,
                disabled: option.parent_id === null,
            }));
        },
        methods: {
            ...mapActions('journal', ['fetchEntries', 'setFilterSearchParams']),
            updateResults() {
                const { id } = this.$route.params;
                const searchParams = Object.keys(this.formValues).map((key) => {
                    if (this.formValues[key]) {
                        return `&${key}=${this.formValues[key]}`;
                    }
                    return '';
                }).join('');
                this.setFilterSearchParams({ filter: searchParams });
                this.fetchEntries({ assignmentId: id, searchParams });
            },
            resetFilter() {
                const { id } = this.$route.params;
                Object.keys(this.formValues).forEach((key) => {
                    this.formValues[key] = '';
                });
                this.setFilterSearchParams({ filter: '' });
                this.fetchEntries({ assignmentId: id });
            },
            showMoreParticipants() {
                this.$emit('fetch-participants');
            },
            changePage(page) {
                this.$emit('fetch-participants', page);
            },
            setUserFilter(userId) {
                this.formValues = {
                    keyword: '',
                    userGroup: '',
                    startDate: '',
                    endDate: '',
                    user: userId,
                };
                this.updateResults();
            },
        },
    };
</script>

<style scoped>
    #sidebar div h2{
        background-color: transparent;
        color: var(--color-primary);
        border: 0;
        border-left: 10px solid var(--color-primary);
        border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
        display: inline-block;
        font-weight: normal;
        padding-top: 6px;
        padding-bottom: 2px;
        margin-bottom: 20px;
        margin-left: -20px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        width: auto;
        font-size: 1em;
        word-wrap: break-word;
        text-transform: uppercase;
    }
    .custom-select {
        border-radius: 0;
        border: 1px solid #cccccc;
        background-color: #ffffff;
    }
    .button-group {
        display: flex;
        justify-content: flex-end;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: .375rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .mb-4{
        margin-bottom: 1.5rem;
    }
</style>

<style lang="scss" scoped>
    .user-avatar {
        &__link {
            display: inline-block;
            height: 100%;
            width: 100%;
        }

        &__avatar {
            border-radius: var(--avatar-border-radius);
            height: 100%;
            width: 100%;

            &--linked {
                transition: all .2s ease-in-out;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        &__avatar-figure {
            background: $color-grey-lighter;
            border-radius: var(--avatar-border-radius);
            height: 100%;
            margin: 0;
            width: 100%;
        }
    }
</style>

<style lang="scss" scoped>
    .journal-participant-tab{
        padding: 0;
        li {
            list-style: none;
        }
    }
    .user-name {
        &--linked {
            color: var(--username-link-color);

            &:hover {
                color: var(--username-link-color);
                text-decoration: underline;
            }
        }
    }
    .liking {
        &__list {
            display: flex;
            flex-direction: column;
            list-style: none;
            margin-bottom: 0;
            padding: 0 0 10px;
        }

        &__list-item {
            align-items: center;
            border-bottom: 1px solid #ededed;
            display: flex;
            min-height: 53px;
            padding: 8px 0;
        }

        &__list-avatar {
            flex-shrink: 0;
            height: 36px;
            width: 36px;
        }

        &__list-username {
            color: #777;
            flex-grow: 1;
            margin-left: 10px;
            text-decoration: none;
        }
    }
</style>

<style>
    :root {
        --username-link-color: var(--color-primary);
    }
</style>
