<template>
    <a
        ref="button"
        class="close-action actions-list__action btn btn--text btn--action"
        :class="{ 'close-action--closed': closed }"
        data-cy="actions-list-close"
        tabindex="0"
        data-jest="button"
        aria-label="close action"
        href="#"
        @keypress.enter="toggleClosed"
        @click="toggleClosed"
    >
        <i v-if="closed" class="close-action__toggle-icon btn__icon fas fa-lock" data-jest="closed-icon" />
        <i v-else class="close-action__toggle-icon btn__icon far fa-unlock-alt" data-jest="open-icon" />
        <b-tooltip :target="() => $refs.button" :disabled="screenWidth <= 900" :delay="{ show: 500, hide: 0 }" data-jest="tooltip">
            {{ tooltipText }}
        </b-tooltip>
    </a>
</template>

<script>
    export default {
        name: 'CloseAction',
        props: {
            closed: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            tooltipText() {
                if (this.closed) {
                    return this.$trans('action.open', {}, 'frontend');
                }

                return this.$trans('action.close', {}, 'frontend');
            },
            screenWidth() {
                return this.$store.getters['configuration/getSetting']('screenWidth');
            },
        },
        methods: {
            toggleClosed() {
                if (this.closed) {
                    this.$emit('open');
                } else {
                    this.$emit('close');
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .subscribe-action {
        &--subscribed {
            color: var(--color-primary);
        }
    }
</style>
