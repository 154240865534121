var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "notification",
      class: [
        { "notification--has-buttons": _vm.$slots.button },
        "notification--" + _vm.type
      ]
    },
    [
      _vm.type === "error"
        ? _c("i", { staticClass: "notification__icon fas fa-exclamation" })
        : _vm.type === "success"
        ? _c("i", { staticClass: "notification__icon fas fa-check" })
        : _vm.type === "warning"
        ? _c("i", {
            staticClass: "notification__icon fas fa-exclamation-triangle"
          })
        : _vm.type === "points-rewarded"
        ? _c("i", { staticClass: "notification__icon far fa-angle-double-up" })
        : _vm.type === "badge-rewarded"
        ? _c("i", { staticClass: "notification__icon fas fa-trophy" })
        : _c("i", { staticClass: "notification__icon fa", class: _vm.icon }),
      _vm._v(" "),
      _c("div", {
        staticClass: "notification__message",
        domProps: { innerHTML: _vm._s(_vm.message) }
      }),
      _vm._v(" "),
      _vm.$slots.button
        ? _c(
            "div",
            { staticClass: "notification__buttons" },
            [_vm._t("button")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canBeRemoved
        ? _c(
            "a",
            {
              staticClass: "notification__remove",
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.remove }
            },
            [
              _c("i", {
                staticClass: "notification__remove-icon fas fa-remove"
              })
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }