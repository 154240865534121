import AssignmentView from './Overview/AssignmentView/AssignmentView.vue';

const JournalModule = () => import(/* webpackChunkName: 'journal-module' */ './JournalModule.vue');
const AssignmentOverview = () => import(/* webpackChunkName: 'journal-module' */ './Overview/AssignmentOverview.vue');

export default {
    path: '/journal',
    name: 'JournalModule',
    component: JournalModule,
    meta: { bodyClass: 'module-journal' },
    children: [
        {
            path: '',
            name: 'AssignmentOverview',
            component: AssignmentOverview,
            meta: { bodyClass: 'module-journal__assignment-overview' },
        },
        {
            path: 'show/:id',
            name: 'Shows',
            component: AssignmentView,
            meta: { bodyClass: 'module-journal__assignment-overview' },
        },
        {
            path: 'show/:id/0/:slug',
            name: 'Shows',
            component: AssignmentView,
        },
    ],
};
