var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "actions-list__action rating-action" },
    [
      _vm.canRate
        ? [
            _c(
              "a",
              {
                staticClass:
                  "rating-action__rate-action btn btn--text btn--action",
                class: {
                  "rating-action__rate-action--rated": _vm.rating.user_rating
                },
                attrs: {
                  tabindex: "-1",
                  "data-cy": "actions-list-rate",
                  "aria-label": "rating action",
                  href: "#"
                },
                on: {
                  click: function(e) {
                    return _vm.showModalToRate(e)
                  }
                }
              },
              [
                _vm.rating.user_rating
                  ? _c("i", { staticClass: "rating-action__icon fas fa-star" })
                  : _c("i", { staticClass: "rating-action__icon far fa-star" })
              ]
            ),
            _vm._v(" "),
            _c(
              "modal-component",
              {
                staticClass: "rating-action__modal",
                attrs: { show: _vm.showRateModal },
                on: {
                  close: function($event) {
                    _vm.showRateModal = false
                  }
                }
              },
              [
                _c(
                  "template",
                  { slot: "title" },
                  [
                    _vm._t("rate-label", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("trans")("rate.share_vote")) +
                          "\n                "
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _c("template", { slot: "content" }, [
                  _c(
                    "div",
                    { staticClass: "rating-action__stars-container" },
                    [
                      _vm.loading
                        ? _c("loading-spinner", {
                            staticClass: "rating-action__loading-spinner"
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "rating-action__form",
                          class: {
                            "rating-action__form--loading": _vm.loading
                          },
                          attrs: { "data-cy": "rate-modal-form" }
                        },
                        [
                          _vm._l(5, function(index) {
                            return _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.ratingModel,
                                  expression: "ratingModel"
                                }
                              ],
                              key: "input-" + index,
                              staticClass: "rating-action__input",
                              attrs: {
                                id: _vm.randomString + "-" + index,
                                type: "radio",
                                disabled: _vm.loading,
                                name: "rating",
                                "data-cy": "rate-modal-rate-button"
                              },
                              domProps: {
                                value: index,
                                checked: _vm._q(_vm.ratingModel, index)
                              },
                              on: {
                                change: [
                                  function($event) {
                                    _vm.ratingModel = index
                                  },
                                  function(e) {
                                    return _vm.rate(index)
                                  }
                                ]
                              }
                            })
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "rating-action__stars" },
                            _vm._l(5, function(index) {
                              return _c(
                                "label",
                                {
                                  key: "label-" + (6 - index),
                                  staticClass: "rating-action__label",
                                  class: {
                                    "rating-action__label--selected":
                                      _vm.ratingModel === 6 - index
                                  },
                                  attrs: {
                                    for: _vm.randomString + "-" + (6 - index)
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "rating-action__star",
                                      attrs: {
                                        "data-cy": "rate-modal-rate-star"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "rating-action__star-icon fas fa-star"
                                      })
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "rating-action__close-modal btn btn--primary",
                    attrs: { "data-cy": "rate-modal-close" },
                    on: {
                      click: function($event) {
                        _vm.showRateModal = false
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("trans")("action.close")) +
                        "\n            "
                    )
                  ]
                )
              ],
              2
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.canViewRating
        ? [
            _c(
              "a",
              {
                staticClass:
                  "rating-action__show-action btn btn--text btn--action",
                attrs: {
                  tabindex: "-1",
                  "data-cy": "actions-list-show-ratings"
                },
                on: {
                  click: function(e) {
                    return _vm.showModal(e)
                  }
                }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "rating-action__average",
                    attrs: { "data-cy": "actions-list-rating-average" }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.rating.average) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "small",
                  {
                    staticClass: "rating-action__total",
                    attrs: { "data-cy": "actions-list-ratings-total" }
                  },
                  [
                    _vm._v(
                      "\n                (" +
                        _vm._s(_vm.rating.total) +
                        ")\n            "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "modal-component",
              {
                staticClass: "rating-action__modal",
                attrs: { show: _vm.showRatingsModal },
                on: {
                  close: function($event) {
                    _vm.showRatingsModal = false
                  }
                }
              },
              [
                _c("template", { slot: "title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("transChoice")(
                          "rate.ratings_amount",
                          _vm.rating.total
                        )
                      ) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "content" },
                  [
                    _vm.rating.total === 0
                      ? _c(
                          "div",
                          { staticClass: "rating-action__no-ratings" },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm._f("trans")("rate.no_ratings")) +
                                "\n                "
                            )
                          ]
                        )
                      : _c(
                          "transition-group",
                          {
                            staticClass: "rating-action__list",
                            attrs: { name: "rating-list", tag: "ul" }
                          },
                          _vm._l(_vm.ratings, function(userRating, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticClass: "rating-action__list-item"
                              },
                              [
                                _c("user-avatar", {
                                  staticClass: "rating-action__list-avatar",
                                  attrs: { user: userRating.user, linked: "" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "rating-action__list-content"
                                  },
                                  [
                                    _c("user-name", {
                                      staticClass:
                                        "rating-action__list-username",
                                      attrs: {
                                        user: userRating.user,
                                        linked: ""
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("rating-indicator", {
                                      staticClass: "rating-action__list-rating",
                                      attrs: {
                                        rating: userRating.rating,
                                        colored: true
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          0
                        ),
                    _vm._v(" "),
                    _vm.loadingRatings
                      ? _c("loading-spinner", {
                          staticClass: "rating-action__loading-ratings-spinner"
                        })
                      : _vm.ratings.length < _vm.rating.total
                      ? _c(
                          "div",
                          { staticClass: "rating-action__load-more-container" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn--soft",
                                attrs: { disabled: _vm.loadingRatings },
                                on: {
                                  click: function(e) {
                                    return _vm.loadRatings(e)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("trans")("action.load_more")
                                    ) +
                                    "\n                    "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "rating-action__close-modal btn btn--primary",
                    attrs: { "data-cy": "ratings-modal-close" },
                    on: {
                      click: function($event) {
                        _vm.showRatingsModal = false
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("trans")("action.close")) +
                        "\n            "
                    )
                  ]
                )
              ],
              2
            )
          ]
        : _c(
            "span",
            {
              staticClass:
                "rating-action__show-action rating-action__show-action--disabled btn btn--text btn--action"
            },
            [
              !_vm.canRate
                ? _c("i", { staticClass: "rating-action__icon fas fa-star" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "rating-action__average",
                  attrs: { "data-cy": "actions-list-rating-average" }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.rating.average) + "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "small",
                {
                  staticClass: "rating-action__total",
                  attrs: { "data-cy": "actions-list-ratings-total" }
                },
                [
                  _vm._v(
                    "\n            (" + _vm._s(_vm.rating.total) + ")\n        "
                  )
                ]
              )
            ]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }