<template>
    <div id="entry-action" class="ajax-journal-overview" :class="{'dashboard-mode-enabled': dashboardMode}">
        <article>
            <div v-if="!showEntryForm" class="author-wrapper flex flex-wrap">
                <div class="journal-head flex flex-wrap">
                    <div class="metrics__metric metrics__author">
                        <user-avatar :linked="true" :user="entry.author" class="journal-avatar-figure" />
                    </div>
                    <div class="post-author">
                        <span class="postdate">{{ formattedDate(entry.entry_date) }}</span>
                        {{ 'journal.journal_entry.by'|trans({}, 'journal_frontend') }}
                        <user-name rel="author" :linked="true" :user="entry.author" />
                        <div v-if="entry.location_name" class="location">
                            <i class="fa-regular fa-location-dot" /> {{ entry.location_name }}
                        </div>
                    </div>
                </div>
                <div class="main-content">
                    <section class="journal-content">
                        <div class="journal-message">
                            <div v-html="entry.html" />
                            <div
                                v-if="visuals && visuals.length > 0 && $store.getters['configuration/getSetting']('screenWidth') >= 900"
                                class="main-item__visuals"
                            >
                                <visuals-carousel :visuals="visuals" thumbnail-key="300x300" thumbnail2x-key="500x500" :class="{'comment__visuals': dashboardMode }" />
                            </div>
                        </div>
                        <div
                            v-if="visuals && visuals.length > 0 && $store.getters['configuration/getSetting']('screenWidth') < 900"
                            class="main-item__visuals-mobile"
                        >
                            <visuals-carousel :visuals="visuals" thumbnail-key="300x300" thumbnail2x-key="500x500" />
                        </div>

                        <actions-list class="main-item__actions-list">
                            <journal-liking
                                slot="first"
                                :journal-id="entry.id"
                                :like-total="entry.likes.total"
                                :has-liked="entry.likes.has_liked"
                                :can-like="$can('can-like-journal', entry)"
                            />
                            <edit-action
                                v-if="$can('can-edit-journal', entry)"
                                slot="first"
                                class="comment__action--edit"
                                @edit="showEntryForm = true"
                            />
                            <delete-action
                                v-if="$can('can-delete-journal', entry)"
                                slot="hidden"
                                class="comment__action--delete"
                                @delete="removeEntry(entry.id)"
                            >
                                <template slot="title">
                                    {{ 'journal.entry_delete'|trans({}, 'journal_frontend') }}
                                </template>
                                <template slot="content">
                                    {{
                                        'journal.journal_entry.action.confirm_delete_message'|trans({}, 'journal_frontend')
                                    }}
                                </template>
                                <template slot="confirm-label">
                                    {{ 'journal.journal_entry.action.delete'|trans({}, 'journal_frontend') }}
                                </template>
                            </delete-action>
                            <report-action
                                slot="hidden"
                                :reported="entry.reported"
                                @report="report"
                            />
                            <bookmark-action
                                v-if="$can('can-bookmark-journal', entry)"
                                slot="hidden"
                                :bookmarked="entry.is_bookmarked"
                                @bookmark="bookmark(...arguments)"
                                @unbookmark="unbookmark"
                            />
                            <permalink-action
                                slot="hidden"
                                class="comment__action--permalink"
                                @copy-permalink="copyPermalink"
                            />
                            <reply-action
                                v-if="$can('can-add-comment', entry)"
                                slot="last"
                                v-scroll-to="{ el: '#article-comment-form-'+entry.id, offset: -100, onDone: focusCommentField(entry) }"
                                @reply="addCommentEnabled = true"
                            />
                        </actions-list>
                    </section>
                </div>
            </div>
            <div v-else class="edit-entry">
                <create-new-entry :initial-value="entry" @cancel="cancelEdit" />
            </div>
            <section class="article-comments comments-section">
                <div class="section-heading">
                    <div class="article__comments-section-title section-heading__title section-title">
                        {{ 'journal.journal_entry.comments.comment_heading'|trans({}, 'journal_frontend') }}

                        <span class="section-title__count">
                            {{ totalComments }}
                        </span>
                    </div>
                </div>
                <div class="comments-section__wrapper">
                    <div
                        v-if="$can('can-add-comment', entry) && addCommentEnabled"
                        class="article__comments-form-container comments-section__form-container"
                        data-cy="comment-form-container"
                    >
                        <h4 class="article__comments-form-title comments-section__form-title">
                            {{ 'journal.journal_entry.comments.leave_a_comment'|trans({}, 'journal_frontend') }}
                        </h4>
                        <comment-form
                            :id="'article-comment-form-'+ entry.id"
                            ref="commentForm"
                            :enabled="$can('can-add-comment', entry)"
                            :identifier="`journal:comments:${entry.id}:create`"
                            :mention-url="mentionUrl"
                            class="article__comments-form comments-section__form"
                            @save="saveComment"
                        >
                            <cmnty-uploader
                                id="attachments"
                                slot="uploader"
                                ref="uploader"
                                v-model="commentAttachments"
                                name="attachments"
                                mapping="journal_comment_attachment"
                                translation-domain="uploader_frontend"
                            />
                            <button slot="button" class="comments-form__button btn btn--text btn--sm" @click="hideComment">
                                {{ 'action.cancel'|trans }}
                            </button>
                        </comment-form>
                    </div>
                    <journal-comments
                        v-for="(comment, index) in entry.comments"
                        :key="index"
                        :comment="comment"
                        :entry-id="entry.id"
                        :objective-id="entry.objective_id"
                        :dashboard-mode="dashboardMode"
                        @add-to-notes="$emit('add-to-notes', $event);"
                        @add-to-backroom="$emit('add-to-backroom', $event);"
                    />
                </div>
            </section>
        </article>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import CmntyUploader from '../../../../Core/Attachments/Uploader/CmntyUploader.vue';
    import CreateNewEntry from './CreateNewEntry.vue';
    import JournalComments from './JournalComments.vue';
    import JournalLiking from './JournalLiking.vue';
    import AccessDeniedError from '../../../../Core/Http/AccessDeniedError';

    const CommentForm = () => import(/* webpackChunkName: 'comment-form' */ '../../../../Core/Comments/CommentForm.vue');

    export default {
        components: {
            CmntyUploader,
            JournalLiking,
            JournalComments,
            CommentForm,
            CreateNewEntry,
        },
        props: {
            entry: {
                type: Object,
                default: () => {},
            },
            dashboardMode: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                commentAttachments: [],
                showEntryForm: false,
                addCommentEnabled: false,
            };
        },
        computed: {
            totalComments() {
                return this.entry.comments.length;
            },
            visuals() {
                return this.entry?.attachments?.attachments ?? [];
            },
            mentionUrl() {
                return '';
            },

        },

        methods: {
            ...mapActions('journal', ['addComment', 'bookmarkEntries', 'unbookmarkEntries', 'deleteEntry']),
            async bookmark(category) {
                const payload = {
                    category,
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.bookmarkEntries({ entryId: this.entry.id, payload });
                this.$toaster.success(
                    this.$trans('journal.journal_entry.action.bookmark_add_success', {}, 'journal_frontend'),
                    true,
                );
            },
            async unbookmark() {
                const payload = {
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.unbookmarkEntries({ entryId: this.entry.id, payload });
                this.$toaster.success(
                    this.$trans('journal.journal_entry.action.bookmark_remove_success', {}, 'journal_frontend'),
                    true,
                );
            },
            formattedDate(date) {
                return this.$moment(date).format('LLLL');
            },
            async saveComment(text) {
                const payload = {
                    text,
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.addComment({
                    entryId: this.entry.id, payload: { ...payload, attachments: this.commentAttachments }, author: this.$store.getters['user/getUser'],
                });
                this.attachments = [];
                this.$refs.uploader.resetFiles();
                this.$toaster.success(
                    this.$trans('journal.journal_entry.comments.comment_add_success', {}, 'journal_frontend'),
                    true,
                );
                this.addCommentEnabled = false;
            },
            hideComment() {
                this.addCommentEnabled = false;
            },
            async removeEntry(entryId) {
                const payload = {
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.deleteEntry({ entryId, payload });
                this.$toaster.success(
                    this.$trans('journal.journal_entry.action.entry_delete_success', {}, 'journal_frontend'),
                    true,
                );
            },
            cancelEdit() {
                this.showEntryForm = false;
            },
            async report(reason) {
                try {
                    await this.$store.dispatch('journal/reportEntry', { entryId: this.entry.id, reason });

                    this.$toaster.success(this.$trans('reporting.report.event'), true);
                } catch (error) {
                    if (error instanceof AccessDeniedError) {
                        throw error;
                    }

                    this.$toaster.error(this.$trans('reporting.report.error'), true);
                }
            },
            async copyPermalink() {
                // const permalink = this.$url('cmnty_task_list_journal_objectives_view', {
                //     id: this.objectiveId,
                //     entry_id: this.entryId,
                //     comment_id: this.comment.id,
                // });

                const permalink = `${document.location.origin}/journal/show/${this.entry.objective_id}?entry_id=${this.entry.id}`;
                await this.$copyText(permalink);

                this.$toaster.success(this.$trans('comments.permalink_copied', {}, 'frontend'), true);
            },
            focusCommentField(entry) {
                setTimeout(() => {
                    if (document.querySelector(`#article-comment-form-${entry.id}`)) {
                        document.querySelector(`#article-comment-form-${entry.id}`).querySelector('textarea').focus();
                    }
                }, 200);
            },
        },
    };
</script>

<style scoped lang="scss">
    .ajax-journal-overview{
        margin-top: 2em;
        .author-wrapper{
            margin-bottom: 32px;
        }
        .comment__visuals {
            width: 100px;
            float: right;
            flex: 0 0 auto;
            margin-left: 15px;
            margin-bottom: 0px;
        }
    }
    .metrics__metric {
        align-items: flex-start;
        display: inline-flex;
        font-size: calc(1rem - 2px);
    }
    .journal-avatar-figure {
        flex-shrink: 0;
        height: 60px;
        margin-right: 16px;
        width: 60px;
    }
    figure.avatar {
        background: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        width: 60px;
        height: 60px;
        margin-bottom: 0;
    }
    .avatar img{
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }
    .avatar-shape {
        border-radius: var(--avatar-border-radius) !important;
        overflow: hidden;
    }
    .post-author{
        border-bottom: 1px solid var(--color-primary);
        width: calc(80% - 72px);
        padding-bottom: 5px;
    }
    .main-content{
        margin-left: 10px;
        width: 100%;
        padding-left: 65px;

    }
    .article-comments {
        border-left: 0;
        margin-top: 10px;
        padding-left: 65px;
        width: calc(100% - 70px);
    }
    @media screen and (max-width: 1160px) {
        .article-comments {
            margin-top: 5px;
            padding-left: 0px !important;
            width: 100%;
        }
        .main-content {
            padding-left: 0px;
        }
    }
    .location {
        color: #999999;
        font-size: 14px;
        margin: 6px 0px;
    }
    .journal-content {
        word-break: break-word;
        white-space: normal;
    }
    .journal-message {
        margin-bottom: 1em;
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        .main-item__visuals {

        }
    }
    .flex-wrap {
        flex-wrap: wrap;
    }

    .dashboard-mode-enabled {
        .main-item__visuals {
            margin-left: 0px;
        }
        .journal-message {
            margin-bottom: 5px;
        }
        .author-wrapper {
            margin-bottom: 0px;
        }
        .article-comments {
            margin-top: 0px
        }
        .section-heading {
            margin-bottom: 0px;
        }
        .main-item__actions-list {
            display: none;
        }
        .main-content:hover {
            .main-item__actions-list {
                display: inline-flex;
            }
        }
    }
</style>
