var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "sidebar" } }, [
    _vm.enabledElements.length > 0
      ? _c(
          "aside",
          {
            staticClass: "main-container__sidebar sidebar",
            class: { "sidebar--open": _vm.expanded }
          },
          [
            _c(
              "smooth-reflow",
              { staticClass: "sidebar__list", attrs: { tag: "div" } },
              [
                _vm._l(_vm.enabledElements, function(element, index) {
                  return [
                    element.component === "custom-element"
                      ? _c("custom-element", {
                          key: index,
                          attrs: {
                            title: element.title,
                            content: element.content
                          }
                        })
                      : _c(
                          element.component,
                          _vm._b(
                            {
                              key: index,
                              tag: "component",
                              attrs: { position: "sidebar" },
                              on: {
                                disable: function($event) {
                                  return _vm.disableElement(element.component)
                                },
                                enable: function($event) {
                                  return _vm.enableElement(element.component)
                                }
                              }
                            },
                            "component",
                            _vm.getElementProps(element),
                            false
                          )
                        )
                  ]
                })
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }