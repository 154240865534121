var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _vm.meta.routerOutletOnly
        ? _c(
            "div",
            { staticClass: "main-container__content" },
            [
              _c("router-view"),
              _vm._v(" "),
              _c("blueimp-modal"),
              _vm._v(" "),
              _c("toasts-container"),
              _vm._v(" "),
              _c("cookie-modal")
            ],
            1
          )
        : [
            _c("navigation-wrapper"),
            _vm._v(" "),
            _c("div", { staticClass: "main-background" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "main-wrapper", attrs: { id: "wrapper" } },
              [
                _c("status-messages"),
                _vm._v(" "),
                _c("main-header"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header-container" },
                  [
                    _c("widgets-container", {
                      staticClass: "header-container__items",
                      attrs: { configuration: "header" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "main-container" },
                  [
                    _c("div", { staticClass: "main-container__box" }, [
                      _c(
                        "div",
                        { staticClass: "top-container main-container__top" },
                        [
                          _c("widgets-container", {
                            staticClass: "top-container__items",
                            attrs: { configuration: "top" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "main-container__content" },
                        [_c("router-view")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "bottom-container main-container__bottom"
                        },
                        [
                          _c("widgets-container", {
                            staticClass: "bottom-container__items",
                            attrs: { configuration: "bottom" }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("sidebar-component")
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "footer-container" },
                  [
                    _c("widgets-container", {
                      staticClass: "footer-container__items",
                      attrs: { configuration: "footer" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("main-footer")
              ],
              1
            ),
            _vm._v(" "),
            _c("toasts-container"),
            _vm._v(" "),
            _c("cookie-modal"),
            _vm._v(" "),
            _c("blueimp-modal"),
            _vm._v(" "),
            _c("nps-component", {
              attrs: {
                scope: "focus_community_manager_finished_meeting",
                "translation-parameters": {
                  "{name}": _vm.$store.getters["user/getUser"].display_name,
                  "{module_name}": _vm.$trans(
                    "module_name",
                    {},
                    "focus_frontend"
                  )
                }
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }