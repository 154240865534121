import Vue from 'vue';

function getRandomId() {
    return Math.floor(Math.random() * 90000 + 10000);
}

export default {
    namespaced: true,
    state() {
        return {
            toasts: [],
        };
    },
    getters: {
        toasts: (state) => state.toasts,
    },
    mutations: {
        addToast(state, payload) {
            state.toasts.push(payload);
        },
        removeToast(state, id) {
            const index = state.toasts.findIndex((Toast) => Toast.id === id);
            state.toasts.splice(index, 1);
        },
        clearToasts(state) {
            state.toasts = [];
        },
    },
    actions: {
        addToast({ commit, dispatch }, Toast) {
            Vue.set(Toast, 'id', getRandomId());
            commit('addToast', Toast);

            if (Toast.autoRemove) {
                setTimeout(() => {
                    dispatch('removeToast', Toast);
                }, 8000);
            }
        },
        removeToast({ commit }, { id }) {
            commit('removeToast', id);
        },
        clearToasts({ commit }) {
            commit('clearToasts');
        },
    },
};
