<template>
    <div class="loading-double-bounce">
        <div class="loading-double-bounce__one" />
        <div class="loading-double-bounce__two" />
    </div>
</template>

<script>
    export default {
        name: 'LoadingDoubleBounce',
    };
</script>

<style lang="scss" scoped>
    .loading-double-bounce {
        height: 100%;
        position: relative;
        width: 100%;

        &__one,
        &__two {
            animation: doubleBounce 2.0s infinite ease-in-out;
            background-color: var(--color-primary);
            border-radius: 50%;
            height: 100%;
            left: 0;
            opacity: .6;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &__two {
            animation-delay: -1s;
        }
    }

    @keyframes doubleBounce {
        0%,
        100% {
            transform: scale(0);
        }

        50% {
            transform: scale(1);
        }
    }
</style>
