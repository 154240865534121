import AccessAdmin from './Voters/AccessAdmin';
import AccessPulse from './Voters/AccessPulse';
import AccessNote from './Voters/AccessNote';
import AccessDashboardContent from './Voters/AccessDashboardContent';
import AddComment from './Voters/AddComment';
import AddIdea from './Voters/AddIdea';
import Bookmark from './Voters/Bookmark';
import Delete from './Voters/Delete';
import IgnoreUserGroups from './Voters/IgnoreUserGroups';
import Like from './Voters/Like';
import Move from './Voters/Move';
import Update from './Voters/Update';
import Rate from './Voters/Rate';
import Reply from './Voters/Reply';
import StartThread from './Voters/StartThread';
import Subscribe from './Voters/Subscribe';
import SwitchLocale from './Voters/SwitchLocale';
import ViewComments from './Voters/ViewComments';
import ViewIdeas from './Voters/ViewIdeas';
import ViewLikes from './Voters/ViewLikes';
import ViewProfile from './Voters/ViewProfile';
import ViewRating from './Voters/ViewRating';
import ViewUserGroups from './Voters/ViewUserGroups';
import FilterOnStatus from './Voters/FilterOnStatus';
import MarkChallengesAsRead from './Voters/MarkChallengesAsRead';
import Report from './Voters/Report';
import UseMoodboard from './Voters/UseMoodboard';
import MarkBlogArticlesAsRead from './Voters/MarkBlogArticlesAsRead';
import StartMeeting from './Voters/StartMeeting';
import EndMeeting from './Voters/EndMeeting';
import JoinMeeting from './Voters/JoinMeeting';
import ScheduleMeeting from './Voters/ScheduleMeeting';
import ParticipateInMeeting from './Voters/ParticipateInMeeting';
import MuteAttendee from './Voters/MuteAttendee';
import SeeObservers from './Voters/SeeObservers';
import ViewSolutionPromotions from './Voters/ViewSolutionPromotions';
import RemoveAttendee from './Voters/RemoveAttendee';
import MuteAttendees from './Voters/MuteAttendees';
import RoleCommunityManager from './Voters/RoleCommunityManager';
import AccessFocus from './Voters/AccessFocus';
import NavigatePlatforms from './Voters/NavigatePlatforms';
import FocusVoters from './Voters/Focus/FocusVoters';
import ForumVoters from './Voters/Forum/ForumVoters';
import JournalVoters from './Voters/Journal/JournalVoters';
import ModeratorDashboardVoters from './Voters/ModeratorDashboard/ModeratorDashboardVoters';
import AccessBackroomComment from './Voters/AccessBackroomComment';

export default class VoterRegistry {
    voters;

    constructor() {
        this.voters = {
            'access-admin': AccessAdmin,
            'access-pulse': AccessPulse,
            'access-dashboard-content': AccessDashboardContent,
            'access-note': AccessNote,
            'access-backroom-comment': AccessBackroomComment,
            'add-comment': AddComment,
            'add-idea': AddIdea,
            bookmark: Bookmark,
            delete: Delete,
            'ignore-user-groups': IgnoreUserGroups,
            'filter-on-status': FilterOnStatus,
            like: Like,
            move: Move,
            update: Update,
            rate: Rate,
            reply: Reply,
            subscribe: Subscribe,
            'start-thread': StartThread,
            'switch-locale': SwitchLocale,
            'view-comments': ViewComments,
            'view-ideas': ViewIdeas,
            'view-likes': ViewLikes,
            'view-profile': ViewProfile,
            'view-rating': ViewRating,
            'view-user-groups': ViewUserGroups,
            'mark-challenges-as-read': MarkChallengesAsRead,
            report: Report,
            'use-moodboard': UseMoodboard,
            'mark-blog-articles-as-read': MarkBlogArticlesAsRead,
            'start-meeting': StartMeeting,
            'end-meeting': EndMeeting,
            'join-meeting': JoinMeeting,
            'schedule-meeting': ScheduleMeeting,
            'participate-in-meeting': ParticipateInMeeting,
            'mute-attendee': MuteAttendee,
            'mute-attendees': MuteAttendees,
            'remove-attendee': RemoveAttendee,
            'see-observers': SeeObservers,
            'navigate-platforms': NavigatePlatforms,
            'view-solution-promotions': ViewSolutionPromotions,
            'role-moderator': RoleCommunityManager,
            'access-focus': AccessFocus,
            ...FocusVoters,
            ...ForumVoters,
            ...JournalVoters,
            ...ModeratorDashboardVoters,
        };
    }

    getVoter(action) {
        const voter = this.voters[action];
        if (!voter) {
            throw new Error(`The voter for action '${action}' does not exists.\n\nPossible actions are: ${Object.keys(this.voters).join(', ')}.\n`);
        }

        return voter;
    }
}
