var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.platformMenuItems.length > 0
    ? _c(
        "div",
        { staticClass: "platform-icon-wrapper" },
        [
          _c(
            "a",
            {
              staticClass: "explore-navigation__user-navigation-toggle ",
              class: {
                "explore-navigation__user-navigation-toggle--expanded":
                  _vm.expandedUserNavigation
              },
              attrs: { tabindex: "0", "data-jest": "platform-select" },
              on: {
                click: _vm.toggleUserNavigation,
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.toggleUserNavigation($event)
                }
              }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "platform-icon",
                attrs: { icon: "fa-solid fa-grid" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide-left-in" } }, [
            _c(
              "nav",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expandedUserNavigation,
                    expression: "expandedUserNavigation"
                  }
                ],
                staticClass: "explore-navigation__user-navigation"
              },
              [
                _c(
                  "ul",
                  {
                    staticClass:
                      "explore-navigation__user-navigation-list platform-list"
                  },
                  _vm._l(_vm.platformMenuItems, function(platformItem, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class:
                          "explore-navigation__user-navigation-item explore-navigation__user-navigation-item--" +
                          index +
                          " platform-list-item"
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "explore-navigation__user-navigation-link",
                            attrs: {
                              href: platformItem.url,
                              "data-jest": "platformItemList",
                              target: "_blank"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "explore-navigation__user-navigation-label"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "platform-initials" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getInitial(platformItem.name))
                                    )
                                  ]
                                ),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(platformItem.name) +
                                    "\n                        "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }