<template>
    <div v-if="visible" class="promote-solution">
        <slot />
        <promote-solution-modal
            :slides="modalSlides"
            :show="modalIsVisible"
            :solution-name="solutionName"
            @close="modalIsVisible = false"
        />
    </div>
</template>

<script>
    import Cookie from 'js-cookie';
    import PromoteSolutionModal from './PromoteSolutionModal.vue';

    export default {
        name: 'PromoteSolution',
        components: {
            PromoteSolutionModal,
        },
        props: {
            solutionName: {
                type: String,
                required: true,
            },
            modalSlides: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                visible: true,
                modalIsVisible: false,
            };
        },
        created() {
            this.$on('close', this.close);
            this.$on('trigger', this.showModal);
            if (Cookie.get(this.solutionName)) {
                this.visible = false;
            }
        },
        methods: {
            close() {
                this.visible = false;
                Cookie.set(this.solutionName, 1, { expires: 365 });
            },
            showModal() {
                this.modalIsVisible = true;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .promote-solution {
        align-items: center;
        display: flex;
    }
</style>
