const DashboardModule = () => import(/* webpackChunkName: 'dashboard-module' */ './DashboardModule.vue');
const MyDashboard = () => import(/* webpackChunkName: 'dashboard-overview' */ './Widget/MyDashboardWidget.vue');

export default {
    path: '/moderation/dashboard',
    name: 'DashboardModule',
    component: DashboardModule,
    meta: { bodyClass: 'module-dashboard', routerOutletOnly: true },
    children: [
        {
            path: '',
            name: 'DashboardOverview',
            component: MyDashboard,
            meta: { bodyClass: 'module-dashboard__assignment-overview', routerOutletOnly: true },
        },
    ],
};
