<template>
    <div v-if="platformMenuItems.length > 0" class="platform-icon-wrapper">
        <a
            class="explore-navigation__user-navigation-toggle "
            :class="{ 'explore-navigation__user-navigation-toggle--expanded': expandedUserNavigation }"
            tabindex="0"
            data-jest="platform-select"
            @click="toggleUserNavigation"
            @keyup.down.enter="toggleUserNavigation"
        >
            <font-awesome-icon icon="fa-solid fa-grid" class="platform-icon" />
        </a>

        <transition name="slide-left-in">
            <nav v-show="expandedUserNavigation" class="explore-navigation__user-navigation">
                <ul class="explore-navigation__user-navigation-list platform-list">
                    <li v-for="(platformItem, index) in platformMenuItems" :key="index" :class="`explore-navigation__user-navigation-item explore-navigation__user-navigation-item--${index} platform-list-item` ">
                        <a class="explore-navigation__user-navigation-link" :href="platformItem.url" data-jest="platformItemList" target="_blank">
                            <span class="explore-navigation__user-navigation-label">
                                <span class="platform-initials">{{ getInitial(platformItem.name) }}</span>
                                {{ platformItem.name }}
                            </span>
                        </a>
                    </li>
                </ul>
            </nav>
        </transition>
    </div>
</template>

<script>
    import NavigationApi from '../NavigationApi';

    export default {
        data() {
            return {
                platformMenuItems: [],
                expandedUserNavigation: false,
            };
        },
        async created() {
            const response = await NavigationApi.getPlatformList();
            this.platformMenuItems = response.body && response.body.length ? response.body : [];
        },
        methods: {
            getInitial(platformName) {
                let initials = platformName.split(' ');

                if (initials.length > 1) {
                    initials = initials.shift().charAt(0) + initials.pop().charAt(0);
                } else {
                    initials = platformName.substring(0, 2);
                }

                return initials.toUpperCase();
            },
            closeUserNavigation() {
                this.expandedUserNavigation = false;
            },
            toggleUserNavigation() {
                this.expandedUserNavigation = !this.expandedUserNavigation;
            },
        },
    };
</script>

<style scoped lang="scss">
.explore-navigation {
        background-color: var(--color-menu-background);
        box-shadow: 0 0 10px 5px rgba(#000, .3);
        color: var(--color-menu-text);
        display: flex;
        height: 60px;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 3;

        @include max-breakpoint(screen-xs-max) {
            display: none;
        }

        &__inner {
            display: flex;
            height: 100%;
            position: relative;
            width: 100%;
        }

        // Main navigation

        &__main-navigation {
            display: inline-flex;
            margin-left: $gutter-l;
            margin-right: auto;
            max-width: 100%;

            @media (min-width: 992px) {
                margin-left: auto;
            }
        }

        &__main-list {
            @include reset-list();
            display: flex;
            font-size: $font-m;
        }

        &__main-item {
            display: inline-flex;
            flex-shrink: 0;
            word-break: break-word;
            max-width: 170px;
        }

        &__main-link {
            align-items: center;
            color: var(--color-menu-text);
            display: inline-flex;
            padding: $gutter-s $gutter-m;
            text-align: center;
            text-decoration: none;

            &:hover,
            &:focus,
            &:active,
            &[aria-expanded='true'],
            &--active {
                background: rgba(#fff, .075);
                color: var(--color-primary);
                outline: none;
            }
        }

        // More dropdown

        &__more-dropdown-container {
            flex-shrink: 0;
        }

        /deep/ .explore-navigation {
            &__more-dropdown-toggle {
                align-items: center;
                background: transparent;
                border: 0;
                border-radius: 0;
                color: var(--color-menu-text);
                display: inline-flex;
                font-size: $font-m;
                height: 60px;
                justify-content: center;
                line-height: 1.2;
                padding: $gutter-s $gutter-m;
                text-decoration: none;
                transition: all .2s ease-in-out;

                &:hover,
                &:focus,
                &:active,
                &[aria-expanded="true"] {
                    background: rgba(#fff, .075);
                    color: var(--color-primary);
                }

                &:after {
                    display: none;
                }
            }

            &__more-dropdown {
                background-color: var(--color-menu-background);
                border: 0;
                border-radius: 0;
                margin: 0;
                padding: $gutter-s 0;
            }

            &__more-dropdown-link a {
                align-items: center;
                border-radius: 0;
                color: var(--color-menu-text);
                display: inline-flex;
                font-size: $font-s;

                &:hover,
                &:focus,
                &:active {
                    background: rgba(#fff, .075);
                    color: var(--color-primary);
                }
            }
        }

        &__more-angle-down {
            font-size: $font-s;
            margin-left: $gutter-s;
            margin-top: $gutter-xxs;
        }

        &__more-dropdown-background {
            background: rgba(#fff, .075);
            bottom: 0;
            display: inline-flex;
            height: 100%;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
        }

        // Right container

        &__right {
            display: flex;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
        }

        // Admin button

        &__admin,
        &__pulse {
            flex-shrink: 0;
            position: relative;
        }

        &__admin-link,
        &__pulse-link {
            align-items: center;
            color: var(--color-menu-text);
            display: inline-flex;
            height: 100%;
            justify-content: center;
            transition: all .2s ease-in-out;
            width: 50px;

            &:hover,
            &:focus,
            &:active,
            &--active {
                background: rgba(#fff, .075);
                border-bottom: 2px solid var(--color-menu-text);
                color: var(--color-menu-text);
                outline: none;
                text-decoration: none;
                transform: scale(1.05);
            }
        }

        &__admin-icon,
        &__admin-icon {
            font-size: 20px;
        }

        // User dropdown

        &__user-navigation-toggle {
            align-items: center;
            color: var(--color-menu-text);
            cursor: pointer;
            display: inline-flex;
            height: 100%;
            justify-content: center;
            padding: 0 $gutter-s;
            transition: all .2s ease-in-out;
            width: auto;

            &:hover,
            &:focus,
            &:active {
                background: rgba(#fff, .075);
                outline: none;
                transform: scale(1.05);
            }

            &--expanded {
                background: rgba(#fff, .075);
                .platform-icon{
                    color: var(--color-primary);
                }
            }
        }

        &__avatar-figure {
            margin: 0;
            position: relative;
            transition: all .2s ease-in-out;
        }

        &__avatar {
            border-radius: var(--avatar-border-radius);
            height: 40px;
            width: 40px;
        }

        &__avatar-angle {
            margin-left: $gutter-xs;
        }

        &__notifications-indicator {
            background: #d9534f;
            border: 2px solid #fff;
            border-radius: 50%;
            bottom: -2px;
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, .2);
            display: inline-flex;
            height: 14px;
            position: absolute;
            right: -2px;
            width: 14px;
        }

        &__user-navigation {
            background-color: var(--color-menu-background);
            position: absolute;
            right: 0;
            top: 100%;
        }

        &__user-navigation-list {
            @include reset-list();
            background: rgba(#fff, .075);
            min-width: 220px;
            padding: 8px 0;
        }

        &__user-navigation-link {
            align-items: center;
            border-radius: 0;
            color: var(--color-menu-text);
            display: inline-flex;
            font-size: $font-m;
            padding: $gutter-xs $gutter-m;
            text-decoration: none;
            width: 100%;

            // &:hover,
            // &:focus,
            // &:active {
            //     background: rgba(#fff, .075);
            //     color: var(--color-primary);
            //     cursor: pointer;
            //     text-decoration: none;
            // }
        }

        &__user-navigation-indicator {
            align-items: center;
            background-color: var(--color-primary);
            border-radius: 50%;
            color: #fff;
            display: flex;
            font-size: $font-s;
            height: 20px;
            justify-content: center;
            margin-left: $gutter-m;
            width: 20px;

            &--notifications {
                background-color: #d9534f;
            }
        }

        &__sidebar-toggle {
            border-left: 1px solid rgba(#fff, .2);
            margin-left: $gutter-s;
            width: 54px;
        }

        &__language-switch {
            align-items: center;
            display: inline-flex;
            height: 100%;
            justify-content: center;
            transition: all .2s ease-in-out;
            width: 50px;

            &:hover,
            &:focus,
            &:active {
                color: var(--color-primary);
                outline: none;
                text-decoration: none;
            }
        }
    }
.language-switch__dropdown {
    background-color: #333333;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 8px 0;
}
.platform-switch__dropdown {
    min-width: 250px;
}
.platform-list{
    width: 300px;
}
.platform-list-item{
    padding: 10px 0px;
     &:hover,
     &:focus,
     &:active {
        background: rgba(#fff, .075);
        color: var(--color-primary);
        cursor: pointer;
        text-decoration: none;
    }
}
.platform-icon-wrapper{
    display: flex;
    align-items: center;
}
.platform-icon {
  height: 28px !important;
  width: 30px !important;
  fill: white;
  align-items: center;
  display: inline-flex;
  &:hover{
        background: rgba(#fff, .075);
        fill: var(--color-primary);
        cursor: pointer;
        text-decoration: none;
  }

}

.platform-list-item:hover > .explore-navigation__user-navigation-link > .explore-navigation__user-navigation-label > .platform-initials {
    background: var(--color-primary);
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}
.platform-initials {
  background-color: white;
  color: black;
  padding: 4px;
  border-radius: 3px;
  font-weight: 600;
  margin-right: 10px;
  min-height: 27px !important;
  min-width: 27px !important;
  &:hover{
        background: rgba(#fff, .075);
        color: var(--color-primary);
        cursor: pointer;
        text-decoration: none;
  }
}
</style>
