var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker" },
    [
      _c("v-date-picker", {
        attrs: {
          mode: "dateTime",
          masks: {
            input: _vm.dateFormat,
            inputDateTime: _vm.dateTimeFormat,
            inputDateTime24hr: _vm.dateTimeFormat
          },
          is24hr: _vm.is24hr,
          locale: _vm.locale,
          timezone: _vm.timezone
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var inputValue = ref.inputValue
                var togglePopover = ref.togglePopover
                return [
                  _c(
                    "div",
                    {
                      staticClass: "date-picker__wrapper inline-form-control",
                      on: { click: togglePopover }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "inline-form-control__label",
                          class: {
                            "inline-form-control__label--super": _vm.date !== ""
                          },
                          attrs: { for: _vm.id }
                        },
                        [_vm._t("label")],
                        2
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "inline-form-control__input",
                        attrs: { id: _vm.id, readonly: "" },
                        domProps: { value: inputValue }
                      })
                    ]
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.date,
          callback: function($$v) {
            _vm.date = $$v
          },
          expression: "date"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }