var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "button",
      staticClass:
        "close-action actions-list__action btn btn--text btn--action",
      class: { "close-action--closed": _vm.closed },
      attrs: {
        "data-cy": "actions-list-close",
        tabindex: "0",
        "data-jest": "button",
        "aria-label": "close action",
        href: "#"
      },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.toggleClosed($event)
        },
        click: _vm.toggleClosed
      }
    },
    [
      _vm.closed
        ? _c("i", {
            staticClass: "close-action__toggle-icon btn__icon fas fa-lock",
            attrs: { "data-jest": "closed-icon" }
          })
        : _c("i", {
            staticClass:
              "close-action__toggle-icon btn__icon far fa-unlock-alt",
            attrs: { "data-jest": "open-icon" }
          }),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: function() {
              return _vm.$refs.button
            },
            disabled: _vm.screenWidth <= 900,
            delay: { show: 500, hide: 0 },
            "data-jest": "tooltip"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.tooltipText) + "\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }