var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-v2__inner" },
    [
      _vm._t("top"),
      _vm._v(" "),
      _c("div", { staticClass: "upload-v2__body" }, [
        _vm.file.type == "image"
          ? _c("figure", { staticClass: "upload-v2__figure" }, [
              _c("img", {
                staticClass: "upload-v2__img",
                attrs: { src: _vm.thumbnail, alt: _vm.file.original_name }
              })
            ])
          : _vm.file.type == "video"
          ? _c("figure", { staticClass: "upload-v2__figure" }, [
              _c("span", { staticClass: "upload-v2__video-icon fas fa-play" }),
              _vm._v(" "),
              _c("div", { staticClass: "upload-v2__video-filename" }, [
                _c("span", [_vm._v(_vm._s(_vm.file.original_name))])
              ])
            ])
          : _c("div", { staticClass: "upload-v2__document" }, [
              _c("span", {
                staticClass: "upload-v2__document-icon file-icon file-icon--md",
                attrs: { "data-extension": _vm.file.extension }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "upload-v2__document-filename" }, [
                _c("span", [_vm._v(_vm._s(_vm.file.original_name))])
              ])
            ]),
        _vm._v(" "),
        _c("span", { staticClass: "upload-v2__limit-msg" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("trans")(
                  "uploader.max_files_limit_reached",
                  { "%maxFiles%": _vm.maxFiles },
                  "uploader_frontend"
                )
              ) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _vm._t("footer"),
      _vm._v(" "),
      _c("input", {
        staticClass: "upload-v2__item-hidden",
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.file.id }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }