<template>
    <div class="visuals-carousel" :class="enableFullWidth ? 'full-width' : ''">
        <ul
            :id="`bi-gallery-${suffix}`"
            ref="list"
            :data-unique-id="suffix"
            class="visuals-carousel__list bi-gallery"
            style="display: none;"
        >
            <li v-for="(visual, index) in visuals" :key="index">
                <a v-if="visual.type === 'image'" :href="visual.original_url" :type="visual.mime_type">
                    <figure>
                        {{ thumbnailKey }}
                        <img
                            :src="visual.thumbnails[thumbnailKey]"
                            :srcset="
                                `${visual.thumbnails[thumbnailKey]} 250w,
                                ${visual.thumbnails[thumbnail2xKey]} 500w`"
                            :alt="visual.original_name"
                            :data-href="visual.thumbnails[thumbnailKey]"
                        >
                    </figure>
                </a>

                <a
                    v-if="visual.type === 'video' && visual.video_status === 'complete'"
                    data-item-type="video"
                    :href="visual.original_url"
                    :type="visual.mime_type"
                    :data-poster="visual.thumbnails['400x300']"
                    :data-sources="JSON.stringify((visual.video_sources || []))"
                    :title="visual.original_name"
                />

                <a v-if="visual.type === 'video' && visual.video_status !== 'complete'" :href="visual.thumbnails['400x300']">
                    <figure>
                        <img :data-href="visual.thumbnails['400x300']" :src="visual.thumbnails['400x300']">
                    </figure>
                </a>
            </li>
        </ul>

        <div :id="`carousel-${suffix}`" class="visuals-carousel__carousel">
            <div class="visuals-carousel__wrapper">
                <div class="visuals-carousel__slides" :data-target="`#bi-gallery-${suffix}`" @click="clickCarousel" />
                <template v-if="visuals.length > 1">
                    <i class="visuals-carousel__nav visuals-carousel__nav--left far fa-angle-left" @click="prevImage" />
                    <i class="visuals-carousel__nav visuals-carousel__nav--right far fa-angle-right" @click="nextImage" />
                </template>
            </div>
            <ol ref="bulletList" class="visuals-carousel__bullet-list" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VisualsCarousel',
        props: {
            visuals: {
                type: Array,
                required: true,
            },
            thumbnailKey: {
                type: String,
                required: false,
                default: '250x230',
            },
            thumbnail2xKey: {
                type: String,
                required: false,
                default: '500x460',
            },
            enableFullWidth: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                carousel: {},
                initialized: false,
                suffix: Math.random().toString(36).substring(7),
                gallery: null,
            };
        },
        watch: {
            visuals: {
                handler: async function handleWatch() {
                    this.closeGallery();
                    this.openGallery();
                },
                deep: true,
            },
        },
        mounted() {
            this.openGallery();
        },
        beforeDestroy() {
            this.closeGallery();
        },
        methods: {
            closeGallery() {
                this.carousel.pause();
                this.carousel.close();
                this.initialized = false;
                if (this.gallery) {
                    this.gallery.close();
                }
            },
            openGallery() {
                if (this.initialized) {
                    return;
                }

                this.setCarousel();
            },
            setCarousel() {
                this.$nextTick(() => {
                    this.initGallery(this.$refs.list);

                    this.initialized = true;
                });
            },
            setAttachments(attachments) {
                if (attachments.length === 0) {
                    return;
                }

                this.attachments = attachments;
                this.setCarousel();
            },
            initGallery(gallery) {
                const anchors = gallery.querySelectorAll('a');
                const items = [];

                anchors.forEach((element) => {
                    let item = '';

                    if (element.getAttribute('data-item-type') !== 'video') {
                        [item] = element.querySelectorAll('img');
                    } else {
                        item = element;
                    }

                    items.push(item);
                });

                /* eslint-disable-next-line no-undef */
                this.carousel = blueimp.Gallery(
                    items,
                    {
                        activeIndicatorClass: 'active',
                        carousel: true,
                        container: `#carousel-${this.suffix}`,
                        continuous: true,
                        thumbnailIndicators: false,
                        transitionSpeed: 200,
                        slideClass: 'visuals-carousel__slide',
                        slideContentClass: 'visuals-carousel__slide-content',
                        slidesContainer: '.visuals-carousel__slides',
                    },
                );
                document.querySelectorAll('.visuals-carousel__slide .video-content').forEach((element) => {
                    element.addEventListener('click', () => {
                        this.$nextTick(() => {
                            setTimeout(() => {
                                document.querySelector(`#blueimp-gallery .slide:nth-child(${this.carousel.getIndex() + 1}) video`).play();
                            }, 400);
                        });
                    });
                });
                this.setAriaLabelForIndicator();
            },
            nextImage() {
                this.carousel.next();
            },
            prevImage() {
                this.carousel.prev();
            },
            clickCarousel(clickEvent) {
                const event = clickEvent;
                const container = clickEvent.target.parentNode;
                const listIndex = [...container.parentNode.children].findIndex((element) => element === container);
                const options = { index: listIndex, event, closeOnSlideClick: true };
                const links = this.$refs.list.querySelectorAll('a');

                /* eslint-disable-next-line no-undef */
                this.gallery = blueimp.Gallery(links, options);
            },
            getSources(visual) {
                if (typeof visual.sources === 'undefined') {
                    return JSON.stringify([]);
                }

                return JSON.stringify(visual.sources);
            },
            setAriaLabelForIndicator() {
                const bulletListContainer = this.$refs.bulletList;
                const bulletList = bulletListContainer.querySelectorAll('li');
                bulletList.forEach((liElement, index) => {
                    liElement.removeAttribute('role');
                    liElement.setAttribute('aria-label', `indicator link ${index + 1}`);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    $carousel-width-int: 250;
    $carousel-height-int: 230;
    $carousel-width: #{$carousel-width-int}px;

    .visuals-carousel {
        max-width: 100%;
        position: relative;
        width: $carousel-width;

        &__carousel {
            position: relative;
            max-width: 100%;
            width: $carousel-width;
        }

        &__wrapper {
            border-radius: $main-radius;
            height: auto;
            max-width: 100%;
            overflow: hidden;
            padding-bottom: calc(#{$carousel-height-int} / #{$carousel-width-int} * 100%);
            position: relative;
            width: $carousel-width;
        }

        &.full-width {
            width: 100%;
            .visuals-carousel__carousel {
                width: 100%;
            }
            .visuals-carousel__wrapper {
                width: 100%;
            }
        }

        &__slides {
            display: flex;
            flex-direction: row;
            height: 100%;
            position: absolute;
            width: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        &__nav {
            color: #fff;
            cursor: pointer;
            font-size: 2rem; // Icon
            opacity: .5;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: transform .2s;
            z-index: 1;

            &:hover,
            &:focus,
            &:active {
                opacity: 1;
                transform: translateY(-50%) scale(1.2);
            }

            &--left {
                /*rtl:ignore*/
                left: $gutter-s;
            }

            &--right {
                /*rtl:ignore*/
                right: $gutter-s;
            }
        }

        &__indicator {
            margin-top: $gutter-s;
        }

        &__bullet-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            list-style-type: none;
            margin-bottom: 0;
            margin-top: $gutter-m;
            padding-left: 0;
            width: 100%;
        }
    }
</style>

<style lang="scss">
    .visuals-carousel {
        &__slide {
            float: left;
            height: 100%;
            position: relative;
        }

        &__slide-content {
            font-family: 'object-fit: cover;';
            height: 100%;
            object-fit: cover;
            transition: all .2s ease-in-out;
            width: 100%;

            &:hover {
                transform: scale(1.03);
            }
        }

        &__bullet-list {
            li {
                background-color: #dadada;
                border-radius: 10px;
                display: inline-block;
                height: 10px;
                margin: 0 $gutter-xs $gutter-s;
                transition: all .2s ease-in-out;
                width: 10px;

                &:hover {
                    background-color: $color-base;
                    cursor: pointer;
                    transform: scale(1.1);
                }

                &.active {
                    background-color: $color-base;
                }
            }
        }

        .video-content {
            position: relative;
            .video-cover {
                position: absolute;
                top: 0;
                left: 0;
                width: 250px;
                height: 250px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat
            }

            &:after {
                align-items: center;
                background: var(--color-primary);
                border-radius: 50%;
                box-shadow: 0 0 5px 1px rgba(0, 0, 0, .2);
                color: #fff;
                content: '\f04b';
                display: inline-flex;
                font-family: 'Font Awesome 6 Pro';
                font-size: 30px;
                font-weight: 900;
                height: 80px;
                justify-content: center;
                left: 50%;
                padding-left: 5px;
                position: absolute;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 80px;
            }

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }

    [dir='rtl'] {
        .visuals-carousel {
            &__slides {
                /*rtl:ignore*/
                flex-direction: row-reverse;
                /*rtl:ignore*/
                left: 0;
            }
        }
    }
</style>
