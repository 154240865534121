var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ajax-journal-overview",
      class: { "dashboard-mode-enabled": _vm.dashboardMode },
      attrs: { id: "entry-action" }
    },
    [
      _c("article", [
        !_vm.showEntryForm
          ? _c("div", { staticClass: "author-wrapper flex flex-wrap" }, [
              _c("div", { staticClass: "journal-head flex flex-wrap" }, [
                _c(
                  "div",
                  { staticClass: "metrics__metric metrics__author" },
                  [
                    _c("user-avatar", {
                      staticClass: "journal-avatar-figure",
                      attrs: { linked: true, user: _vm.entry.author }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "post-author" },
                  [
                    _c("span", { staticClass: "postdate" }, [
                      _vm._v(_vm._s(_vm.formattedDate(_vm.entry.entry_date)))
                    ]),
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("trans")(
                            "journal.journal_entry.by",
                            {},
                            "journal_frontend"
                          )
                        ) +
                        "\n                    "
                    ),
                    _c("user-name", {
                      attrs: {
                        rel: "author",
                        linked: true,
                        user: _vm.entry.author
                      }
                    }),
                    _vm._v(" "),
                    _vm.entry.location_name
                      ? _c("div", { staticClass: "location" }, [
                          _c("i", {
                            staticClass: "fa-regular fa-location-dot"
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.entry.location_name) +
                              "\n                    "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "main-content" }, [
                _c(
                  "section",
                  { staticClass: "journal-content" },
                  [
                    _c("div", { staticClass: "journal-message" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.entry.html) }
                      }),
                      _vm._v(" "),
                      _vm.visuals &&
                      _vm.visuals.length > 0 &&
                      _vm.$store.getters["configuration/getSetting"](
                        "screenWidth"
                      ) >= 900
                        ? _c(
                            "div",
                            { staticClass: "main-item__visuals" },
                            [
                              _c("visuals-carousel", {
                                class: { comment__visuals: _vm.dashboardMode },
                                attrs: {
                                  visuals: _vm.visuals,
                                  "thumbnail-key": "300x300",
                                  "thumbnail2x-key": "500x500"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.visuals &&
                    _vm.visuals.length > 0 &&
                    _vm.$store.getters["configuration/getSetting"](
                      "screenWidth"
                    ) < 900
                      ? _c(
                          "div",
                          { staticClass: "main-item__visuals-mobile" },
                          [
                            _c("visuals-carousel", {
                              attrs: {
                                visuals: _vm.visuals,
                                "thumbnail-key": "300x300",
                                "thumbnail2x-key": "500x500"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "actions-list",
                      { staticClass: "main-item__actions-list" },
                      [
                        _c("journal-liking", {
                          attrs: {
                            slot: "first",
                            "journal-id": _vm.entry.id,
                            "like-total": _vm.entry.likes.total,
                            "has-liked": _vm.entry.likes.has_liked,
                            "can-like": _vm.$can("can-like-journal", _vm.entry)
                          },
                          slot: "first"
                        }),
                        _vm._v(" "),
                        _vm.$can("can-edit-journal", _vm.entry)
                          ? _c("edit-action", {
                              staticClass: "comment__action--edit",
                              attrs: { slot: "first" },
                              on: {
                                edit: function($event) {
                                  _vm.showEntryForm = true
                                }
                              },
                              slot: "first"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$can("can-delete-journal", _vm.entry)
                          ? _c(
                              "delete-action",
                              {
                                staticClass: "comment__action--delete",
                                attrs: { slot: "hidden" },
                                on: {
                                  delete: function($event) {
                                    return _vm.removeEntry(_vm.entry.id)
                                  }
                                },
                                slot: "hidden"
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "journal.entry_delete",
                                          {},
                                          "journal_frontend"
                                        )
                                      ) +
                                      "\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "content" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "journal.journal_entry.action.confirm_delete_message",
                                          {},
                                          "journal_frontend"
                                        )
                                      ) +
                                      "\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "confirm-label" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("trans")(
                                          "journal.journal_entry.action.delete",
                                          {},
                                          "journal_frontend"
                                        )
                                      ) +
                                      "\n                            "
                                  )
                                ])
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("report-action", {
                          attrs: {
                            slot: "hidden",
                            reported: _vm.entry.reported
                          },
                          on: { report: _vm.report },
                          slot: "hidden"
                        }),
                        _vm._v(" "),
                        _vm.$can("can-bookmark-journal", _vm.entry)
                          ? _c("bookmark-action", {
                              attrs: {
                                slot: "hidden",
                                bookmarked: _vm.entry.is_bookmarked
                              },
                              on: {
                                bookmark: function($event) {
                                  return _vm.bookmark.apply(void 0, arguments)
                                },
                                unbookmark: _vm.unbookmark
                              },
                              slot: "hidden"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("permalink-action", {
                          staticClass: "comment__action--permalink",
                          attrs: { slot: "hidden" },
                          on: { "copy-permalink": _vm.copyPermalink },
                          slot: "hidden"
                        }),
                        _vm._v(" "),
                        _vm.$can("can-add-comment", _vm.entry)
                          ? _c("reply-action", {
                              directives: [
                                {
                                  name: "scroll-to",
                                  rawName: "v-scroll-to",
                                  value: {
                                    el: "#article-comment-form-" + _vm.entry.id,
                                    offset: -100,
                                    onDone: _vm.focusCommentField(_vm.entry)
                                  },
                                  expression:
                                    "{ el: '#article-comment-form-'+entry.id, offset: -100, onDone: focusCommentField(entry) }"
                                }
                              ],
                              attrs: { slot: "last" },
                              on: {
                                reply: function($event) {
                                  _vm.addCommentEnabled = true
                                }
                              },
                              slot: "last"
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          : _c(
              "div",
              { staticClass: "edit-entry" },
              [
                _c("create-new-entry", {
                  attrs: { "initial-value": _vm.entry },
                  on: { cancel: _vm.cancelEdit }
                })
              ],
              1
            ),
        _vm._v(" "),
        _c("section", { staticClass: "article-comments comments-section" }, [
          _c("div", { staticClass: "section-heading" }, [
            _c(
              "div",
              {
                staticClass:
                  "article__comments-section-title section-heading__title section-title"
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm._f("trans")(
                        "journal.journal_entry.comments.comment_heading",
                        {},
                        "journal_frontend"
                      )
                    ) +
                    "\n\n                    "
                ),
                _c("span", { staticClass: "section-title__count" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.totalComments) +
                      "\n                    "
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "comments-section__wrapper" },
            [
              _vm.$can("can-add-comment", _vm.entry) && _vm.addCommentEnabled
                ? _c(
                    "div",
                    {
                      staticClass:
                        "article__comments-form-container comments-section__form-container",
                      attrs: { "data-cy": "comment-form-container" }
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass:
                            "article__comments-form-title comments-section__form-title"
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "journal.journal_entry.comments.leave_a_comment",
                                  {},
                                  "journal_frontend"
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "comment-form",
                        {
                          ref: "commentForm",
                          staticClass:
                            "article__comments-form comments-section__form",
                          attrs: {
                            id: "article-comment-form-" + _vm.entry.id,
                            enabled: _vm.$can("can-add-comment", _vm.entry),
                            identifier:
                              "journal:comments:" + _vm.entry.id + ":create",
                            "mention-url": _vm.mentionUrl
                          },
                          on: { save: _vm.saveComment }
                        },
                        [
                          _c("cmnty-uploader", {
                            ref: "uploader",
                            attrs: {
                              slot: "uploader",
                              id: "attachments",
                              name: "attachments",
                              mapping: "journal_comment_attachment",
                              "translation-domain": "uploader_frontend"
                            },
                            slot: "uploader",
                            model: {
                              value: _vm.commentAttachments,
                              callback: function($$v) {
                                _vm.commentAttachments = $$v
                              },
                              expression: "commentAttachments"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "comments-form__button btn btn--text btn--sm",
                              attrs: { slot: "button" },
                              on: { click: _vm.hideComment },
                              slot: "button"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm._f("trans")("action.cancel")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.entry.comments, function(comment, index) {
                return _c("journal-comments", {
                  key: index,
                  attrs: {
                    comment: comment,
                    "entry-id": _vm.entry.id,
                    "objective-id": _vm.entry.objective_id,
                    "dashboard-mode": _vm.dashboardMode
                  },
                  on: {
                    "add-to-notes": function($event) {
                      return _vm.$emit("add-to-notes", $event)
                    },
                    "add-to-backroom": function($event) {
                      return _vm.$emit("add-to-backroom", $event)
                    }
                  }
                })
              })
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }