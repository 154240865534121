const widgets = {
    'search-widget': () => import(/* webpackChunkName: 'search-widget' */ './SearchWidget.vue'),
    'whos-online-widget': () => import(/* webpackChunkName: 'whos-online-widget' */ './WhosOnlineWidget/WhosOnlineWidget.vue'),
    'widget-challenge-best-ideas': () => import(/* webpackChunkName: 'best-ideas-widget' */ '../Module/Challenge/Widget/BestIdeasWidget.vue'),
    'widget-challenge-show-challenges': () => import(/* webpackChunkName: 'challenges-widget' */ '../Module/Challenge/Widget/ChallengesWidget.vue'),
    'widget-challenge-top-participants': () => import(/* webpackChunkName: 'top-challenge-participants-widget' */ '../Module/Challenge/Widget/TopChallengeParticipantsWidget.vue'),
    'widget-challenge-latest-ideas': () => import(/* webpackChunkName: 'latest-ideas-widget' */ '../Module/Challenge/Widget/LatestIdeasWidget.vue'),
    'widget-challenge-related-ideas': () => import(/* webpackChunkName: 'related-ideas-widget' */ '../Module/Challenge/Widget/RelatedIdeasWidget.vue'),
    'widget-challenge-show-ideas': () => import(/* webpackChunkName: 'ideas-widget' */ '../Module/Challenge/Widget/IdeasWidget.vue'),
    'recent-articles-widget': () => import(/* webpackChunkName: 'recent-articles-widget' */ '../Module/Blog/Widget/RecentArticlesWidget.vue'),
    'widget-blog-categories': () => import(/* webpackChunkName: 'blog-categories-widget' */ '../Module/Blog/Widget/BlogCategoriesWidget.vue'),
    'wordcloud-widget': () => import(/* webpackChunkName: 'word-cloud-widget' */ '../Module/Challenge/Widget/WordCloudWidget.vue'),
    'carousel-widget': () => import(/* webpackChunkName: 'carousel-widget' */ './Carousel/CarouselWidget.vue'),
    'my-journal-widget': () => import(/* webpackChunkName: 'my-journal-widget' */ '../Module/Journal/Widget/MyJournalWidget.vue'),
    'task-list-widget': () => import(/* webpackChunkName: 'task-list-widget' */ './TaskList/TaskListWidget.vue'),
    'not-allowed-widget': () => import(/* webpackChunkName: 'not-allowed-widget' */ './NotAllowedWidget.vue'),
    'widget-discussion-board-board-overview': () => import(/* webpackChunkName: 'board-overview-widget' */ '../Module/Forum/Widget/BoardOverview/BoardOverviewWidget.vue'),
    'widget-discussion-board-board-statistics': () => import(/* webpackChunkName: 'board-statistics-widget' */ '../Module/Forum/Widget/BoardStatistics/BoardStatisticsWidget.vue'),
    'webshop-category-widget': () => import(/* webpackChunkName: 'webshop-category-widget' */ '../Module/Webshop/widget/WebshopCategoryWidget.vue'),
    'webshop-cart-widget': () => import(/* webpackChunkName: 'webshop-category-widget' */ '../Module/Webshop/widget/WebshopCartWidget.vue'),
};

export default widgets;
