var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "visuals-carousel",
      class: _vm.enableFullWidth ? "full-width" : ""
    },
    [
      _c(
        "ul",
        {
          ref: "list",
          staticClass: "visuals-carousel__list bi-gallery",
          staticStyle: { display: "none" },
          attrs: {
            id: "bi-gallery-" + _vm.suffix,
            "data-unique-id": _vm.suffix
          }
        },
        _vm._l(_vm.visuals, function(visual, index) {
          return _c("li", { key: index }, [
            visual.type === "image"
              ? _c(
                  "a",
                  {
                    attrs: { href: visual.original_url, type: visual.mime_type }
                  },
                  [
                    _c("figure", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.thumbnailKey) +
                          "\n                    "
                      ),
                      _c("img", {
                        attrs: {
                          src: visual.thumbnails[_vm.thumbnailKey],
                          srcset:
                            visual.thumbnails[_vm.thumbnailKey] +
                            " 250w,\n                            " +
                            visual.thumbnails[_vm.thumbnail2xKey] +
                            " 500w",
                          alt: visual.original_name,
                          "data-href": visual.thumbnails[_vm.thumbnailKey]
                        }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            visual.type === "video" && visual.video_status === "complete"
              ? _c("a", {
                  attrs: {
                    "data-item-type": "video",
                    href: visual.original_url,
                    type: visual.mime_type,
                    "data-poster": visual.thumbnails["400x300"],
                    "data-sources": JSON.stringify(visual.video_sources || []),
                    title: visual.original_name
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            visual.type === "video" && visual.video_status !== "complete"
              ? _c("a", { attrs: { href: visual.thumbnails["400x300"] } }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        "data-href": visual.thumbnails["400x300"],
                        src: visual.thumbnails["400x300"]
                      }
                    })
                  ])
                ])
              : _vm._e()
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "visuals-carousel__carousel",
          attrs: { id: "carousel-" + _vm.suffix }
        },
        [
          _c(
            "div",
            { staticClass: "visuals-carousel__wrapper" },
            [
              _c("div", {
                staticClass: "visuals-carousel__slides",
                attrs: { "data-target": "#bi-gallery-" + _vm.suffix },
                on: { click: _vm.clickCarousel }
              }),
              _vm._v(" "),
              _vm.visuals.length > 1
                ? [
                    _c("i", {
                      staticClass:
                        "visuals-carousel__nav visuals-carousel__nav--left far fa-angle-left",
                      on: { click: _vm.prevImage }
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass:
                        "visuals-carousel__nav visuals-carousel__nav--right far fa-angle-right",
                      on: { click: _vm.nextImage }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("ol", {
            ref: "bulletList",
            staticClass: "visuals-carousel__bullet-list"
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }