var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeNavigations,
          expression: "closeNavigations"
        }
      ],
      staticClass: "mobile-navigation",
      class: {
        "mobile-navigation--hidden":
          _vm.hideMenu && !_vm.expanded && !_vm.expandedUserNavigation
      }
    },
    [
      _c(
        "div",
        { staticClass: "mobile-navigation__inner" },
        [
          _c(
            "a",
            {
              staticClass: "mobile-navigation__toggle",
              class: { "mobile-navigation__toggle--expanded": _vm.expanded },
              attrs: { tabindex: "0" },
              on: { click: _vm.toggleNavigation }
            },
            [
              _c("i", {
                staticClass: "mobile-navigation__mobile-toggle-icon fas fa-bars"
              })
            ]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide-right-in" } }, [
            _c(
              "nav",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expanded,
                    expression: "expanded"
                  }
                ],
                staticClass: "mobile-navigation__main-navigation"
              },
              [
                _c(
                  "ul",
                  { staticClass: "mobile-navigation__main-list" },
                  _vm._l(_vm.items, function(item, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class:
                          "mobile-navigation__main-item mobile-navigation__main-item--" +
                          key
                      },
                      [
                        [
                          "challenge",
                          "blog",
                          "focus",
                          "journal",
                          "discussion-board"
                        ].includes(item.key)
                          ? _c(
                              "router-link",
                              {
                                staticClass: "mobile-navigation__main-link",
                                attrs: {
                                  to: item.url,
                                  tag: "a",
                                  "active-class":
                                    "mobile-navigation__main-link--active"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "mobile-navigation__main-label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.label) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "mobile-navigation__main-link",
                                class: {
                                  "mobile-navigation__main-link--active":
                                    item.active
                                },
                                attrs: { href: item.url }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "mobile-navigation__main-label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.label) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              ]
                            )
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "nav-logo" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mobile-navigation__right" },
            [
              _vm.$can("switch-locale", _vm.user)
                ? _c("language-switch", {
                    staticClass: "mobile-navigation__language-switch",
                    attrs: { variant: "compact", direction: "right" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("access-pulse", _vm.user) && _vm.isObserver
                ? _c("div", { staticClass: "mobile-navigation__pulse" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.bottom",
                            modifiers: { bottom: true }
                          }
                        ],
                        staticClass: "mobile-navigation__pulse-link",
                        attrs: { href: "/pulse", title: "Pulse" }
                      },
                      [
                        _c("i", {
                          staticClass:
                            "mobile-navigation__pulse-icon fas fa-wave-sine"
                        })
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("access-admin", _vm.user)
                ? _c("div", { staticClass: "mobile-navigation__admin" }, [
                    _vm._m(0)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$can("navigate-platform")
                ? _c("platform-navigation")
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mobile-navigation__user-navigation-toggle",
                  class: {
                    "mobile-navigation__user-navigation-toggle--expanded":
                      _vm.expandedUserNavigation
                  },
                  attrs: { tabindex: "0" },
                  on: { click: _vm.toggleUserNavigation }
                },
                [
                  _c(
                    "figure",
                    { staticClass: "mobile-navigation__avatar-figure" },
                    [
                      _vm.user && _vm.user.avatar
                        ? _c("img", {
                            staticClass: "mobile-navigation__avatar",
                            attrs: {
                              src:
                                _vm.user.avatar.thumbnail_urls[
                                  "150x150_cropped"
                                ],
                              alt: _vm.user.display_name + "'s Avatar"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "mobile-navigation__notifications-indicator"
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "slide-left-in" } }, [
                _c(
                  "nav",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.expandedUserNavigation,
                        expression: "expandedUserNavigation"
                      }
                    ],
                    staticClass: "mobile-navigation__user-navigation"
                  },
                  [
                    _c(
                      "ul",
                      {
                        staticClass: "mobile-navigation__user-navigation-list"
                      },
                      [
                        _vm.user.is_logged_in
                          ? _vm._l(_vm.userItems, function(item) {
                              return _c(
                                "li",
                                {
                                  key: item.key,
                                  class:
                                    "mobile-navigation__user-navigation-item mobile-navigation__user-navigation-item--" +
                                    item.key
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "mobile-navigation__user-navigation-link",
                                      attrs: { href: item.url }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mobile-navigation__user-navigation-label"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.label) +
                                              "\n                                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      item.key === "notification-center" &&
                                      _vm.user.stats &&
                                      _vm.user.stats.notifications_count > 0
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "mobile-navigation__user-navigation-indicator mobile-navigation__user-navigation-indicator--notifications"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.user.stats
                                                        .notifications_count
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.key === "private-messages" &&
                                      _vm.user.stats &&
                                      _vm.user.stats.private_messages_count > 0
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "mobile-navigation__user-navigation-indicator mobile-navigation__user-navigation-indicator--private-messages"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.user.stats
                                                        .private_messages_count
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              )
                            })
                          : [
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "mobile-navigation__user-navigation-item mobile-navigation__user-navigation-item--login"
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "mobile-navigation__user-navigation-link",
                                      attrs: { href: _vm.$path("login") }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mobile-navigation__user-navigation-label"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm._f("trans")("user.login")
                                              ) +
                                              "\n                                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "mobile-navigation__user-navigation-item mobile-navigation__user-navigation-item--register"
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "mobile-navigation__user-navigation-link",
                                      attrs: { href: _vm.$path("register") }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mobile-navigation__user-navigation-label"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm._f("trans")("user.register")
                                              ) +
                                              "\n                                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("sidebar-expander", {
                staticClass: "mobile-navigation__sidebar-toggle"
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "mobile-navigation__admin-link",
        attrs: { href: "/admin3" }
      },
      [_c("i", { staticClass: "mobile-navigation__admin-icon fas fa-key" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }