<template>
    <div class="journal-main-wrapper">
        <div v-if="!showEntryForm" class="my-journal-wrapper">
            <nice-date :date="$moment(entry.entry_date).valueOf()" />
            <div class="my-journal-content">
                <h1 class="date-title">
                    {{ $moment(entry.entry_date).format("dddd DD MMMM YYYY") }}
                </h1>
                <section class="journal-content">
                    <div class="journal-message">
                        <div v-html="entry.html" />

                        <div v-if="visuals && visuals.length > 0 && $store.getters['configuration/getSetting']('screenWidth') >= 900" class="main-item__visuals">
                            <visuals-carousel :visuals="visuals" thumbnail-key="300x300" thumbnail2x-key="500x500" />
                        </div>
                    </div>
                    <div v-if="visuals && visuals.length > 0 && $store.getters['configuration/getSetting']('screenWidth') < 900" class="main-item__visuals-mobile">
                        <visuals-carousel :visuals="visuals" thumbnail-key="300x300" thumbnail2x-key="500x500" />
                    </div>

                    <actions-list class="main-item__actions-list">
                        <journal-liking
                            slot="first"
                            :journal-id="entry.id"
                            :like-total="entry.likes.total"
                            :has-liked="entry.likes.has_liked"
                            :can-like="$can('can-like-journal', entry)"
                        />
                        <edit-action
                            v-if="$can('can-edit-journal', entry)"
                            slot="first"
                            class="comment__action--edit"
                            @edit="showEntryForm = true"
                        />

                        <delete-action
                            v-if="$can('can-delete-journal', entry)"
                            slot="hidden"
                            class="comment__action--delete"
                            @delete="deleteAction(entry.id)"
                        >
                            <template slot="title">
                                {{ 'journal.journal_entry.action.delete_journal'|trans({}, 'journal_frontend') }}
                            </template>
                            <template slot="content">
                                {{ 'journal.journal_entry.action.confirm_delete_message'|trans({}, 'journal_frontend') }}
                            </template>
                            <template slot="confirm-label">
                                {{ 'journal.journal_entry.action.delete'|trans({}, 'journal_frontend') }}
                            </template>
                        </delete-action>

                        <report-action
                            slot="hidden"
                            :reported="entry.reported"
                            @report="report"
                        />
                        <bookmark-action
                            v-if="$can('can-bookmark-journal', entry)"
                            slot="hidden"
                            :bookmarked="entry.is_bookmarked"
                            @bookmark="bookmark"
                            @unbookmark="unbookmark"
                        />
                        <subscribe-action
                            v-if="$can('can-subscribe-journal', entry)"
                            slot="hidden"
                            :subscribed="entry.subscribed"
                            @subscribe="subscribe"
                            @unsubscribe="unsubscribe"
                        />
                        <permalink-action
                            slot="hidden"
                            class="comment__action--permalink"
                            @copy-permalink="copyPermalink"
                        />
                        <reply-action
                            v-if="$can('can-add-comment', entry)"
                            slot="last"
                            v-scroll-to="{ el: '#article-comment-form-'+entry.id, offset: -100, onDone: focusCommentField(entry) }"
                            @reply="addCommentEnabled = true"
                        />
                    </actions-list>
                </section>
                <section class="article-comments comments-section">
                    <div class="section-heading">
                        <h3
                            class="article__comments-section-title section-heading__title section-title"
                        >
                            {{ 'journal.journal_entry.comments.comment_heading'|trans({}, 'journal_frontend') }}
                            <span class="section-title__count">
                                {{ totalComments }}
                            </span>
                        </h3>
                    </div>
                    <div class="comments-section__wrapper">
                        <div
                            v-show="$can('can-add-comment', entry) && addCommentEnabled"
                            class="article__comments-form-container comments-section__form-container"
                            data-cy="comment-form-container"
                        >
                            <h4
                                class="article__comments-form-title comments-section__form-title"
                            >
                                {{ 'journal.journal_entry.comments.leave_a_comment'|trans({}, 'journal_frontend') }}
                            </h4>
                            <comment-form
                                :id="'article-comment-form-'+entry.id"
                                ref="commentForm"
                                :enabled="$can('can-add-comment', entry)"
                                :mention-url="mentionUrl"
                                :identifier="`journal:entry:${entry.id}:create`"
                                class="article__comments-form comments-section__form"
                                @save="saveComment"
                                @cancel="hideComment"
                            >
                                <cmnty-uploader
                                    id="attachments"
                                    slot="uploader"
                                    ref="uploader"
                                    v-model="commentAttachments"
                                    name="attachments"
                                    mapping="discussion_board_comment_attachment"
                                    translation-domain="uploader_frontend"
                                />
                                <button slot="button" class="comments-form__button btn btn--text btn--sm" @click="hideComment">
                                    {{ 'action.cancel'|trans }}
                                </button>
                            </comment-form>
                        </div>
                        <journal-comments
                            v-for="(comment, index) in entry.comments"
                            :key="index"
                            :comment="comment"
                            :entry-id="entry.id"
                            :objective-id="entry.objective_id"
                        />
                    </div>
                </section>
            </div>
        </div>
        <div v-else class="edit-entry">
            <create-new-entry :initial-value="entry" @cancel="cancelEdit" />
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import NiceDate from '../Components/NiceDate.vue';
    import CreateNewEntry from './CreateNewEntry.vue';
    import JournalComments from './JournalComments.vue';
    import JournalLiking from './JournalLiking.vue';
    import AccessDeniedError from '../../../../Core/Http/AccessDeniedError';
    import CmntyUploader from '../../../../Core/Attachments/Uploader/CmntyUploader.vue';

    const CommentForm = () => import(
        /* webpackChunkName: 'comment-form' */ '../../../../Core/Comments/CommentForm.vue'
    );

    export default {
        components: {
            NiceDate,
            JournalLiking,
            JournalComments,
            CommentForm,
            CreateNewEntry,
            CmntyUploader,
        },
        props: {
            entry: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                showEntryForm: false,
                addCommentEnabled: false,
                commentAttachments: [],
            };
        },
        computed: {
            visuals() {
                return this.entry?.attachments?.attachments ?? [];
            },
            totalComments() {
                return this.entry.comments.length;
            },
            mentionUrl() {
                return '';
            },
        },
        watch: {
            entry(newValue) {
                this.entries = newValue;
            },
        },
        methods: {
            ...mapActions(
                'journal',
                [
                    'addComment',
                    'deleteEntry',
                    'bookmarkEntries',
                    'unbookmarkEntries',
                    'subscribeEntries',
                    'unsubscribeEntries',
                ],
            ),
            async bookmark(category) {
                const payload = {
                    category,
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.bookmarkEntries({ entryId: this.entry.id, payload });
                this.$toaster.success('Successfully added bookmark', true);
            },
            async unbookmark() {
                const payload = {
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.unbookmarkEntries({ entryId: this.entry.id, payload });
                this.$toaster.success('Successfully removed bookmark', true);
            },
            async subscribe() {
                const payload = {
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.subscribeEntries({ entryId: this.entry.id, payload });
                this.$toaster.success('Successfully subscribed', true);
            },
            async unsubscribe() {
                const payload = {
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.unsubscribeEntries({ entryId: this.entry.id, payload });
                this.$toaster.success('Successfully unsubscribed', true);
            },
            deleteAction(entryId) {
                const payload = {
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                this.deleteEntry({ entryId, payload });
                this.$toaster.success('Successfully deleted journal', true);
            },
            cancelEdit() {
                this.showEntryForm = false;
            },
            saveComment(text) {
                const payload = {
                    text,
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                this.addComment({ entryId: this.entry.id, payload, author: this.$store.getters['user/getUser'] });
                this.addCommentEnabled = false;
            },
            hideComment() {
                this.addCommentEnabled = false;
            },
            focusCommentField(entry) {
                setTimeout(() => {
                    if (document.querySelector(`#article-comment-form-${entry.id}`)) {
                        document.querySelector(`#article-comment-form-${entry.id}`).querySelector('textarea').focus();
                    }
                }, 200);
            },
            async report(reason) {
                try {
                    await this.$store.dispatch('journal/reportEntry', { entryId: this.entry.id, reason });

                    this.$toaster.success(this.$trans('reporting.report.event'), true);
                } catch (error) {
                    if (error instanceof AccessDeniedError) {
                        throw error;
                    }

                    this.$toaster.error(this.$trans('reporting.report.error'), true);
                }
            },
            async copyPermalink() {
                // const permalink = this.$url('cmnty_task_list_journal_objectives_view', {
                //     id: this.objectiveId,
                //     entry_id: this.entryId,
                //     comment_id: this.comment.id,
                // });

                const permalink = `${document.location.origin}/journal/show/${this.entry.objective_id}?entry_id=${this.entry.id}`;
                await this.$copyText(permalink);

                this.$toaster.success(this.$trans('comments.permalink_copied', {}, 'frontend'), true);
            },
        },
    };
</script>

<style scoped lang="scss">
.my-journal-wrapper {
    display: flex;
    .my-journal-content {
        width: 100%;
        margin-left: 10px;
        .journal-content{
            margin-bottom: 12px;
            word-break: break-word;
            white-space: normal;
        }
    }
    .date-title {
        font-size: 2em;
    }
    .journal-message {
        display: flex;
        justify-content: space-between;
        margin: 20px 0px;
        .main-item__visuals {
            margin-right: 0;
        }
    }
    .main-item__actions-list{
        margin: 20px 0px;
    }
    @media screen and (max-width: 1160px) {
        .nice-date {
            display: none;
        }
    }
}
</style>
