var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "transition-modal", duration: 400 } },
    [
      _vm.show
        ? _c("div", { ref: "modalComponent", staticClass: "modal" }, [
            _c("div", {
              staticClass: "modal__backdrop modal-backdrop",
              on: { click: _vm.close }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "modal__wrapper modal-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "modal__dialog modal-dialog",
                  attrs: { role: "document" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal__content modal-content",
                      attrs: { "data-cy": "modal-content" }
                    },
                    [
                      _c("div", { staticClass: "modal__header modal-header" }, [
                        _c(
                          "h5",
                          { staticClass: "modal__title modal-title" },
                          [_vm._t("title")],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "modal__body modal-body" },
                        [_vm._t("content")],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "modal__footer modal-footer" },
                        [_vm._t("default")],
                        2
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }