<template>
    <component :is="menuView" :items="items" :user-items="userItems" />
</template>

<script>
    const AstraNavigation = () => import(/* webpackChunkName: 'astra-navigation' */ './Types/AstraNavigation.vue');
    const ExploreNavigation = () => import(/* webpackChunkName: 'explore-navigation' */ './Types/ExploreNavigation.vue');

    export default {
        name: 'MainNavigation',
        components: {
            AstraNavigation,
            ExploreNavigation,
        },
        props: {
            items: {
                type: Array,
                required: true,
            },
            userItems: {
                type: Array,
                required: true,
            },
            type: {
                type: String,
                default: 'astra',
            },
        },
        computed: {
            menuView() {
                return `${this.type}-navigation`;
            },
        },
    };
</script>
