var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "actions-list" },
    [
      _vm._t("first"),
      _vm._v(" "),
      _vm._t("second"),
      _vm._v(" "),
      !_vm.showHiddenActions &&
      _vm.$slots.hidden &&
      _vm.$slots.hidden.length > 0
        ? _c(
            "span",
            {
              staticClass:
                "actions-list__action actions-list__action--expander btn btn--text",
              attrs: {
                tabindex: "0",
                "data-cy": "actions-list-expander",
                "data-jest": "show-hidden",
                "aria-label": "action expander button",
                role: "link"
              },
              on: {
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  _vm.showHiddenActions = true
                },
                click: function($event) {
                  _vm.showHiddenActions = true
                }
              }
            },
            [
              _c("i", {
                staticClass: "actions-list__action-icon fas fa-ellipsis-h"
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showHiddenActions ? _vm._t("hidden") : _vm._e(),
      _vm._v(" "),
      _vm._t("last")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }