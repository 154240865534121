<template>
    <section id="journal-entry-form" class="journal-new-entry flex">
        <nice-date />
        <div class="editor-wrapper">
            <markdown-editor
                v-model="message"
                class="new-entry__markdown-editor"
                placeholder=""
                textarea-name="message"
            />
            <div class="attachment-wrapper">
                <div>{{ 'journal.add_attachment'|trans({}, 'journal_frontend') }}</div>
                <cmnty-uploader
                    id="attachments"
                    ref="uploader"
                    v-model="attachments"
                    :initial-files="initialValue ? initialValue.attachments.attachments : []"
                    name="attachments"
                    identifier="journal-entry-new"
                    mapping="journal_entry_attachment"
                    translation-domain="uploader_frontend"
                />
            </div>
            <date-picker
                v-model="entryDate"
                :initial-date="initialDate"
                class="discussion-form__date"
            />
            <div class="flex justify-end form_buttons">
                <button v-if="isEdit" class="form__button btn btn--text" type="button" @click="cancelEdit">
                    {{ 'journal.journal_entry.action.cancel'|trans({}, 'journal_frontend') }}
                </button>
                <button
                    class="form__button btn btn--secondary add-journal-btn"
                    type="button"
                    name=""
                    :disabled="!isFormValid"
                    @click="addJournal"
                >
                    <i class="fa-regular fa-book mr-xs" />
                    {{ 'journal.add_journal'|trans({}, 'journal_frontend') }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapActions } from 'vuex';
    import moment from 'moment-timezone';

    import CmntyUploader from '../../../../Core/Attachments/Uploader/CmntyUploader.vue';
    import NiceDate from '../Components/NiceDate.vue';
    import DatePicker from '../../../../Core/Forms/DatePicker.vue';

    const MarkdownEditor = () => import(/* webpackChunkName: 'markdown-editor' */ '../../../../Core/Markdown/MarkdownEditor.vue');

    export default {
        components: {
            MarkdownEditor,
            CmntyUploader,
            NiceDate,
            DatePicker,
        },
        props: {
            initialValue: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                message: '',
                attachments: [],
                entryDate: null,
                isFormValid: false,
            };
        },
        computed: {
            mentioning() {
                return false;
            },
            isEdit() {
                return this.initialValue && Object.keys(this.initialValue).length;
            },
            initialDate() {
                return this.initialValue && this.initialValue.entry_date ? this.initialValue.entry_date : null;
            },
            timezone() {
                return this.$store.getters['user/getUser'].timezone;
            },
        },
        watch: {
            message(newValue) {
                if (newValue.length) {
                    this.isFormValid = true;
                }
            },
        },
        mounted() {
            const timezoneString = moment().format('YYYY-MM-DD HH:mm:ss');
            this.entryDate = timezoneString;
        },
        created() {
            this.message = this.initialValue?.message ?? '';
        },
        methods: {
            ...mapActions('journal', ['createNewEntry', 'updateEntry']),
            async addJournal() {
                const { id } = this.$route.params;
                const payload = {
                    message: this.message,
                    entry_date: this.getTimeZoneString(this.entryDate),
                    attachments: this.attachments,
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                if (this.isEdit) {
                    await this.updateEntry({ id: this.initialValue.id, payload });
                    this.cancelEdit('cancel');
                    this.$toaster.success(
                        this.$trans('journal.changed', {}, 'journal_frontend'),
                        true,
                    );
                    return;
                }

                await this.createNewEntry({ assignmentId: id, payload, author: this.$store.getters['user/getUser'] });
                this.$toaster.success(
                    this.$trans('journal.added', {}, 'journal_frontend'),
                    true,
                );
                await this.$store.dispatch('taskList/fetchTasks');
                this.resetForm();
            },
            validateForm() {
                if (this.message.length === 0) {
                    this.isFormValid = false;
                } else {
                    this.isFormValid = true;
                }
            },
            cancelEdit() {
                this.$emit('cancel');
                this.resetForm();
            },
            resetForm() {
                const currentDate = new Date();
                const formattedDate = this.getTimeZoneString(currentDate);
                this.message = '';
                this.attachments = [];
                this.entryDate = formattedDate;
                this.$refs.uploader.resetFiles();
            },
            getTimeZoneString(dateString) {
                const timezoneString = moment(dateString).format();
                return timezoneString;
            },
        },

    };
</script>

<style scoped>
    .journal-new-entry {
        background: #fff;
        padding-top: 20px;
        border-top: 1px solid var(--color-primary);
        margin-bottom: 20px;
    }
    .new-entry__markdown-editor {
        min-height: 150px;
    }

    .attachment-wrapper {
        margin: 10px 0px;
    }
    .editor-wrapper {
        width: 100%;
        margin-left: 10px;
    }
    .add-journal-btn {
        margin: 10px 0px;
        background-color: var(--color-button-primary);
    }
    @media screen and (max-width: 1160px) {
        .nice-date {
            display: none;
        }
    }
</style>
