var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showIframe
      ? _c("div", { domProps: { innerHTML: _vm._s(_vm.iframe) } })
      : _c("div", { staticClass: "iframe-consent" }, [
          _c("div", { staticClass: "iframe-consent__container" }, [
            _c("p", { staticClass: "iframe-consent__description" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("trans")("cookie_consent.iframe_overlay.message", {
                      "%content%": _vm.$trans(
                        "cookie_consent.iframe_overlay.type." + _vm.type
                      )
                    })
                  ) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "iframe-consent__load-button btn btn-primary btn--primary",
                on: { click: _vm.load }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("trans")("cookie_consent.iframe_overlay.load", {
                        "%content%": _vm.$trans(
                          "cookie_consent.iframe_overlay.type." + _vm.type
                        )
                      })
                    ) +
                    "\n            "
                )
              ]
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }