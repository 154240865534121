<template>
    <li class="upload-v2__button">
        <input
            :id="uniqueId"
            class="upload-v2__input hidden"
            type="file"
            :multiple="multiple"
            :disabled="disabled"
            @change="addFiles"
        >
        <label :for="uniqueId" class="upload-v2__label" :class="{ 'upload-v2__label--disabled': disabled }" tabindex="0">
            <i class="fas fa-plus-circle" />
            <span v-if="disabled && disabledReason" class="upload-v2__label-msg" :class="{ 'upload-v2__label-msg--disabled': disabled }">
                {{ disabledReason }}
            </span>
        </label>
    </li>
</template>

<script>
    export default {
        name: 'FileInput',
        props: {
            uploader: {
                type: Object,
                required: true,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            disabledReason: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                uniqueId: Math.floor((Math.random() * (99999 - 10000)) + 10000),
            };
        },
        methods: {
            addFiles(event) {
                this.uploader.methods.addFiles(event.target);
            },
        },
    };
</script>
