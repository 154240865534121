import { MANAGERS_WITH_CMNTY, OBSERVER } from '../../../../User/Roles';

export default ({ user, subject }) => {
    if (MANAGERS_WITH_CMNTY.includes(user.role.type)) {
        return true;
    }
    if (user.role.type === OBSERVER && subject.id === user.id) {
        return true;
    }
    return false;
};
