var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "nps-component" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isVisible,
            expression: "isVisible"
          }
        ],
        staticClass: "nps-component"
      },
      [
        _c(
          "transition",
          { attrs: { name: "nps-component-page", mode: "out-in" } },
          [
            !_vm.rated
              ? _c(
                  "div",
                  {
                    key: "step-one",
                    staticClass: "nps-component__step nps-component__step-one"
                  },
                  [
                    _c("p", { staticClass: "nps-component__question" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("trans")(
                              "question",
                              _vm.translationParameters,
                              _vm.translationDomain
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("nps-input", {
                      attrs: { rated: _vm.rated, rating: _vm.rating },
                      on: { rate: _vm.setRating }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "nps-component__rating-labels" }, [
                      _c(
                        "span",
                        { staticClass: "nps-component__rating-label-left" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "not_likely",
                                  _vm.translationParameters,
                                  _vm.translationDomain
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "nps-component__rating-label-right" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "extremely_likely",
                                  _vm.translationParameters,
                                  _vm.translationDomain
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("footer", { staticClass: "nps-component__footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "nps-component__button btn btn--primary",
                          attrs: { disabled: _vm.rated || _vm.rating === null },
                          on: { click: _vm.submitRating }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "submit",
                                  _vm.translationParameters,
                                  _vm.translationDomain
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "nps-component__button btn btn--text",
                          on: { click: _vm.notNow }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "not_now",
                                  _vm.translationParameters,
                                  _vm.translationDomain
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              : _vm.rated && !_vm.submittedFeedback
              ? _c(
                  "div",
                  {
                    key: "step-two",
                    staticClass: "nps-component__step nps-component__step-two"
                  },
                  [
                    _c("p", { staticClass: "nps-component__question" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("trans")(
                              _vm.npsType + "_feedback",
                              _vm.translationParameters,
                              _vm.translationDomain
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.feedback,
                          expression: "feedback"
                        }
                      ],
                      staticClass: "nps-component__textarea",
                      attrs: { rows: "3", title: "Feedback" },
                      domProps: { value: _vm.feedback },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.feedback = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("footer", { staticClass: "nps-component__footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "nps-component__button btn btn--primary",
                          attrs: {
                            disabled:
                              _vm.submittedFeedback || _vm.feedback === ""
                          },
                          on: { click: _vm.submitFeedback }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "submit",
                                  _vm.translationParameters,
                                  _vm.translationDomain
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "nps-component__button btn btn--text",
                          on: { click: _vm.skipFeedback }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "skip",
                                  _vm.translationParameters,
                                  _vm.translationDomain
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                )
              : _c(
                  "div",
                  {
                    key: "step-three",
                    staticClass: "nps-component__step nps-component__step-two"
                  },
                  [
                    _c("p", { staticClass: "nps-component__thank-you" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("trans")(
                              "thank_you",
                              _vm.translationParameters,
                              _vm.translationDomain
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "nps-component__thank-you-description" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("trans")(
                                _vm.npsType + "_thank_you",
                                _vm.translationParameters,
                                _vm.translationDomain
                              )
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("footer", { staticClass: "nps-component__footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "nps-component__button btn btn--primary",
                          on: { click: _vm.closeWindow }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "close",
                                  _vm.translationParameters,
                                  _vm.translationDomain
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }