export default class AuthorizationChecker {
    /** {VoterRegistry} */
    voterRegistry;

    /** {Store} */
    store;

    constructor(voterRegistry, store) {
        this.voterRegistry = voterRegistry;
        this.store = store;
    }

    isGranted(action, subject) {
        try {
            const voter = this.voterRegistry.getVoter(action);
            const user = this.store.getters['user/getUser'];

            return voter({ user, subject });
        } catch (error) {
            return false;
        }
    }
}
