var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "promote-solution" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c("promote-solution-modal", {
            attrs: {
              slides: _vm.modalSlides,
              show: _vm.modalIsVisible,
              "solution-name": _vm.solutionName
            },
            on: {
              close: function($event) {
                _vm.modalIsVisible = false
              }
            }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }