var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-filter" },
    [
      _c(
        "b-dropdown",
        {
          staticClass: "custom-filter__dropdown-container",
          attrs: {
            variant: "link",
            "no-caret": "",
            right: "",
            "toggle-class": "btn--sm btn--soft"
          }
        },
        [
          _c(
            "template",
            { slot: "button-content" },
            [
              _vm.showIcon
                ? _c("i", {
                    staticClass:
                      "custom-filter__dropdown-filter-icon fas fa-filter"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeFilter.id === 0
                ? [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("trans")("filter.all", {}, "frontend")) +
                        "\n            "
                    )
                  ]
                : [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.activeFilter.name) +
                        "\n            "
                    )
                  ],
              _vm._v(" "),
              _c("i", {
                staticClass:
                  "custom-filter__dropdown-caret-icon fas fa-caret-down"
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm._l(_vm.filters, function(filter) {
            return _c(
              "b-dropdown-item",
              {
                key: filter.id,
                attrs: { "data-jest": "filter" },
                on: {
                  click: function($event) {
                    return _vm.changeFilter(filter)
                  }
                }
              },
              [
                filter.id === 0
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("trans")("filter.all", {}, "frontend")
                          ) +
                          "\n            "
                      )
                    ]
                  : [
                      _vm._v(
                        "\n                " +
                          _vm._s(filter.name) +
                          "\n            "
                      )
                    ]
              ],
              2
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }