var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "actions-list__action btn--text",
      class: _vm.textMode ? "" : "btn",
      attrs: { "data-cy": "actions-list-reply" },
      on: {
        click: function($event) {
          return _vm.$emit("addToBackroom")
        }
      }
    },
    [
      !_vm.textMode
        ? _c("i", {
            staticClass: "actions-list__action-icon fas fa-note-medical"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "actions-list__action-label" }, [
        _vm._v("\n        Add to backroom\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }