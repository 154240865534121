const FocusModule = () => import(/* webpackChunkName: 'focus-module' */ './FocusModule.vue');
const MeetingOverview = () => import(/* webpackChunkName: 'focus-module' */ './MeetingOverview/MeetingOverview.vue');
const MeetingView = () => import(/* webpackChunkName: 'focus-module' */ './MeetingView/MeetingView.vue');
const CheckView = () => import(/* webpackChunkName: 'focus-module' */ './CheckView/CheckView.vue');
const AvailabilityView = () => import(/* webpackChunkName: 'focus-module' */ './AvailabilityView/AvailabilityView.vue');
const RecorderView = () => import(/* webpackChunkName: 'focus-recorder-view' */ './RecorderView/RecorderView.vue');
const InviteLinkView = () => import(/* webpackChunkName: 'focus-module' */ './InviteLinkView/InviteLinkView.vue');

export default {
    path: '/focus',
    component: FocusModule,
    meta: { bodyClass: 'module-focus' },
    children: [
        {
            path: '',
            name: 'MeetingOverview',
            component: MeetingOverview,
            meta: { bodyClass: 'module-focus__meeting-overview' },
        },
        {
            path: 'check',
            name: 'CheckView',
            component: CheckView,
            meta: {
                bodyClass: 'module-focus__check-view',
                tasklist: {
                    module: null,
                    actions: [
                        'focus_pass_check',
                    ],
                },
            },
        },
        {
            path: 'availability',
            name: 'AvailabilityView',
            component: AvailabilityView,
            meta: {
                bodyClass: 'module-focus__availability-view',
                tasklist: {
                    module: null,
                    actions: [
                        'focus_schedule',
                    ],
                },
            },
        },
        {
            path: ':id',
            name: 'MeetingView',
            component: MeetingView,
            meta: {
                bodyClass: 'module-focus__meeting-view',
                tasklist: {
                    module: 'focus_meeting',
                    itemIdParam: 'id',
                    actions: [
                        'focus_meeting',
                    ],
                },
            },
            props: true,
        },
        {
            path: 'invite-link/:token/:role',
            name: 'InviteLink',
            component: InviteLinkView,
            meta: {
                bodyClass: 'module-focus__invite-link-view',
            },
        },
    ],
};

export const RecorderRoutes = {
    path: '/_focus-recorder/:recorderMeetingId',
    component: RecorderView,
    meta: { bodyClass: 'module-focus__recorder-view' },
    props: true,
};
