<template>
    <main class="blog-module__main-item main-item" :class="{'overlay': filterSidebarExpanded}">
        <div v-if="$can('access-admin')" class="setting-dropdown">
            <b-dropdown
                variant="link"
                no-caret
                class="documents-action__dropdown"
                toggle-class="documents-action__toggle btn btn--text btn--action"
                data-cy="actions-list-documents"
                ref="actionDropdown"
            >
                <template slot="button-content">
                    <i class="action-icon fa-solid fa-wrench" />
                </template>
                <b-dropdown-item v-for="(setting, index) in settingsMenu" :key="index" :href="setting.link" data-jest="document">
                    <span v-html="setting.icon" />
                    <span class="documents-action__dropdown-label" data-jest="filename">
                        {{ setting.label }}
                    </span>
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <h1 class="blog-module__title main-item__title heading-one" data-cy="main-item-title">
            {{ currentAssignment.subject }}
        </h1>
        <breadcrumbs-component :crumbs="breadcrumbs" />
        <journal-alert-message
            v-if="$can('access-admin') && !showUserPreview"
            :content="journalAdminAlertMessage"
            @click="changeToUserView"
        />
        <journal-alert-message
            v-if="$can('access-admin') && showUserPreview"
            :content="journalParticipantsAlertMessage"
            @click="changeToAdminView"
        />
        <assignment-component :current-assignment="currentAssignment" />

        <div v-if="showJournalAdminView && !showUserPreview" class="journal-entries-container flex space-between">
            <section class="journal-posts journal-overview">
                <div> {{ 'journal.submissions'|trans({}, 'journal_frontend') }}</div>
                <section class="journal-top-bar">
                    <div v-click-outside="closeFilterSideBar" class="mobile-navigation" :class="{ 'mobile-navigation--hidden': !filterSidebarExpanded }">
                        <div class="participant-toggle-right" :class="{'expanded': filterSidebarExpanded}">
                            <button
                                type="button"
                                class="form__button btn btn--primary"
                                :class="{ 'mobile-navigation__toggle--expanded': filterSidebarExpanded }"
                                tabindex="0"
                                @click="toggleNavigation"
                            >
                                <i class="mobile-navigation__admin-icon fas fa-search" />
                            </button>
                        </div>
                        <div v-click-outside="filterSidebarExpanded ? closeFilterSideBar : null" class="participant-section-right">
                            <transition name="slide-left-in">
                                <nav v-show="filterSidebarExpanded" class="participant-right-side-bar">
                                    <journal-sidebar
                                        :participants-list="participants.list"
                                        :participants-pagination="participants.pagination"
                                        @fetch-participants="fetchParticipants"
                                    />
                                </nav>
                            </transition>
                        </div>
                    </div>
                </section>
                <smooth-reflow tag="div" class="journal-overview__entries">
                    <template v-if="isEntryLoading">
                        <div class="journal-overview__entries-loader">
                            <loading-spinner />
                        </div>
                    </template>
                    <template v-else>
                        <journal-entries v-for="(entry, index) in entries" :key="index" :entry="entry" />
                    </template>
                </smooth-reflow>
                <pagination-component
                    v-if="entries.length && entriesPagination.total > entriesPagination.limit"
                    class="article-overview__pagination"
                    :current-page="entriesPagination.page"
                    :total-rows="entriesPagination.total"
                    :per-page="entriesPagination.limit"
                    @change="changeEntriesPage"
                />
            </section>
            <section class="journal-sidebar-container">
                <journal-sidebar
                    :participants-list="participants.list"
                    :participants-pagination="participants.pagination"
                    @fetch-participants="fetchParticipants"
                />
            </section>
        </div>
        <journal-entry-view v-else />
    </main>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import JournalEntries from './JournalEntries.vue';
    import JournalSidebar from './JournalSidebar.vue';
    import JournalApi from '../../JournalApi';
    import AssignmentComponent from './AssignmentComponent.vue';
    import JournalEntryView from './JournalEntryView.vue';
    import JournalAlertMessage from '../Components/JournalAlertMessage.vue';
    import GetSelectedCommentMixin from '../../../../Core/Comments/Mixins/GetSelectedCommentMixin';

    export default {
        components: {
            JournalEntries,
            JournalSidebar,
            AssignmentComponent,
            JournalEntryView,
            JournalAlertMessage,
        },
        mixins: [GetSelectedCommentMixin],
        async beforeRouteEnter(to, from, next) {
            next(async (vm) => {
                if (!vm.showJournalAdminView) {
                    vm.$store.commit('configuration/changePage', { page: 'AssignmentView' });
                } else {
                    vm.$store.commit('configuration/changePage', { page: '' });
                }
                vm.$store.commit('activeItems/deactivateItem');
                next();
            });
        },
        async beforeRouteUpdate(to, from, next) {
            this.assignmentId = to.params.id;
            const isAssignmentIdChanged = to.params.id !== from.params.id;

            if (this.showUserPreview && this.$can('access-admin')) {
                this.$store.commit('configuration/changePage', { page: 'AssignmentView' });
            } else {
                this.$store.commit('configuration/changePage', { page: '' });
            }
            if (isAssignmentIdChanged) {
                const assignmentResponse = await JournalApi.fetchSingleAssignment(this.assignmentId);
                this.currentAssignment = assignmentResponse.body;
            }
            this.$store.commit('activeItems/deactivateItem');
            next();
        },
        data() {
            return {
                currentAssignment: {},
                participants: {
                    list: [],
                    pagination: {
                        page: 1,
                        limit: 10,
                        total: 0,
                    },
                },
                assignmentId: this.$route.params.id,
                showUserPreview: false,
                filterSidebarExpanded: false,
                settingsMenu: [
                    {
                        label: 'Overview',
                        link: '/admin3/publish/journal',
                        icon: '<i class="fa-solid fa-square-list mr-xs"></i>',
                    },
                    {
                        label: 'New journal',
                        link: '/admin3/publish/journal/add',
                        icon: '<i class="fa-solid fa-plus mr-xs"></i>',
                    },
                    {
                        label: 'Edit journal',
                        link: `/admin3/publish/journal/${this.$route.params.id}/edit?locale=en`,
                        icon: '<i class="fa-solid fa-pencil mr-xs"></i>',
                    },
                ],

            };
        },
        computed: {
            ...mapGetters({
                entries: 'journal/getEntries',
                entriesPagination: 'journal/getEntriesPagination',
                isEntryLoading: 'journal/isEntryLoading',
            }),
            breadcrumbs() {
                const breadcrumbs = [];

                breadcrumbs.push({
                    title: this.$trans('journal.overview_title', {}, 'journal_frontend'),
                    href: '/journal',
                });

                breadcrumbs.push({ title: this.currentAssignment.subject });

                return breadcrumbs;
            },
            user() {
                return this.$store.getters['user/getUser'];
            },
            showJournalAdminView() {
                return this.$can('access-admin') || this.user.role.type === 'observer';
            },
            journalAdminAlertMessage() {
                const content = this.$trans('journal.comman_note', {}, 'journal_frontend', false);
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, 'text/html');
                return doc.body.innerHTML;
            },
            journalParticipantsAlertMessage() {
                const content = this.$trans('journal.viewing_user_view', {}, 'journal_frontend', false);
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, 'text/html');
                return doc.body.innerHTML;
            },
        },
        watch: {
            showUserPreview: {
                async handler(newValue) {
                    if (newValue) {
                        this.$store.commit('configuration/changePage', { page: 'AssignmentView' });
                    } else {
                        this.$store.commit('configuration/changePage', { page: '' });
                    }
                    const entryId = this.$route.query.entry_id;
                    if (entryId) {
                        const comment = this.getSelectedComment();
                        if (!Number.isNaN(comment)) {
                            this.$store.commit('journal/selectComment', comment);
                        }
                        await this.fetchSingleEntry({ assignmentId: this.assignmentId, entryId });
                    } else {
                        this.fetchEntries({
                            assignmentId: this.assignmentId,
                            isUserView: newValue,
                            page: this.entriesPagination.page,
                        });
                    }

                    await this.fetchParticipants();
                },
                immediate: true,
            },
        },
        async created() {
            const assignmentResponse = await JournalApi.fetchSingleAssignment(this.assignmentId);
            this.currentAssignment = assignmentResponse.body;

            const actionDropdown = this.$refs.actionDropdown.$el;
            const actionDropdownBtn = actionDropdown.querySelector('button');
            actionDropdownBtn.setAttribute('aria-label', 'action dropdown button');
        },
        methods: {
            ...mapActions('journal', ['fetchEntries', 'fetchSingleEntry']),
            async changeToUserView() {
                this.showUserPreview = true;
            },
            async changeToAdminView() {
                this.showUserPreview = false;
            },
            async fetchParticipants(page) {
                const currentPage = page || this.participants.pagination.page;
                const { body } = await JournalApi.fetchParticipants(this.assignmentId, currentPage);
                this.participants.pagination = body.meta;
                this.participants.list = [...body.data];
            },
            async changeEntriesPage(page) {
                const { id } = this.$route.params;
                await this.fetchEntries({ assignmentId: id, isUserView: false, page });
            },
            closeFilterSideBar() {
                this.filterSidebarExpanded = false;
            },
            toggleNavigation() {
                this.filterSidebarExpanded = !this.filterSidebarExpanded;
            },
        },
    };
</script>

<style scoped lang="scss">
    .journal-entries-container {
        width: 100%;
    }
    .journal-posts{
        border-right: 1px solid var(--color-primary);
        padding-right: 35px;
        width: 70%;
    }
    .journal-overview {
        &__entries-loader {
            align-items: center;
            background: $color-grey-lightest;
            border-radius: var(--main-border-radius);
            display: inline-flex;
            grid-column: 1/-1;
            height: 80px;
            justify-content: center;
            width: 100%;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                margin: $gutter-m;
            }
        }
    }
    .journal-posts > h3 {
        border-bottom: 1px solid var(--color-primary);
        color: var(--color-primary);
        font-size: 1.5rem;
        font-weight: normal;
        margin: 0 0 5px;
        text-shadow: 1px 1px 0 #FFFFFF;
        text-transform: uppercase;
    }
    .journal-sidebar-container {
        padding: 10px 20px;
        max-width: 315px;
    }

    .journal-top-bar {
        display: none;
    }

    .setting-dropdown {
        position: absolute;
        top: 0;
        right: 0;
    }
    .action-icon {
        color: #333;
    }

    @media screen and (max-width: 1160px) {
        .journal-posts {
            width: 100%;
            border-right: none;
            padding-right: 0px;
        }
        .journal-sidebar-container {
            display: none;
        }
        .journal-top-bar {
            display: block;
        }
    }

    .participant-toggle-right {
        position: fixed;
        top:32%;
        right: -8px;
        overflow: auto;
        z-index: 2;

       &.expanded {
           right: 80%
       }
    }
    .participant-section-right {
        position: fixed;
        right: 0;
        top: 5%;
        overflow: auto;
        width: 80%;
        z-index: 2;
        max-height: calc(100vh - 56px);
        overflow: auto;
    }
    .participant-right-side-bar {
        background: white;
        padding: 20px;

    }
    .overlay:before {
        content: '';
        background: rgba(0,0,0,0.4);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
    }

</style>
