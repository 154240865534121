<template>
    <div class="upload-v2__loading">
        <div class="upload-v2__loader">
            <div class="rect1" />
            <div class="rect2" />
            <div class="rect3" />
            <div class="rect4" />
            <div class="rect5" />
        </div>

        <div class="upload-v2__loading-filename">
            <span>
                {{ filename }}
            </span>
        </div>

        <div class="upload-v2__progress">
            <div class="upload-v2__progress-bar" :style="{ 'width': progress + '%' }" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UploadProgress',
        props: {
            uploader: {
                type: Object,
                required: true,
            },
            id: {
                type: Number,
                required: true,
            },
            filename: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                bytesUploaded: 0,
                totalSize: 0,
            };
        },
        computed: {
            progress() {
                return (this.bytesUploaded / this.totalSize) * 100 || 0;
            },
        },
        mounted() {
            this.uploader.on('progress', this.updateProgress);
        },
        beforeDestroy() {
            this.uploader.off('progress', this.updateProgress);
        },
        methods: {
            updateProgress(id, filename, bytesUploaded, totalSize) {
                if (this.id !== id) {
                    return;
                }

                this.totalSize = totalSize;
                this.bytesUploaded = bytesUploaded;
            },
        },
    };
</script>
