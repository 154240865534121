import ToastApi from './ToastApi';

export default class Toaster {
    /** {Store} */
    store;

    constructor(store) {
        this.store = store;
    }

    add(type, message, autoRemove = false, containsHtml = false, redirectUrl = '') {
        if (!message) {
            return;
        }

        this.store.dispatch('toasts/addToast', {
            type,
            message,
            autoRemove,
            containsHtml,
            redirectUrl,
        });
    }

    success(message, autoRemove) {
        this.add('success', message, autoRemove);
    }

    warning(message, autoRemove) {
        this.add('warning', message, autoRemove);
    }

    error(message, autoRemove) {
        this.add('error', message, autoRemove);
    }

    info(message, autoRemove) {
        this.add('info', message, autoRemove);
    }

    htmlSuccess(message, autoRemove) {
        this.add('success', message, autoRemove, true);
    }

    htmlWarning(message, autoRemove) {
        this.add('warning', message, autoRemove, true);
    }

    htmlError(message, autoRemove) {
        this.add('error', message, autoRemove, true);
    }

    htmlInfo(message, autoRemove) {
        this.add('info', message, autoRemove, true);
    }

    seed() {
        const toasts = JSON.parse(document.querySelector('meta[name="cmnty-toasts"]').content);
        toasts.forEach(({ type, message, auto_remove: autoRemove, contains_html: containsHtml }) => {
            this.add(type, message, autoRemove, containsHtml);
        });
    }

    async loadFromServer() {
        const { body: { data: toasts } } = await ToastApi.loadFromServer();
        toasts.forEach(({ type, message, auto_remove: autoRemove, contains_html: containsHtml, url: redirectUrl }) => {
            this.add(type, message, autoRemove, containsHtml, redirectUrl);
        });
    }

    clearToasts() {
        this.store.dispatch('toasts/clearToasts');
    }
}
