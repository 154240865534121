var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    { tag: "component", staticClass: "toast", class: "toast--" + _vm.type },
    [
      _vm.type === "info"
        ? _c("i", { staticClass: "toast__icon fas fa-info" })
        : _vm.type === "success"
        ? _c("i", { staticClass: "toast__icon fas fa-check" })
        : _vm.type === "warning"
        ? _c("i", { staticClass: "toast__icon fas fa-exclamation-triangle" })
        : _vm.type === "error"
        ? _c("i", { staticClass: "toast__icon fas fa-exclamation" })
        : _vm.type === "points-rewarded"
        ? _c("i", { staticClass: "toast__icon far fa-angle-double-up" })
        : _vm.type === "badge-rewarded"
        ? _c("i", { staticClass: "toast__icon fas fa-trophy-alt" })
        : _c("i", { staticClass: "toast__icon fas fa-info" }),
      _vm._v(" "),
      _vm.containsHtml
        ? _c("div", {
            staticClass: "toast__message",
            domProps: { innerHTML: _vm._s(_vm.message) }
          })
        : _c("div", { staticClass: "toast__message" }, [
            _vm._v("\n        " + _vm._s(_vm.message) + "\n    ")
          ]),
      _vm._v(" "),
      _vm.redirectUrl != ""
        ? _c("div", { staticClass: "toast__buttons-container" }, [
            _c(
              "button",
              {
                staticClass:
                  "toast__buttons toast__buttons--accept btn btn--sm",
                on: { click: _vm.accept }
              },
              [_vm._v("\n            Yes\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "toast__buttons toast__buttons--decline btn btn--sm",
                on: { click: _vm.remove }
              },
              [_vm._v("\n            No\n        ")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.dismissible
        ? _c(
            "a",
            {
              staticClass: "toast__remove",
              attrs: { tabindex: "0", "data-jest": "remove" },
              on: { click: _vm.remove }
            },
            [_c("i", { staticClass: "toast__remove-icon fas fa-times" })]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }