<template>
    <div class="upload-v2 upload-v2--small">
        <!-- This 'transition-group' will be converted to an '<ol>' -->
        <transition-group name="upload-v2-list" tag="ol" class="upload-v2__list">
            <file-input
                v-if="allowUpload"
                key="upload-input"
                :uploader="uploader"
                :multiple="multiple"
                :disabled="isDisabled"
                :disabled-reason="disabledReason"
            />

            <li v-for="(file, index) in files" :key="file.id" class="upload-v2__item">
                <upload-progress v-if="file.status === 'submitted'" :id="file.id" :uploader="uploader" :filename="file.filename" />

                <upload v-if="file.status === 'upload successful'" :name="name" :file="file.response" :max-files="maxFiles">
                    <delete-icon slot="top" @deleteUpload="deleteUpload(index)" />
                    <position-controls
                        v-if="multiple"
                        slot="footer"
                        :position="index"
                        :total="files.length"
                        @moveLeft="moveLeft(index)"
                        @moveRight="moveRight(index)"
                    />
                </upload>

                <upload-errors v-if="file.status === 'upload failed'" :errors="file.response.errors">
                    <delete-icon slot="top" @deleteUpload="deleteUpload(index)" />
                </upload-errors>
            </li>
        </transition-group>
    </div>
</template>

<script>
    import FineUploaderTraditional from 'fine-uploader-wrappers';
    import * as ObjectFitImages from 'object-fit-images';
    import DeleteIcon from './DeleteIcon.vue';
    import FileInput from './FileInput.vue';
    import PositionControls from './PositionControls.vue';
    import Upload from './Upload.vue';
    import UploadErrors from './UploadErrors.vue';
    import UploadProgress from './UploadProgress.vue';

    export default {
        name: 'CmntyUploader',

        components: {
            DeleteIcon,
            FileInput,
            PositionControls,
            Upload,
            UploadErrors,
            UploadProgress,
        },
        props: {
            name: {
                type: String,
                required: true,
            },
            mapping: {
                type: String,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            initialFiles: {
                type: Array,
                default: () => [],
            },
            translationDomain: {
                type: String,
                default: 'uploader_frontend',
            },
            identifier: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                uploader: new FineUploaderTraditional({
                    options: {
                        debug: false,
                        request: {
                            endpoint: `/_uploader/v2/${this.mapping}/`,
                            inputName: 'upload[file]',
                            params: {
                                'upload[_token]': document.querySelector('meta[name="cmnty-upload-csrf-token"]').content,
                            },
                        },
                    },
                }),
                files: [],
                manuallyDisabled: false,
            };
        },
        computed: {
            config() {
                return this.$store.getters['configuration/getSetting'](`uploader/${this.mapping}`);
            },
            maxFiles() {
                return this.config.max_files;
            },
            multiple() {
                return this.maxFiles > 1;
            },
            allowUpload() {
                return this.config.allowed;
            },
            isDisabled() {
                if (!this.allowUpload) {
                    return true;
                }

                if (this.manuallyDisabled) {
                    return true;
                }

                if (this.disabled) {
                    return true;
                }

                if (this.multiple) {
                    return this.files.length >= this.maxFiles;
                }

                return false;
            },
            disabledReason() {
                if (this.multiple && this.files.length >= this.maxFiles) {
                    return this.$trans('uploader.max_files_limit_reached', { '%maxFiles%': this.maxFiles }, this.translationDomain);
                }

                return '';
            },
        },
        watch: {
            files(files) {
                const ids = files.filter((file) => !!file.response && !!file.response.id).map((file) => file.response.id);

                this.$emit('input', ids);
            },
        },
        mounted() {
            this.initialFiles.map((file) => this.files.push({
                id: Math.floor((Math.random() * (99999 - 10000)) + 10000),
                filename: file.original_name,
                response: file,
                status: 'upload successful',
            }));

            this.uploader.on('submitted', this.fileSubmitted);
            this.uploader.on('complete', this.uploadCompleted);
            this.uploader.on('cancel', this.uploadCancelled);

            this.$emit('cmnty-uploader.inject-upload', this.injectUpload);
            this.$emit('cmnty-uploader.reset-files', this.resetFiles);

            window.addEventListener('unload', () => {
                this.cancelAll();
            });

            ObjectFitImages();
        },
        created() {
            this.$translator.load(this.translationDomain);
        },
        beforeDestroy() {
            this.$emit('cmnty-uploader.inject-upload', this.injectUpload);
        },
        methods: {
            fileSubmitted(id, filename) {
                this.files.push({ id, filename, status: 'submitted' });
            },
            uploadCompleted(id, filename, response) {
                if (!response.success) {
                    this.uploadFailed(id, filename, response);

                    return;
                }

                if (!this.multiple) {
                    this.files = this.files.filter((file) => file.id === id || file.status === 'submitted');
                }

                const index = this.files.findIndex((file) => file.id === id);
                if (index === -1) {
                    // File no longer exists.
                    return;
                }

                this.$set(this.files[index], 'status', 'upload successful');
                this.$set(this.files[index], 'response', response);

                this.$emit('cmnty-uploader.upload-completed', this.mapping, response);
            },
            uploadFailed(id, filename, response) {
                const index = this.files.findIndex((file) => file.id === id);
                if (index === -1) {
                    // File no longer exists.
                    return;
                }

                this.$set(this.files[index], 'status', 'upload failed');
                this.$set(this.files[index], 'response', { errors: [this.$trans('uploader.unknown_error', {}, this.translationDomain)], ...response });
            },
            uploadCancelled(id) {
                const index = this.files.findIndex((file) => file.id === id);
                if (index === -1) {
                    // File no longer exists.
                    return;
                }

                this.files.splice(index, 1);
            },
            cancelAll() {
                this.uploader.methods.cancelAll();
            },
            deleteUpload(index) {
                this.files.splice(index, 1);

                this.$emit('cmnty-uploader.upload-deleted', this.mapping);
            },
            moveLeft(index) {
                if (index < 1) {
                    return;
                }

                this.files.splice(index - 1, 2, this.files[index], this.files[index - 1]);
            },
            moveRight(index) {
                if (index >= this.files.length - 1) {
                    return;
                }

                this.files.splice(index, 2, this.files[index + 1], this.files[index]);
            },
            injectUpload(response, identifier) {
                if (identifier && identifier !== this.identifier) {
                    return;
                }

                this.files.push({
                    id: Math.floor((Math.random() * (99999 - 10000)) + 10000),
                    filename: response.original_name,
                    response,
                    status: 'upload successful',
                });

                ObjectFitImages();
            },
            disable() {
                this.manuallyDisabled = true;
            },
            enable() {
                this.manuallyDisabled = false;
            },
            resetFiles() {
                this.files = [];
            },
        },
    };
</script>
