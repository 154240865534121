import { MANAGERS_WITH_CMNTY } from '../../../../../Core/User/Roles';

const getIdsFromChannelName = (channel) => {
    // Removes "chat_private"
    const cleanChannelNameFromChatAndPrivateStrings = channel.replace('chat_private-', '');
    // Extracts IDs to array of IDs
    const ids = cleanChannelNameFromChatAndPrivateStrings.split('-').map((id) => parseInt(id, 10));
    return ids;
};

export const canSendMessageInModeratorsWithIndividualObserver = (subject, user) => {
    // Has to be "chat_moderators_observer-X"
    // Removes "chat_moderators_observer"
    if (MANAGERS_WITH_CMNTY.includes(user.role.type)) {
        return true;
    }
    const cleanChannelNameFromChatAndPrivateStrings = subject.replace('chat_moderators_observer-', '');
    return parseInt(cleanChannelNameFromChatAndPrivateStrings, 10) === user.id;
};

export default getIdsFromChannelName;
