<template>
    <div class="main-item__breadcrumbs">
        <ul class="breadcrumbs">
            <li v-for="(crumb, index) in crumbs" :key="index" class="breadcrumbs__item" data-jest="crumb">
                <router-link
                    v-if="crumb.route"
                    :to="crumb.route"
                    tag="a"
                    class="breadcrumbs__link"
                    data-jest="router-link"
                >
                    {{ crumb.title }}
                </router-link>
                <a v-else-if="crumb.href" :href="crumb.href" class="breadcrumbs__link" data-jest="link">
                    {{ crumb.title }}
                </a>
                <span v-else class="breadcrumbs__link" data-jest="label">
                    {{ crumb.title }}
                </span>
                <i class="breadcrumbs__separator fa fa-angle-double-right" />
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'BreadcrumbsComponent',
        props: {
            crumbs: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .breadcrumbs {
        @include reset-list();
        display: flex;
        font-size: $font-s;
        max-width: 100%;
        overflow: hidden;
        width: 100%;

        &__item {
            align-items: center;
            display: inline-flex;
            line-height: 1.1;
            max-width: 33%;

            &:last-child {
                .breadcrumbs {
                    &__separator {
                        display: none;
                    }
                }
            }
        }

        &__link {
            color: $color-grey-darker;
            font-size: 12px;
            font-style: italic;
            line-height: 1.5;
            overflow: hidden;
            padding-left: 1px;
            padding-right: 2px;
            text-overflow: ellipsis;
            white-space: nowrap;

            &[href]:hover {
                color: $color-base;
            }
        }

        &__separator {
            color: $color-base;
            flex-shrink: 0;
            margin: 0 $gutter-s;
        }
    }
</style>

<style lang="scss">
    [dir='rtl'] {
        .breadcrumbs {
            &__separator {
                transform: rotate(180deg);
            }
        }
    }
</style>
