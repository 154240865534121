var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("view-profile") && _vm.linked && _vm.user.id !== 0
    ? _c(
        "a",
        {
          staticClass: "user-name user-name--linked",
          attrs: { href: "/user/view_profile/" + _vm.user.id }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.user.name || _vm.user.display_name) + "\n    "
          ),
          _vm.showCrownIcon
            ? _c("i", { staticClass: "fa-solid fa-crown" })
            : _vm._e(),
          _vm._v(" "),
          _vm.showEyeIcon
            ? _c("i", { staticClass: "fa-solid fa-eye" })
            : _vm._e()
        ]
      )
    : _c("span", { staticClass: "user-name" }, [
        _vm._v(_vm._s(_vm.user.name || _vm.user.display_name) + "\n    "),
        _vm.showCrownIcon
          ? _c("i", { staticClass: "fa-solid fa-crown" })
          : _vm._e(),
        _vm._v(" "),
        _vm.showEyeIcon
          ? _c("i", { staticClass: "fa-solid fa-crown" })
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }