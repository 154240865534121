var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.reported
    ? _c(
        "a",
        {
          ref: "button",
          staticClass:
            "actions-list__action report-action btn btn--text btn--action",
          attrs: {
            tabindex: "0",
            "data-cy": "actions-list-report",
            "aria-label": "report action",
            href: "#"
          },
          on: { click: _vm.showModal }
        },
        [
          _c("i", { staticClass: "report-action__icon fas fa-flag" }),
          _vm._v(" "),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: function() {
                  return _vm.$refs.button
                },
                disabled: _vm.screenWidth <= 900,
                delay: { show: 500, hide: 0 },
                triggers: "hover"
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("trans")("action.report")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "modal-component",
            {
              staticClass: "report-action__confirm-modal",
              attrs: { show: _vm.isModalVisible },
              on: { close: _vm.hideModal }
            },
            [
              _c("template", { slot: "title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("trans")("reporting.report.title")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("template", { slot: "content" }, [
                _c("p", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("trans")("reporting.report.description")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "custom-controls custom-controls--vertical" },
                  [
                    _c("label", { staticClass: "custom-controls__label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reason,
                            expression: "reason"
                          }
                        ],
                        staticClass: "custom-controls__input",
                        attrs: {
                          id: "inappropriate-content",
                          value: "inappropriate",
                          type: "radio"
                        },
                        domProps: {
                          checked: _vm._q(_vm.reason, "inappropriate")
                        },
                        on: {
                          change: function($event) {
                            _vm.reason = "inappropriate"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "custom-controls__indicator custom-controls__indicator--radio",
                        attrs: { for: "inappropriate-content" }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "custom-controls__description",
                          attrs: { for: "inappropriate-content" }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "reporting.report.inappropriate_content"
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "custom-controls__label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reason,
                            expression: "reason"
                          }
                        ],
                        staticClass: "custom-controls__input",
                        attrs: {
                          id: "spam-irrelevant",
                          value: "spam",
                          type: "radio"
                        },
                        domProps: { checked: _vm._q(_vm.reason, "spam") },
                        on: {
                          change: function($event) {
                            _vm.reason = "spam"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "custom-controls__indicator custom-controls__indicator--radio",
                        attrs: { for: "inappropriate-content" }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "custom-controls__description",
                          attrs: { for: "spam-irrelevant" }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")(
                                  "reporting.report.spam_irrelevant_content"
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "custom-controls__label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reason,
                            expression: "reason"
                          }
                        ],
                        staticClass: "custom-controls__input",
                        attrs: { id: "other", value: "other", type: "radio" },
                        domProps: { checked: _vm._q(_vm.reason, "other") },
                        on: {
                          change: function($event) {
                            _vm.reason = "other"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "custom-controls__indicator custom-controls__indicator--radio",
                        attrs: { for: "inappropriate-content" }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "custom-controls__description",
                          attrs: { for: "other" }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("trans")("reporting.report.other")
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn--secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.hideModal }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("trans")("action.cancel")) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn--delete",
                  attrs: { type: "button", disabled: _vm.reason === null },
                  on: { click: _vm.report }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("trans")("reporting.report.action")) +
                      "\n        "
                  )
                ]
              )
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }