import { render, staticRenderFns } from "./AssignmentView.vue?vue&type=template&id=16b14d8b&scoped=true&"
import script from "./AssignmentView.vue?vue&type=script&lang=js&"
export * from "./AssignmentView.vue?vue&type=script&lang=js&"
import style0 from "./AssignmentView.vue?vue&type=style&index=0&id=16b14d8b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b14d8b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/platform/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16b14d8b')) {
      api.createRecord('16b14d8b', component.options)
    } else {
      api.reload('16b14d8b', component.options)
    }
    module.hot.accept("./AssignmentView.vue?vue&type=template&id=16b14d8b&scoped=true&", function () {
      api.rerender('16b14d8b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Module/Journal/Overview/AssignmentView/AssignmentView.vue"
export default component.exports