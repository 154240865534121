var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-v2__inner" },
    [
      _vm._t("top"),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "upload-v2__errors" },
        _vm._l(_vm.errors, function(error, index) {
          return _c("li", { key: index, staticClass: "upload-v2__error" }, [
            _c("i", {
              staticClass: "upload-v2__error-icon fas fa-exclamation-circle"
            }),
            _vm._v(" "),
            _c("span", { staticClass: "upload-v2__error-text" }, [
              _vm._v("\n                " + _vm._s(error) + "\n            ")
            ])
          ])
        }),
        0
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }