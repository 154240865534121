export default {
    methods: {
        getSelectedComment() {
            let comment = null;
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has('comment')) {
                comment = parseInt(urlParams.get('comment'), 10);
            }

            return comment;
        },
    },
};
