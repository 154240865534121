var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "button",
      staticClass:
        "subscribe-action actions-list__action btn btn--text btn--action",
      class: { "subscribe-action--subscribed": _vm.subscribed },
      attrs: {
        tabindex: "0",
        "data-cy": "actions-list-subscribe",
        "data-jest": "button",
        "aria-label": "subscribe action",
        href: "#"
      },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.toggleSubscription($event)
        },
        click: _vm.toggleSubscription
      }
    },
    [
      _vm.subscribed
        ? _c("i", {
            staticClass:
              "subscribe-action__toggle-icon btn__icon fas fa-envelope",
            attrs: { "data-jest": "subscribed-icon" }
          })
        : _c("i", {
            staticClass:
              "subscribe-action__toggle-icon btn__icon far fa-envelope",
            attrs: { "data-jest": "unsubscribed-icon" }
          }),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: function() {
              return _vm.$refs.button
            },
            disabled: _vm.screenWidth <= 900,
            delay: { show: 500, hide: 0 },
            "data-jest": "tooltip"
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.tooltipText) + "\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }