var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "default-avatar",
      attrs: { x: "0px", y: "0px", viewBox: "0 0 462 462" }
    },
    [
      _c(
        "g",
        {
          staticClass: "default-avatar__background",
          class: "default-avatar__background--" + _vm.color,
          attrs: { id: "bg" }
        },
        [
          _c("rect", {
            staticClass: "st0",
            attrs: { width: "462", height: "462" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "g",
        { staticClass: "default-avatar__shadow", attrs: { id: "shadow" } },
        [
          _c("polygon", {
            staticClass: "st1",
            attrs: {
              points:
                "295,87 462,254 462,462 160.5,462 91.2,393.2 210.2,246.5 172.9,208.5"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("g", { staticClass: "default-avatar__head", attrs: { id: "head" } }, [
        _c("circle", {
          staticClass: "st2",
          attrs: { cx: "231", cy: "144.7", r: "86.3" }
        })
      ]),
      _vm._v(" "),
      _c("g", { staticClass: "default-avatar__body", attrs: { id: "body" } }, [
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M231,405.3c36,0,72.5,0,105.7,0c46.3-2.6,60.6-41.6,52.3-94.6c-8.2-58-34.4-97.9-78.8-94.4c-25.8,20.6-52.1,32.2-79.3,32.2c-27.1,0-53.4-11.6-79.3-32.2c-44.3-3.5-70.5,36.4-78.8,94.4c-8.2,53,6,92,52.3,94.6C158.5,405.3,195,405.3,231,405.3L231,405.3z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }