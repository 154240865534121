var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "default-fill-image",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 748 748",
        preserveAspectRatio: "none"
      }
    },
    [
      _c("path", {
        staticClass: "default-fill-image__path",
        attrs: { d: "M424 748L0 748 0.1 0.2 92.7 0.3z" }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "default-fill-image__path",
        attrs: { opacity: ".8", d: "M363.3 0.2L323 521 93 0" }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "default-fill-image__path",
        attrs: { opacity: ".5", d: "M541.6 0.3L306 748 363.3 0.2" }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "default-fill-image__path",
        attrs: { opacity: ".3", d: "M748 0.2L747.7 301.3 306 748 541.6 0.3" }
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "default-fill-image__path",
        attrs: { opacity: ".1", d: "M748 746.1L306 748 748 301.3z" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }