/* istanbul ignore file */
import Cookie from 'js-cookie';

export default class SortOrderStorage {
    /** @var {SortOrder[]} */
    sortOrders;

    constructor() {
        this.sortOrders = Cookie.getJSON('CMNTY-Platform-SortOrderStorage') || {};
    }

    getSortOrder(location, defaultSortOrder) {
        return this.sortOrders[location] || defaultSortOrder;
    }

    setSortOrder(location, sortOrder) {
        this.sortOrders[location] = sortOrder;

        this.save();
    }

    save() {
        Cookie.set('CMNTY-Platform-SortOrderStorage', this.sortOrders, { secure: true, expires: 365 });
    }
}
