<template>
    <a
        ref="button"
        class="subscribe-action actions-list__action btn btn--text btn--action"
        :class="{ 'subscribe-action--subscribed': subscribed }"
        tabindex="0"
        data-cy="actions-list-subscribe"
        data-jest="button"
        aria-label="subscribe action"
        href="#"
        @keypress.enter="toggleSubscription"
        @click="toggleSubscription"
    >
        <i v-if="subscribed" class="subscribe-action__toggle-icon btn__icon fas fa-envelope" data-jest="subscribed-icon" />
        <i v-else class="subscribe-action__toggle-icon btn__icon far fa-envelope" data-jest="unsubscribed-icon" />
        <b-tooltip :target="() => $refs.button" :disabled="screenWidth <= 900" :delay="{ show: 500, hide: 0 }" data-jest="tooltip">
            {{ tooltipText }}
        </b-tooltip>
    </a>
</template>

<script>
    export default {
        name: 'SubscribeAction',
        props: {
            subscribed: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            tooltipText() {
                if (this.subscribed) {
                    return this.$trans('action.unsubscribe', {}, 'frontend');
                }

                return this.$trans('action.subscribe', {}, 'frontend');
            },
            screenWidth() {
                return this.$store.getters['configuration/getSetting']('screenWidth');
            },
        },
        methods: {
            toggleSubscription() {
                if (this.subscribed) {
                    this.$emit('unsubscribe');
                } else {
                    this.$emit('subscribe');
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .subscribe-action {
        &--subscribed {
            color: var(--color-primary);
        }
    }
</style>
