<template>
    <modal-component class="front-end__confirm-modal" :show="isCookieModalVisible">
        <template slot="title">
            {{ title }}
        </template>
        <template slot="content">
            <p v-html="content" />
        </template>
        <button class="btn btn--text" @click="decline">
            {{ 'common_decline'|trans({}, 'ci_global') }}
        </button>
        <button class="btn btn--primary" @click="accept">
            {{ 'common_accept'|trans({}, 'ci_global') }}
        </button>
    </modal-component>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Cookie from 'js-cookie';
    import store from '../../Store';
    import CookieModalStore from './CookieModalStore';

    store.registerModule('cookieModal', CookieModalStore);

    export default {
        name: 'CookieModal',
        computed: {
            title() {
                return this.$store.getters['configuration/getSetting']('policyCookieMessageTitle');
            },
            content() {
                return this.$store.getters['configuration/getSetting']('policyCookieMessageContent');
            },
            ...mapGetters({
                isCookieModalVisible: 'cookieModal/getIsCookieModalVisibile',
            }),
        },
        created() {
            this.$translator.load('ci_global');
        },
        methods: {
            accept() {
                Cookie.set('cmnty_cookie_consent', 'accepted', {
                    expires: 365,
                    path: '/',
                    secure: true,
                });

                this.hideModal();
            },
            decline() {
                Cookie.set('cmnty_cookie_consent', 'declined', {
                    expires: 365,
                    path: '/',
                    secure: true,
                });

                this.hideModal();
            },
            hideModal() {
                this.isVisible = false;
                this.$store.dispatch('cookieModal/setCookieModalVisibility', false);
            },
        },
    };
</script>

<style scoped>

</style>
