import Vue from 'vue';
import Cookie from 'js-cookie';

const store = {
    namespaced: true,
    state() {
        return {
            isCookieModalVisible: (typeof Cookie.get('cmnty_cookie_consent') === 'undefined') && !/^\/_focus-recorder/.test(window.location.pathname),
        };
    },
    getters: {
        getIsCookieModalVisibile: (state) => state.isCookieModalVisible,
    },
    mutations: {
        isCookieModalVisibile(state, isCookieModalVisible) {
            Vue.set(state, 'isCookieModalVisible', isCookieModalVisible);
        },
    },
    actions: {
        setCookieModalVisibility({ commit }, isCookieModalVisible) {
            commit('isCookieModalVisibile', isCookieModalVisible);
        },
    },
};

export default store;
