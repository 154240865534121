var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "blueimp-gallery blueimp-gallery-controls",
        attrs: { id: "blueimp-gallery" }
      },
      [
        _c("div", { staticClass: "slides" }),
        _vm._v(" "),
        _c("h3", { staticClass: "title" }),
        _vm._v(" "),
        _c("a", { staticClass: "prev" }),
        _vm._v(" "),
        _c("a", { staticClass: "next" }),
        _vm._v(" "),
        _c("a", { staticClass: "close" }),
        _vm._v(" "),
        _c("a", { staticClass: "play-pause" }),
        _vm._v(" "),
        _c("ol", { staticClass: "indicator" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }