import { render, staticRenderFns } from "./CmntyUploader.vue?vue&type=template&id=245ed448&"
import script from "./CmntyUploader.vue?vue&type=script&lang=js&"
export * from "./CmntyUploader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/platform/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('245ed448')) {
      api.createRecord('245ed448', component.options)
    } else {
      api.reload('245ed448', component.options)
    }
    module.hot.accept("./CmntyUploader.vue?vue&type=template&id=245ed448&", function () {
      api.rerender('245ed448', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Core/Attachments/Uploader/CmntyUploader.vue"
export default component.exports