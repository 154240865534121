<template>
    <img ref="image" class="image js-object-fit" :src="src" :alt="alt">
</template>

<script>
    /* istanbul ignore file */
    import ObjectFitImages from 'object-fit-images';

    export default {
        name: 'ImageComponent',
        props: {
            src: {
                type: String,
                required: true,
            },
            alt: {
                type: String,
                default: '',
            },
        },
        mounted() {
            ObjectFitImages(this.$refs.image);
        },
    };
</script>

<style lang="scss" scoped>
    .image {
        &[cover] {
            font-family: 'object-fit: cover;';
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        &[contain] {
            font-family: 'object-fit: contain;';
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }
</style>
