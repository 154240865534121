var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "button",
      staticClass: "actions-list__action btn btn--action btn--text",
      attrs: {
        slot: "hidden",
        tabindex: "0",
        "data-cy": "actions-list-copy-link",
        "data-jest": "button",
        "aria-label": "permalink action",
        href: "#"
      },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.$emit("copy-permalink")
        },
        click: function($event) {
          return _vm.$emit("copy-permalink")
        }
      },
      slot: "hidden"
    },
    [
      _c("i", { staticClass: "comment__action-icon fas fa-link" }),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: function() {
              return _vm.$refs.button
            },
            disabled: _vm.screenWidth <= 900,
            delay: { show: 500, hide: 0 },
            triggers: "hover",
            "data-jest": "tooltip"
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("trans")("action.permalink")) +
              "\n    "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }