/* istanbul ignore file */
import ToastsStore from './ToastsStore';
import Toaster from './Toaster';

export default {
    install(Vue, { store }) {
        store.registerModule('toasts', ToastsStore);

        const toaster = new Toaster(store);
        toaster.seed();

        // Global methods / properties.
        // Use as Vue.toaster
        // eslint-disable-next-line no-param-reassign
        Vue.toaster = toaster;

        // Instance methods / properties
        // Use withing vue components as this.$toaster
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$toaster = toaster;
    },
};
