<template>
    <component :is="tag" class="notification" :class="[{ 'notification--has-buttons': $slots.button }, `notification--${type}`]">
        <i v-if="type === 'error'" class="notification__icon fas fa-exclamation"></i>
        <i v-else-if="type === 'success'" class="notification__icon fas fa-check"></i>
        <i v-else-if="type === 'warning'" class="notification__icon fas fa-exclamation-triangle"></i>
        <i v-else-if="type === 'points-rewarded'" class="notification__icon far fa-angle-double-up"></i>
        <i v-else-if="type === 'badge-rewarded'" class="notification__icon fas fa-trophy"></i>
        <i v-else class="notification__icon fa" :class="icon"></i>

        <div class="notification__message" v-html="message" />

        <div v-if="$slots.button" class="notification__buttons">
            <slot name="button" />
        </div>
        <a v-if="canBeRemoved" class="notification__remove" @click="remove" href="javascript:void(0)">
            <i class="notification__remove-icon fas fa-remove"></i>
        </a>
    </component>
</template>

<script>
    export default {
        name: 'NotificationComponent',
        props: {
            canBeRemoved: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
                default: 'fa-info',
            },
            message: {
                type: String,
                required: true,
            },
            tag: {
                type: String,
                default: 'div',
            },
            type: {
                type: String,
                default: 'notice',
            },
        },
        methods: {
            remove() {
                this.$emit('remove');
            },
        },
    };
</script>
