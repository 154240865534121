var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "upload-v2__controls" }, [
    _vm.position > 0
      ? _c(
          "span",
          {
            staticClass: "upload-v2__move-left",
            on: {
              click: function($event) {
                return _vm.$emit("moveLeft")
              }
            }
          },
          [
            _c("i", {
              staticClass: "upload-v2__move-left-icon fas fa-chevron-left"
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.position < _vm.total - 1
      ? _c(
          "span",
          {
            staticClass: "upload-v2__move-right",
            on: {
              click: function($event) {
                return _vm.$emit("moveRight")
              }
            }
          },
          [
            _c("i", {
              staticClass: "upload-v2__move-right-icon fas fa-chevron-right"
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }