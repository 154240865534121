<template>
    <button
        class="actions-list__action btn--text"
        :class="textMode ? '' : 'btn'"
        data-cy="actions-list-reply"
        aria-label="reply action"
        @click="$emit('reply')"
    >
        <i v-if="!textMode" class="actions-list__action-icon fas fa-reply" />
        <span class="actions-list__action-label">
            {{ 'action.reply'|trans() }}
        </span>
    </button>
</template>

<script>
    export default {
        name: 'ReplyAction',
        props: {
            textMode: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
    };
</script>
