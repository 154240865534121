<template>
    <div class="status-messages">
        <div v-for="message in messages" :key="message.id" class="status-messages__message">
            <div class="status-messages__message-content" v-html="message.content" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatusMessages',
        computed: {
            messages() {
                const messages = [];

                if (this.$store.getters['configuration/getSetting']('status/paused')) {
                    messages.push({ id: 'paused', content: this.$trans('common_platform_paused', {}, 'ci_platform_status') });
                }

                if (this.$store.getters['configuration/getSetting']('status/deleted')) {
                    messages.push({ id: 'deleted', content: this.$trans('common_platform_deleted', {}, 'ci_platform_status') });
                }

                if (this.$store.getters['configuration/getSetting']('status/sandbox')) {
                    messages.push({ id: 'sandbox', content: this.$trans('common_platform_sandbox', {}, 'ci_platform_status') });
                }

                if (this.$store.getters['configuration/getSetting']('status/demo')) {
                    messages.push({ id: 'demo', content: this.$trans('common_platform_demo', {}, 'ci_platform_status') });
                }

                if (this.$store.getters['configuration/getSetting']('status/trialExpired')) {
                    messages.push({ id: 'trial-expired', content: this.$trans('common_platform_expired_reason_trial_expired', {}, 'ci_platform_status') });
                }

                const impersonatedUser = this.$store.getters['configuration/getSetting']('status/impersonatedUser');
                if (impersonatedUser) {
                    messages.push({ id: 'impersonated-user', content: this.$trans('impersonating.status_message', { '{impersonatedUser}': `<strong>${impersonatedUser}</strong>`, '{openSwitchBackLink}': '<a href="/components/login/switch_back/">', '{closeSwitchBackLink}': '</a>' }) });
                }

                return messages;
            },
            isManager() {
                return this.$store.getters['user/getUser'].has_manager_role;
            },
        },
        created() {
            this.$translator.load('ci_platform_status');
        },
    };
</script>

<style lang="scss">
    $color-status-message: #4596bf;

    .status-messages {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__message {
            background: $color-status-message;
            color: #fff;
            display: flex;
            font-size: $font-s;
            line-height: 1.3;
            justify-content: center;
            padding: $gutter-xs;
            text-align: center;
            width: 100%;

            &:not(:first-child) {
                padding-top: $gutter-xxs;
            }

            &:not(:last-child) {
                padding-bottom: $gutter-xxs;
            }

            &--red-hover {
                &:hover {
                    background: $color-red-flamingo;
                    cursor: pointer;
                }
            }
        }

        &__message-content {
            border: 1px dashed #fff;
            padding: 6px $gutter-s; // Exception on regular gutters as it improves readability vs xs and saves space vs s
            width: 100%;

            a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
</style>
