import CanAddBackroomComment from './CanAddBackroomComment';
import CanAddNote from './CanAddNote';
import CanBookmarkActivity from './CanBookmarkActivity';
import CanDeleteBackroomComment from './CanDeleteBackroomComment';
import CanDeleteNote from './CanDeleteNote';
import CanEditNote from './CanEditNote';
import CanUpdateBackroomComment from './CanUpdateBackroomComment';

export default {
    'add-note': CanAddNote,
    'edit-note': CanEditNote,
    'delete-note': CanDeleteNote,
    'bookmark-activity': CanBookmarkActivity,
    'add-backroom-comment': CanAddBackroomComment,
    'update-backroom-comment': CanUpdateBackroomComment,
    'delete-backroom-comment': CanDeleteBackroomComment,

};
