var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "journal-new-entry flex",
      attrs: { id: "journal-entry-form" }
    },
    [
      _c("nice-date"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "editor-wrapper" },
        [
          _c("markdown-editor", {
            staticClass: "new-entry__markdown-editor",
            attrs: { placeholder: "", "textarea-name": "message" },
            model: {
              value: _vm.message,
              callback: function($$v) {
                _vm.message = $$v
              },
              expression: "message"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "attachment-wrapper" },
            [
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm._f("trans")(
                      "journal.add_attachment",
                      {},
                      "journal_frontend"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("cmnty-uploader", {
                ref: "uploader",
                attrs: {
                  id: "attachments",
                  "initial-files": _vm.initialValue
                    ? _vm.initialValue.attachments.attachments
                    : [],
                  name: "attachments",
                  identifier: "journal-entry-new",
                  mapping: "journal_entry_attachment",
                  "translation-domain": "uploader_frontend"
                },
                model: {
                  value: _vm.attachments,
                  callback: function($$v) {
                    _vm.attachments = $$v
                  },
                  expression: "attachments"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("date-picker", {
            staticClass: "discussion-form__date",
            attrs: { "initial-date": _vm.initialDate },
            model: {
              value: _vm.entryDate,
              callback: function($$v) {
                _vm.entryDate = $$v
              },
              expression: "entryDate"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex justify-end form_buttons" }, [
            _vm.isEdit
              ? _c(
                  "button",
                  {
                    staticClass: "form__button btn btn--text",
                    attrs: { type: "button" },
                    on: { click: _vm.cancelEdit }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("trans")(
                            "journal.journal_entry.action.cancel",
                            {},
                            "journal_frontend"
                          )
                        ) +
                        "\n            "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "form__button btn btn--secondary add-journal-btn",
                attrs: { type: "button", name: "", disabled: !_vm.isFormValid },
                on: { click: _vm.addJournal }
              },
              [
                _c("i", { staticClass: "fa-regular fa-book mr-xs" }),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("trans")(
                        "journal.add_journal",
                        {},
                        "journal_frontend"
                      )
                    ) +
                    "\n            "
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }