<template>
    <div class="actions-list__action documents-action">
        <b-dropdown
            variant="link"
            no-caret
            class="documents-action__dropdown"
            toggle-class="documents-action__toggle btn btn--text btn--action"
            data-cy="actions-list-documents"
            aria-label="document action"
        >
            <template slot="button-content">
                <i class="actions-list__action-icon documents-action__action-icon fas fa-paperclip" />
                <span class="actions-list__action-count documents-action__action-count" data-jest="count">
                    {{ documents.length }}
                </span>
            </template>
            <b-dropdown-item v-for="(document, index) in documents" :key="index" :href="document.original_url" data-jest="document">
                <i class="documents-action__dropdown-icon fas fa-file-pdf" />
                <span class="documents-action__dropdown-label" data-jest="filename">
                    {{ document.original_name }}
                </span>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        name: 'DocumentsAction',
        props: {
            documents: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .documents-action {
        display: inline-flex;

        &__dropdown {
            display: inline-flex;
        }

        &__dropdown-label {
            display: inline-block;
            max-width: calc(100% - 16px);
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            white-space: nowrap;
        }
    }

</style>
