const WebshopModule = () => import(/* webpackChunkName: 'webshop-module' */ './WebshopModule.vue');
const WebshopOverview = () => import(/* webpackChunkName: 'webshop-module' */ './Overview/WebshopOverview.vue');
const ItemDetails = () => import(/* webpackChunkName: 'webshop-module' */ './Overview/ItemDetails.vue');
const WebshopCart = () => import(/* webpackChunkName: 'webshop-module' */ './Overview/WebshopCart.vue');

export default {
    path: '/shop',
    component: WebshopModule,
    meta: { bodyClass: 'module-webshop' },
    children: [
        {
            path: '',
            name: 'WebshopOverview',
            component: WebshopOverview,
            meta: { bodyClass: 'webshop-journal__webshop-overview' },
        },
        {
            path: 'items/:itemId',
            name: 'ItemDetails',
            component: ItemDetails,
            meta: { bodyClass: 'webshop-journal__webshop-overview' },
        },
        {
            path: 'carts',
            name: 'WebshopCarts',
            component: WebshopCart,
            meta: { bodyClass: 'webshop-journal__webshop-overview' },
        },
    ],
};
