<template lang="html">
    <transition name="transition-modal" :duration="400">
        <div v-if="show" ref="modalComponent" class="modal">
            <div class="modal__backdrop modal-backdrop" @click="close" />
            <div class="modal__wrapper modal-wrapper">
                <div class="modal__dialog modal-dialog" role="document">
                    <div class="modal__content modal-content" data-cy="modal-content">
                        <div class="modal__header modal-header">
                            <h5 class="modal__title modal-title">
                                <slot name="title" />
                            </h5>
                        </div>
                        <div class="modal__body modal-body">
                            <slot name="content" />
                        </div>
                        <div class="modal__footer modal-footer">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'ModalComponent',
        props: {
            show: {
                type: Boolean,
                required: true,
            },
        },
        watch: {
            async show() {
                if (this.show) {
                    await this.$root.$emit('bv::hide::tooltip');
                    await document.body.classList.add('modal-open');
                    this.trapFocus();
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },
        mounted() {
            document.body.appendChild(this.$el);
        },
        beforeDestroy() {
            document.body.removeChild(this.$el);
            document.body.classList.remove('modal-open');
        },
        methods: {
            close() {
                this.$emit('close');
            },
            trapFocus() {
                const element = this.$refs.modalComponent;
                const focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
                const firstFocusableEl = focusableEls[0];
                const lastFocusableEl = focusableEls[1];
                const KEYCODE_TAB = 9;
                const KEYCODE_ESC = 27;
                firstFocusableEl.focus();

                element.addEventListener('keydown', (e) => {
                    const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
                    const isEscPressed = (e.key === 'Escape' || e.keyCode === KEYCODE_ESC);

                    if (isEscPressed) {
                        this.$emit('close');
                    }

                    if (!isTabPressed) {
                        return;
                    }

                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstFocusableEl) {
                            lastFocusableEl.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ if (document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal {
        align-items: center;
        display: flex;
        justify-content: center;
        max-height: 100%;
        max-width: 100vw;

        &__backdrop {
            background: rgba(0, 0, 0, .6);
            z-index: -1;
        }

        &__wrapper {
            align-items: center;
            display: flex;
            height: auto;
            justify-content: center;
            position: relative;
            width: auto;

            @include max-breakpoint(screen-xs-max) {
                width: 100%;
            }
        }

        &__dialog {
            @include min-breakpoint(screen-sm-min) {
                min-width: 500px;
            }

            @include max-breakpoint(screen-xs-max) {
                width: 100%;
            }
        }

        &__body {
            line-height: 1.5;
            margin-top: $gutter-m;
            max-height: calc(100vh - 250px);
            padding: 0;
        }

        &__content {
            border: 0;
            border-radius: var(--main-border-radius);
            padding: $gutter-l;
        }

        &__header {
            border: 0;
            padding: 0;
        }

        &__footer {
            border: 0;
            margin-top: $gutter-l;
            padding: 0;
        }

        &.full-width {
            .modal__dialog {
                @include min-breakpoint(screen-sm-min) {
                    min-width: calc(100vw - 430px);
                }

                @include max-breakpoint(screen-xs-max) {
                    width: 100%;
                }
            }
            .modal__body {
                margin-top: 0px;
            }
            .modal__content {
                padding: 0px;
            }
        }
        &.full-width-xl {
            .modal__dialog {
                @include min-breakpoint(screen-sm-min) {
                    min-width: calc(100vw - 85px);
                }

                @include max-breakpoint(screen-xs-max) {
                    width: 100%;
                }
            }
            .modal__body {
                margin-top: 0px;
                max-height: 100%;
            }
            .modal__content {
                padding: 0px;
            }
        }
    }

</style>

<style lang="scss">
    .transition-modal {
        &-enter-active,
        &-leave-active {
            .modal-wrapper {
                transition: all .4s ease-in-out;
            }

            .modal-backdrop {
                transition: all .4s ease-in-out;
            }
        }

        &-enter,
        &-leave-to {
            .modal-wrapper {
                opacity: 0;
                transform: scale(0);
            }

            .modal-backdrop {
                opacity: 0;
            }
        }
    }
</style>
