<template>
    <div class="navigation-wrapper" :class="`navigation-wrapper--${platformLayout}`">
        <main-navigation v-if="$store.getters['configuration/getSetting']('screenWidth') >= 900" :type="platformLayout" :items="mainMenuItems" :user-items="userMenuItems" />
        <mobile-navigation v-else :items="mainMenuItems" :user-items="userMenuItems" />
    </div>
</template>

<script>
    import MainNavigation from './MainNavigation.vue';
    import MobileNavigation from './MobileNavigation.vue';

    export default {
        name: 'NavigationWrapper',
        components: {
            MainNavigation,
            MobileNavigation,
        },
        data() {
            return {
                platformLayout: document.querySelector('meta[name="cmnty-platform-layout"]').content,
                mainMenuItems: JSON.parse(document.querySelector('meta[name="cmnty-main-menu-items"]').content),
                userMenuItems: JSON.parse(document.querySelector('meta[name="cmnty-user-menu-items"]').content),
            };
        },
    };
</script>

<style lang="scss">
    @include min-breakpoint(screen-sm-min) {
        .navigation-wrapper {
            &--explore {
                width: 100%;
            }

            &--astra {
                box-shadow: 0 0 10px rgba(#000, .1);
                width: 250px;
            }
        }
    }

    @include min-breakpoint(screen-sm-min) {
        .platform-layout {
            &--explore {
                .main-container,
                .header-container,
                .footer-container {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: calc(1200px + 2 * #{$gutter-l});
                    padding-left: $gutter-l;
                    padding-right: $gutter-l;
                }
            }

            &--astra {
                .main-container,
                .header-container,
                .footer-container {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: calc(1200px + 2 * #{$gutter-l});
                    padding-left: $gutter-l;
                    padding-right: $gutter-l;
                }
            }
        }
    }
</style>
