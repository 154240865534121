<template>
    <div class="nice-date">
        <span class="date-day">{{ $moment(date).date() }}</span>
        <p class="date-sub">
            {{ $moment(date).format('MMM') }}
            <span class="date-year">{{ $moment(date).format('YYYY') }}</span>
        </p>
    </div>
</template>

<script>
    export default {
        props: {
            date: {
                type: Number,
                default: Date.now(),
            },
        },
    };
</script>

<style scoped>
.nice-date {
    border: 0;
    border-radius: 0;
    background: #fff;
    box-shadow: none;
    margin-right: 10px;
}
.date-day {
    border: 1px solid #dadada;
    border-bottom: 0;
    border-radius: var(--main-border-radius) var(--main-border-radius) 0 0;
    height: 50px;
    text-shadow: none;
    padding-top: 3px;
    color: #666;
    display: block;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
}
.date-sub {
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 0 0 var(--main-border-radius) var(--main-border-radius);
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    display: block;
    text-align: center;
    padding: 2px 0;
    margin-bottom: 0;
}
</style>
