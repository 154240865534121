<template>
    <small class="form-error">
        <slot />
    </small>
</template>

<script>
    export default {
        name: 'FormError',
    };
</script>

<style lang="scss" scoped>
    $color-form-error: $color-red-flamingo;

    .form-error {
        color: $color-form-error;
        display: inline-block;
        font-size: $font-s;
        width: 100%;

        + .form-error {
            margin-top: $gutter-xs;
        }
    }
</style>
