<template>
    <div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls">
        <div class="slides" />

        <h3 class="title" />
        <a class="prev" />
        <a class="next" />
        <a class="close" />
        <a class="play-pause" />
        <ol class="indicator" />
    </div>
</template>

<script>
    export default {
        name: 'BlueimpModal',
        mounted() {
            document.body.appendChild(this.$el);
        },
    };
</script>
