var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "transition-promote-solution-modal", duration: 400 } },
    [
      _vm.show
        ? _c(
            "div",
            {
              staticClass: "modal promote-solution-modal",
              attrs: { id: "promote-solution-modal", role: "dialog" }
            },
            [
              _c("div", {
                staticClass: "promote-solution-modal__backdrop modal-backdrop",
                on: { click: _vm.close }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "promote-solution-modal__dialog modal-dialog" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "promote-solution-modal__modal-content modal-content"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "promote-solution-modal__body" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "promote-solution-modal__close-button",
                              on: { click: _vm.close }
                            },
                            [_c("i", { staticClass: "fa far fa-times" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "promote-solution-modal__slider-container"
                            },
                            [
                              _vm._l(_vm.slides, function(slide, index) {
                                return _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.activeSlide,
                                      expression: "activeSlide"
                                    }
                                  ],
                                  key: index,
                                  staticClass:
                                    "promote-solution-modal__slides-input",
                                  attrs: {
                                    id:
                                      "promote-solution-modal-" +
                                      _vm.randomInteger +
                                      "-" +
                                      index,
                                    type: "radio",
                                    name:
                                      "promote-solution-modal-" +
                                      _vm.randomInteger
                                  },
                                  domProps: {
                                    value: index,
                                    checked: _vm._q(_vm.activeSlide, index)
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        _vm.activeSlide = index
                                      },
                                      function($event) {
                                        return _vm.changeSlide(_vm.activeSlide)
                                      }
                                    ]
                                  }
                                })
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  ref: "carousel",
                                  staticClass: "promote-solution-modal__slides"
                                },
                                _vm._l(_vm.slides, function(slide, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "promote-solution-modal__slide"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "promote-solution-modal__content"
                                        },
                                        [
                                          _c(
                                            "h4",
                                            {
                                              staticClass:
                                                "promote-solution-modal__title"
                                            },
                                            [_vm._v(_vm._s(slide.title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "promote-solution-modal__description"
                                            },
                                            [_vm._v(_vm._s(slide.description))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass:
                                          "promote-solution-modal__image",
                                        attrs: {
                                          src: slide.image,
                                          alt:
                                            _vm.solutionName + " image " + index
                                        }
                                      })
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "promote-solution-modal__slides-bullets bullets"
                                },
                                _vm._l(_vm.slides, function(slide, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass:
                                        "promote-solution-modal__slides-bullet",
                                      class: {
                                        "promote-solution-modal__slides-bullet--active":
                                          index === _vm.activeSlide
                                      }
                                    },
                                    [
                                      _c("label", {
                                        staticClass:
                                          "promote-solution-modal__slides-bullet-anchor",
                                        attrs: {
                                          for:
                                            "promote-solution-modal-" +
                                            _vm.randomInteger +
                                            "-" +
                                            index
                                        }
                                      })
                                    ]
                                  )
                                }),
                                0
                              )
                            ],
                            2
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }