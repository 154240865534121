<template>
    <footer class="main-footer">
        <div class="main-footer__wrapper">
            <div class="main-footer__content">
                <div v-if="platformText" class="main-footer__text" v-html="platformText" />

                <nav v-if="footerMenuItems.length > 0" class="main-footer__navigation footer-navigation">
                    <ol class="footer-navigation__list">
                        <li v-for="item in footerMenuItems" :key="item.key" class="footer-navigation__item">
                            <a class="footer-navigation__link" :href="item.url">
                                {{ item.label }}
                            </a>
                        </li>
                    </ol>
                </nav>
            </div>

            <div v-if="poweredByCmnty" class="main-footer__credits credits">
                <a class="credits__link" :href="`https://cmnty.com?utm_source=platform&utm_medium=${platformName}`" target="_blank">
                    Powered by
                    <svg id="cmnty-logo" class="credits__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132 40.8">
                        <path d="M125 30.1c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2c0-1.2-1-2.2-2.2-2.2zm0 4.2c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                        <path d="M124.2 31.2h1c.2 0 .3 0 .4.1.2.1.3.3.3.5 0 .1 0 .2-.1.3l-.2.2c.1 0 .1.1.2.1 0 .1.1.1.1.3v.5c0 .1 0 .1.1.1h-.4v-.7c0-.1-.1-.2-.2-.3h-.9v.9h-.3v-2zm.9 1c.1 0 .2 0 .3-.1.1-.1.1-.2.1-.3 0-.2-.1-.3-.2-.3h-.9v.7h.7zM7.5 10.9c2.1-2.1 4.8-3.2 8-3.2 4.3 0 7.5 1.4 9.5 4.3 1.1 1.6 1.7 3.2 1.8 4.9h-5.5c-.3-1.3-.8-2.2-1.3-2.8-1-1.1-2.4-1.7-4.3-1.7s-3.5.8-4.6 2.4c-1.1 1.6-1.7 3.9-1.7 6.8s.6 5.1 1.8 6.6c1.2 1.5 2.7 2.2 4.5 2.2 1.9 0 3.3-.6 4.3-1.9.5-.7 1-1.7 1.4-3h5.4c-.5 2.8-1.7 5.2-3.6 6.9-1.9 1.8-4.4 2.7-7.4 2.7-3.7 0-6.6-1.2-8.7-3.6C5 29 3.9 25.7 3.9 21.4c0-4.5 1.2-8 3.6-10.5zm40.3-2.7h7.8v26.1h-5.1V12.4l-4.9 21.9h-5.3l-4.9-21.9v21.8h-5.1v-26h7.9l4.7 20.5 4.9-20.5zm13.5 0H67l10.4 18.2V8.2h5.1v26.1H77L66.4 15.8v18.5h-5.1V8.2zm45.2 0v4.6h-7.8v21.5h-5.5V12.8h-7.8V8.2h21.1z" />
                        <path d="M112.8 19.8l-2.4-4.2H104l6 9.1v9.6h5.3v-9.6l10.3-19.5h-5.5z" />
                    </svg>
                </a>
            </div>
        </div>
    </footer>

</template>

<script>
    export default {
        name: 'MainFooter',
        data() {
            return {
                footerMenuItems: JSON.parse(document.querySelector('meta[name="cmnty-footer-menu-items"]').content),
            };
        },
        computed: {
            platformText() {
                return this.$store.getters['configuration/getSetting']('platformFooterText');
            },
            poweredByCmnty() {
                return this.$store.getters['configuration/getSetting']('hasPoweredByCmntyFooter');
            },
            platformName() {
                return this.$store.getters['configuration/getSetting']('platformName');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .main-footer {
        background-color: $color-footer-background;
        color: $color-footer-text;
        font-size: $font-s;
        margin-top: auto;
        padding: $gutter-l;

        &__wrapper {
            display: flex;
            width: 100%;

            @include max-breakpoint(screen-xs-max) {
                flex-wrap: wrap;
            }
        }

        &__content {
            flex-grow: 1;

            @include max-breakpoint(screen-xs-max) {
                width: 100%;
            }

            @include min-breakpoint(screen-sm-min) {
                flex-direction: row;
            }
        }

        &__text {
            line-height: 1.5;
            margin-bottom: $gutter-l;
            max-width: 75ch;
            width: 100%;

            &:not(:last-child) {
                @include min-breakpoint(screen-sm-min) {
                    margin-bottom: $gutter-m;
                }
            }

            p:last-child {
                margin-bottom: 0;
            }
        }

        &__credits {
            flex-shrink: 0;
            height: 20px;
            margin: auto 0 0 auto;
            text-align: right;
            width: auto;
        }
    }

    .footer-navigation {
        display: flex;

        @include max-breakpoint(screen-xs-max) {
            margin-bottom: $gutter-l;
        }

        &__list {
            @include reset-list();
            display: flex;
            flex-direction: column;

            @include min-breakpoint(screen-sm-min) {
                flex-direction: row;
                flex-wrap: wrap;
                margin-right: $gutter-l;
            }
        }

        &__item {
            align-items: center;
            border-color: $color-footer-text;
            display: inline-flex;
            margin: 0;
            padding: 0;

            &:not(:first-child) {
                @include min-breakpoint(screen-sm-min) {
                    margin-left: $gutter-s;
                }

                &:before {
                    @include min-breakpoint(screen-sm-min) {
                        content: ' ';
                    }
                }
            }

            &:before {
                background: $color-footer-text;
                border-radius: 50%;
                display: inline-flex;
                height: 5px;
                margin-right: $gutter-s;
                width: 5px;
            }
        }

        &__link {
            color: $color-footer-text;
            font-size: inherit;
            padding: $gutter-xs 0;

            &:hover {
                color: $color-footer-text;
                text-decoration: underline;
            }
        }
    }

    .credits {
        &__link {
            align-items: center;
            border-bottom: 1px solid transparent;
            color: $color-footer-text;
            /*rtl:ignore*/
            direction: ltr;
            display: inline-flex;
            fill: $color-footer-text;

            &:hover {
                border-bottom-color: $color-footer-text;
                color: $color-footer-text;
                text-decoration: none;
            }
        }

        &__logo {
            fill: $color-footer-text;
            height: 20px;
            /*rtl:ignore*/
            margin-left: $gutter-xs;
            width: 65px;
        }
    }
</style>
