import Vue from 'vue';
import Router from 'vue-router';
import multiguard from 'vue-router-multiguard';
import VueBodyClass from 'vue-body-class';
import store from './Store';
import ChallengeRoutes from './Module/Challenge/ChallengeRoutes';
import BlogRoutes from './Module/Blog/BlogRoutes';
import QuestionnaireRoutes from './Module/Questionnaire/QuestionnaireRoutes';
import FocusRoutes, { RecorderRoutes } from './Module/Focus/FocusRoutes';
import JournalRoutes from './Module/Journal/JournalRoutes';
import WebshopRoutes from './Module/Webshop/WebshopRoutes';
import DashboardRoutes from './Module/Dashboard/DashboardRoutes';
import ForumRoutes from './Module/Forum/ForumRoutes';
import TaskBlockedByOtherTask from './Core/Authorization/Guards/TaskBlockedByOtherTask';

Vue.use(Router);

const routes = [
    ChallengeRoutes,
    BlogRoutes,
    QuestionnaireRoutes,
    FocusRoutes,
    RecorderRoutes,
    JournalRoutes,
    DashboardRoutes,
    ForumRoutes,
    WebshopRoutes,
];

const router = new Router({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
});

const customVueBodyClass = function (to, from, next) {
    const vueBodyClass = new VueBodyClass(routes);
    vueBodyClass.guard(to, next);
};

router.beforeEach(multiguard([
    TaskBlockedByOtherTask,
    customVueBodyClass,
]));

// TODO: Come up with a better solution for this
// This is used to update the task-list widget while browsing around in challenge
router.afterEach(() => {
    if (store.state.taskList) {
        store.dispatch('taskList/fetchTasks');
    }
});

router.afterEach((to, from) => {
    if (typeof ga !== 'function') {
        // No google analytics
        return;
    }

    if (!from || !from.name) {
        // Probably a full page refresh, ignore...
        return;
    }

    // eslint-disable-next-line no-undef
    ga('set', 'page', to.fullPath);
    // eslint-disable-next-line no-undef
    ga('send', 'pageview');
});

export default router;
