const ChallengeModule = () => import(/* webpackChunkName: 'challenge-module' */ './ChallengeModule.vue');
const ChallengeOverview = () => import(/* webpackChunkName: 'challenge-module' */ './ChallengeOverview/ChallengeOverview.vue');
const ChallengeView = () => import(/* webpackChunkName: 'challenge-module' */ './ChallengeView/ChallengeView.vue');
const IdeaView = () => import(/* webpackChunkName: 'challenge-module' */ './IdeaView/IdeaView.vue');
const CreateIdeaDialog = () => import(/* webpackChunkName: 'challenge-module' */ './IdeaForm/CreateIdeaDialog.vue');
const CreateIdeaPage = () => import(/* webpackChunkName: 'challenge-module' */ './IdeaForm/CreateIdeaPage.vue');
const UpdateIdeaPage = () => import(/* webpackChunkName: 'challenge-module' */ './IdeaForm/UpdateIdeaPage.vue');

export default {
    path: '/challenge',
    component: ChallengeModule,
    meta: { bodyClass: 'module-challenge' },
    children: [
        {
            path: '/',
            name: 'ChallengeOverview',
            components: {
                default: ChallengeOverview,
                form: CreateIdeaDialog,
            },
            meta: { bodyClass: 'module-challenge__challenge-overview' },
        },
        {
            path: ':slug?-:id(\\d+)',
            name: 'ChallengeView',
            components: {
                default: ChallengeView,
                form: CreateIdeaDialog,
            },
            meta: {
                bodyClass: 'module-challenge__challenge',
                tasklist: {
                    module: 'challenge',
                    itemIdParam: 'id',
                    actions: [
                        'challenge_add_idea',
                        'challenge_vote_on_idea',
                    ],
                },
            },
            props: true,
        },
        {
            path: ':challengeSlug?-:challengeId(\\d+)/ideas/:slug?-:id(\\d+)',
            name: 'IdeaView',
            components: {
                default: IdeaView,
                form: CreateIdeaDialog,
            },
            meta: { bodyClass: 'module-challenge__idea' },
            props: true,
        },
        {
            path: ':challengeSlug?-:challengeId(\\d+)/ideas/new',
            name: 'IdeaForm',
            components: {
                default: CreateIdeaPage,
            },
            meta: { bodyClass: 'module-challenge__idea-form' },
            props: true,
        },
        {
            path: ':challengeSlug?-:challengeId(\\d+)/ideas/:slug?-:id(\\d+)/edit',
            name: 'UpdateIdea',
            components: {
                default: UpdateIdeaPage,
            },
            meta: { bodyClass: 'module-challenge__idea-form module-challenge__idea-form--edit' },
            props: true,
        },
    ],
};
