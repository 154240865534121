var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "upload-v2__button" }, [
    _c("input", {
      staticClass: "upload-v2__input hidden",
      attrs: {
        id: _vm.uniqueId,
        type: "file",
        multiple: _vm.multiple,
        disabled: _vm.disabled
      },
      on: { change: _vm.addFiles }
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "upload-v2__label",
        class: { "upload-v2__label--disabled": _vm.disabled },
        attrs: { for: _vm.uniqueId, tabindex: "0" }
      },
      [
        _c("i", { staticClass: "fas fa-plus-circle" }),
        _vm._v(" "),
        _vm.disabled && _vm.disabledReason
          ? _c(
              "span",
              {
                staticClass: "upload-v2__label-msg",
                class: { "upload-v2__label-msg--disabled": _vm.disabled }
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.disabledReason) + "\n        "
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }