<template>
    <svg class="default-avatar" x="0px" y="0px" viewBox="0 0 462 462">
        <g id="bg" class="default-avatar__background" :class="`default-avatar__background--${color}`">
            <rect class="st0" width="462" height="462" />
        </g>
        <g id="shadow" class="default-avatar__shadow">
            <polygon class="st1" points="295,87 462,254 462,462 160.5,462 91.2,393.2 210.2,246.5 172.9,208.5" />
        </g>
        <g id="head" class="default-avatar__head">
            <circle class="st2" cx="231" cy="144.7" r="86.3" />
        </g>
        <g id="body" class="default-avatar__body">
            <path
                class="st2"
                d="M231,405.3c36,0,72.5,0,105.7,0c46.3-2.6,60.6-41.6,52.3-94.6c-8.2-58-34.4-97.9-78.8-94.4c-25.8,20.6-52.1,32.2-79.3,32.2c-27.1,0-53.4-11.6-79.3-32.2c-44.3-3.5-70.5,36.4-78.8,94.4c-8.2,53,6,92,52.3,94.6C158.5,405.3,195,405.3,231,405.3L231,405.3z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'DefaultAvatar',
        props: {
            color: {
                type: String,
                required: false,
                default: 'grey',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .default-avatar {
        &__background {
            fill: $color-grey-medium;

            &--grey {
                fill: $color-grey-medium;
            }

            &--primary {
                fill: var(--color-primary);
            }
        }

        &__shadow {
            opacity: .2;
        }

        &__head,
        &__body {
            fill: #fff;
        }
    }
</style>
