var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rating-indicator",
      class: { "rating-indicator--colored": _vm.colored }
    },
    [
      _vm._l(_vm.fullStars, function(index) {
        return _c("i", {
          key: "full-" + index,
          staticClass: "rating-indicator__icon fas fa-star"
        })
      }),
      _vm._v(" "),
      _vm.halfStar
        ? _c("i", {
            key: "half",
            staticClass: "rating-indicator__icon fas fa-star-half-alt"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.emptyStars, function(index) {
        return _c("i", {
          key: "empty-" + index,
          staticClass: "rating-indicator__icon fal fa-star"
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }