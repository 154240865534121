<template>
    <a
        v-if="sidebarEnabledElements.length > 0"
        class="sidebar__toggle"
        :class="{ 'sidebar__toggle--open': sidebarExpanded }"
        tabindex="0"
        @click="toggleSidebar"
    >
        <i class="sidebar__toggle-icon far fa-angle-double-left" />
    </a>
</template>

<script>
    export default {
        name: 'SidebarExpander',
        computed: {
            sidebarExpanded() {
                return this.$store.getters['configuration/getSetting']('sidebarExpanded');
            },
            sidebarElements() {
                return this.$store.getters['configuration/getElements']('sidebar');
            },
            sidebarEnabledElements() {
                return this.sidebarElements.filter((element) => element.enabled || element.enabled === undefined);
            },
        },
        methods: {
            toggleSidebar() {
                this.$store.commit('configuration/setSetting', { setting: 'sidebarExpanded', value: !this.sidebarExpanded });
            },
        },
    };
</script>

<style lang="scss">
    [dir='rtl'] {
        .sidebar {
            &__toggle {
                transform: rotate(180deg);
            }
        }
    }
</style>
