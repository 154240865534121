var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "actions-list__action liking-action" },
    [
      _vm.canLike
        ? _c(
            "a",
            {
              staticClass:
                "liking-action__like-action btn btn--text btn--action",
              class: _vm.hasLiked
                ? "liking-action__like-action--liked"
                : "liking-action__like-action--not-liked",
              attrs: {
                tabindex: "0",
                "data-cy": "actions-list-like",
                "data-jest": "button",
                "aria-label": "like action",
                href: "#"
              },
              on: {
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.toggleLike($event)
                },
                click: _vm.toggleLike
              }
            },
            [
              _c("span", {
                staticClass:
                  "liking-action__icon liking-action__icon--liked fas fa-heart"
              }),
              _vm._v(" "),
              _c("span", {
                staticClass:
                  "liking-action__icon liking-action__icon--not-liked far fa-heart"
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canViewLikes
        ? [
            _c(
              "a",
              {
                staticClass:
                  "liking-action__show-action btn btn--text btn--action",
                attrs: {
                  tabindex: "0",
                  "data-cy": "actions-list-show-likes",
                  "data-jest": "view-button",
                  href: "#"
                },
                on: {
                  click: function(e) {
                    return _vm.showModal(e)
                  }
                }
              },
              [
                !_vm.canLike
                  ? _c("i", {
                      staticClass:
                        "liking-action__show-action-icon fas fa-heart",
                      attrs: { "data-jest": "icon" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "liking-action__show-action-count" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.totalLikes) +
                        "\n            "
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "modal-component",
              {
                staticClass: "liking__modal",
                attrs: { show: _vm.isModalVisible, "data-jest": "modal" },
                on: {
                  close: function($event) {
                    _vm.isModalVisible = false
                  }
                }
              },
              [
                _c("template", { slot: "title" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("transChoice")(
                          "like.likes_amount",
                          _vm.totalLikes
                        )
                      ) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "content" },
                  [
                    _vm.totalLikes === 0
                      ? _c("div", { staticClass: "liking__no-likes" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("trans")("like.no_likes")) +
                              "\n                "
                          )
                        ])
                      : _c(
                          "transition-group",
                          {
                            staticClass: "liking__list",
                            attrs: { name: "likes-list", tag: "ul" }
                          },
                          _vm._l(_vm.likes, function(like, index) {
                            return _c(
                              "li",
                              { key: index, staticClass: "liking__list-item" },
                              [
                                _c("user-avatar", {
                                  staticClass: "liking__list-avatar",
                                  attrs: { user: like.user, linked: "" }
                                }),
                                _vm._v(" "),
                                _c("user-name", {
                                  staticClass: "liking__list-username",
                                  attrs: { user: like.user, linked: "" }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        ),
                    _vm._v(" "),
                    _vm.loading
                      ? _c("loading-spinner", {
                          staticClass: "liking__loading-spinner"
                        })
                      : _vm.likes.length < _vm.totalLikes
                      ? _c(
                          "div",
                          { staticClass: "liking__load-more-container" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn--soft",
                                attrs: { disabled: _vm.loading },
                                on: { click: _vm.loadLikes }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("trans")("action.load_more")
                                    ) +
                                    "\n                    "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "liking__close-modal btn btn--primary",
                    on: {
                      click: function($event) {
                        _vm.isModalVisible = false
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("trans")("action.close")) +
                        "\n            "
                    )
                  ]
                )
              ],
              2
            )
          ]
        : _c(
            "span",
            {
              staticClass:
                "liking-action__show-action liking-action__show-action--disabled btn btn--text btn--action"
            },
            [
              !_vm.canLike
                ? _c("i", {
                    staticClass: "liking-action__show-action-icon fas fa-heart"
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "liking-action__show-action-count" }, [
                _vm._v("\n            " + _vm._s(_vm.totalLikes) + "\n        ")
              ])
            ]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }