import 'whatwg-fetch';

export default function (input, options) {
    const request = new Request(input, {
        credentials: 'same-origin',
        ...options,
    });

    request.headers.set('X-Requested-With', 'XMLHttpRequest');

    if (request.method !== 'GET') {
        request.headers.set('Content-Type', 'application/json');

        const token = document.querySelector('meta[name="cmnty-csrf-token"]').content;
        if (token) {
            request.headers.set('X-CSRF-Token', token);
        }
    }

    return fetch(request)
        .then((response) => {
            if (response.ok) {
                return Promise.resolve({ response });
            }

            const error = new Error(`${request.method} ${request.url} - ${response.status} ${response.statusText}`);
            error.response = response;

            if (response.status === 400) {
                return response.json().then((body) => {
                    error.body = body;

                    return Promise.reject(error);
                });
            }

            return Promise.reject(error);
        });
}
