<template>
    <a
        ref="button"
        slot="hidden"
        class="actions-list__action btn btn--action btn--text"
        tabindex="0"
        data-cy="actions-list-copy-link"
        data-jest="button"
        aria-label="permalink action"
        href="#"
        @keypress.enter="$emit('copy-permalink')"
        @click="$emit('copy-permalink')"
    >
        <i class="comment__action-icon fas fa-link" />
        <b-tooltip
            :target="() => $refs.button"
            :disabled="screenWidth <= 900"
            :delay="{ show: 500, hide: 0 }"
            triggers="hover"
            data-jest="tooltip"
        >
            {{ 'action.permalink'|trans() }}
        </b-tooltip>
    </a>
</template>

<script>
    export default {
        name: 'PermalinkAction',
        computed: {
            screenWidth() {
                return this.$store.getters['configuration/getSetting']('screenWidth');
            },
        },
    };
</script>
