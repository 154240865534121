<template>
    <div class="journal-liking">
        <LikeAction
            :has-liked="hasUserLiked"
            :total-likes="totalLikes"
            :likes="likes"
            :loading="loading"
            :can-like="canLike"
            :can-view-likes="true"
            @like="like"
            @unlike="unlike"
            @load-likes="fetchLikes"
        />
    </div>
</template>

<script>
    import JournalApi from '../../JournalApi';

    export default {
        name: 'JournalLiking',
        // components: { LikeAction },
        props: {
            journalId: {
                type: Number,
                required: true,
            },
            likeTotal: {
                type: Number,
                required: true,
            },
            hasLiked: {
                type: Boolean,
                required: true,
            },
            canLike: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                pagination: {
                    page: 1,
                    total: 0,
                    limit: 15,
                },
                loading: false,
                hasUserLiked: false,
                totalLikes: 0,
                likes: [],
            };
        },

        watch: {
            likeTotal(newValue) {
                this.totalLikes = newValue || 0;
            },
            hasLiked(newValue) {
                this.hasUserLiked = newValue === true;
            },
        },
        created() {
            this.hasUserLiked = this.hasLiked === true;
            this.totalLikes = this.likeTotal ? this.likeTotal : 0;
        },

        methods: {
            async like(event) {
                event.preventDefault();
                event.stopPropagation();
                try {
                    this.totalLikes += 1;
                    this.hasUserLiked = true;
                    await JournalApi.like(this.journalId);
                } catch (error) {
                    this.hasUserLiked = false;
                    this.totalLikes -= 1;
                }
            },
            async unlike(event) {
                event.preventDefault();
                event.stopPropagation();
                try {
                    this.totalLikes -= 1;
                    this.hasUserLiked = false;
                    await JournalApi.unlike(this.journalId);
                } catch (error) {
                    this.hasUserLiked = true;
                    this.totalLikes += 1;
                }
            },
            async fetchLikes() {
                this.loading = true;

                const { body: data } = await JournalApi.fetchLikes(this.journalId, this.pagination);
                this.likes = data.data;
                this.loading = false;
            },
            resetLikes() {
                this.pagination.page = 1;

                this.$store.commit('blog/articles/resetLikes', { id: this.journal?.id });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .journal-liking {
        display: inline-block;
    }
</style>
