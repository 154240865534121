import { render, staticRenderFns } from "./ModalComponent.vue?vue&type=template&id=5c527a62&scoped=true&lang=html&"
import script from "./ModalComponent.vue?vue&type=script&lang=js&"
export * from "./ModalComponent.vue?vue&type=script&lang=js&"
import style0 from "./ModalComponent.vue?vue&type=style&index=0&id=5c527a62&lang=scss&scoped=true&"
import style1 from "./ModalComponent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c527a62",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/platform/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c527a62')) {
      api.createRecord('5c527a62', component.options)
    } else {
      api.reload('5c527a62', component.options)
    }
    module.hot.accept("./ModalComponent.vue?vue&type=template&id=5c527a62&scoped=true&lang=html&", function () {
      api.rerender('5c527a62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Core/Modals/ModalComponent.vue"
export default component.exports