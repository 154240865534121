var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "button",
      staticClass: "actions-list__action btn btn--action btn--text",
      attrs: {
        tabindex: "0",
        "data-cy": "actions-list-delete",
        "aria-label": "delete action",
        href: "#"
      },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          _vm.showDeleteModal = true
        },
        click: function($event) {
          _vm.showDeleteModal = true
        }
      }
    },
    [
      _c("i", { staticClass: "actions-list__action-icon fas fa-trash" }),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: function() {
              return _vm.$refs.button
            },
            disabled: _vm.screenWidth <= 900,
            delay: { show: 500, hide: 0 },
            triggers: "hover"
          }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm._f("trans")("action.delete")) + "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          staticClass: "delete-action__confirm-modal",
          attrs: { show: _vm.showDeleteModal, "data-jest": "modal" },
          on: {
            close: function($event) {
              _vm.showDeleteModal = false
            }
          }
        },
        [
          _c("template", { slot: "title" }, [_vm._t("title")], 2),
          _vm._v(" "),
          _c("template", { slot: "content" }, [_vm._t("content")], 2),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--text",
              attrs: { "data-jest": "cancel" },
              on: {
                click: function($event) {
                  _vm.showDeleteModal = false
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("trans")("action.cancel")) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--delete",
              attrs: {
                "data-cy": "delete-confirmation-button",
                "data-jest": "submit"
              },
              on: { click: _vm.confirmDelete }
            },
            [_vm._t("confirm-label")],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }