var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-item__breadcrumbs" }, [
    _c(
      "ul",
      { staticClass: "breadcrumbs" },
      _vm._l(_vm.crumbs, function(crumb, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "breadcrumbs__item",
            attrs: { "data-jest": "crumb" }
          },
          [
            crumb.route
              ? _c(
                  "router-link",
                  {
                    staticClass: "breadcrumbs__link",
                    attrs: {
                      to: crumb.route,
                      tag: "a",
                      "data-jest": "router-link"
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(crumb.title) +
                        "\n            "
                    )
                  ]
                )
              : crumb.href
              ? _c(
                  "a",
                  {
                    staticClass: "breadcrumbs__link",
                    attrs: { href: crumb.href, "data-jest": "link" }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(crumb.title) +
                        "\n            "
                    )
                  ]
                )
              : _c(
                  "span",
                  {
                    staticClass: "breadcrumbs__link",
                    attrs: { "data-jest": "label" }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(crumb.title) +
                        "\n            "
                    )
                  ]
                ),
            _vm._v(" "),
            _c("i", {
              staticClass: "breadcrumbs__separator fa fa-angle-double-right"
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }