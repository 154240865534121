<template>
    <div class="date-picker">
        <v-date-picker
            v-model="date"
            mode="dateTime"
            :masks="{ input: dateFormat, inputDateTime: dateTimeFormat, inputDateTime24hr: dateTimeFormat }"
            :is24hr="is24hr"
            :locale="locale"
            :timezone="timezone"
        >
            <template #default="{ inputValue, togglePopover }">
                <div class="date-picker__wrapper inline-form-control" @click="togglePopover">
                    <label class="inline-form-control__label" :class="{ 'inline-form-control__label--super': date !== '' }" :for="id">
                        <slot name="label" />
                    </label>
                    <input
                        :id="id"
                        :value="inputValue"
                        readonly
                        class="inline-form-control__input"
                    >
                </div>
            </template>
        </v-date-picker>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapGetters } from 'vuex';
    import VDatePicker from 'v-calendar';

    Vue.use(VDatePicker);

    export default {
        name: 'DatePicker',
        props: {
            initialDate: {
                type: String,
                default: null,
            },
            timezone: {
                type: String,
                default: null,
            },
            id: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                date: null,
            };
        },
        computed: {
            ...mapGetters({
                dateTimeFormat: 'configuration/getDateTimeFormat',
            }),
            dateFormat() {
                return this.$store.getters['configuration/getSetting']('dateFormat');
            },
            is24hr() {
                return this.dateTimeFormat.includes('HH');
            },
            locale() {
                return this.$moment.locale();
            },
        },
        watch: {
            date(date) {
                const formattedDateForSubmit = this.$moment(new Date(date), 'D MMMM YYYY HH:mm', this.$moment.locale()).format('YYYY-MM-DD HH:mm:ss');
                this.$emit('input', formattedDateForSubmit);
            },
        },
        mounted() {
            if (this.initialDate) {
                this.date = this.$moment(new Date(this.initialDate), 'D MMMM YYYY HH:mm', this.$moment.locale()).format('YYYY-MM-DD HH:mm:ss');
            } else {
                this.date = this.$moment(new Date(), 'D MMMM YYYY HH:mm', this.$moment.locale()).format('YYYY-MM-DD HH:mm:ss');
            }
        },
    };
</script>

<style lang="scss" scoped>

</style>
