<template>
    <svg class="default-fill-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 748 748" preserveAspectRatio="none">
        <path class="default-fill-image__path" d="M424 748L0 748 0.1 0.2 92.7 0.3z" />
        <path class="default-fill-image__path" opacity=".8" d="M363.3 0.2L323 521 93 0" />
        <path class="default-fill-image__path" opacity=".5" d="M541.6 0.3L306 748 363.3 0.2" />
        <path class="default-fill-image__path" opacity=".3" d="M748 0.2L747.7 301.3 306 748 541.6 0.3" />
        <path class="default-fill-image__path" opacity=".1" d="M748 746.1L306 748 748 301.3z" />
    </svg>
</template>

<script>
    export default {
        name: 'DefaultFillImage',
    };
</script>

<style lang="scss" scoped>
    .default-fill-image {
        opacity: .2;

        &__path {
            fill: var(--color-primary);
        }
    }
</style>
