import AddDiscussion from './AddDiscussion';
import CloseDiscussion from './CloseDiscussion';
import DraftDiscussion from './DraftDiscussion';
import EditDiscussion from './EditDiscussion';
import LikeDiscussion from './LikeDiscussion';
import MarkDiscussionsAsRead from './MarkDiscussionsAsRead';
import PinDiscussion from './PinDiscussion';
import ScheduleDiscussion from './ScheduleDiscussion';
import SetVisibilityForDiscussion from './SetVisibilityForDiscussion';
import StartDiscussionThread from './StartDiscussionThread';
import SubscribeToDiscussion from './SubscribeToDiscussion';
import ViewDiscussionLikes from './ViewDiscussionLikes';
import AllowAddDiscussion from './AllowAddDiscussion';

export default {
    'add-discussion': AddDiscussion,
    'allow-add-discussion': AllowAddDiscussion,
    'close-discussion': CloseDiscussion,
    'draft-discussion': DraftDiscussion,
    'edit-discussion': EditDiscussion,
    'like-discussion': LikeDiscussion,
    'mark-discussions-as-read': MarkDiscussionsAsRead,
    'pin-discussion': PinDiscussion,
    'schedule-discussion': ScheduleDiscussion,
    'set-visibility-for-discussion': SetVisibilityForDiscussion,
    'start-discussion-thread': StartDiscussionThread,
    'subscribe-to-discussion': SubscribeToDiscussion,
    'view-discussion-likes': ViewDiscussionLikes,
};
