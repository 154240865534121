<template>
    <footer class="upload-v2__controls">
        <span v-if="position > 0" class="upload-v2__move-left" @click="$emit('moveLeft')">
            <i class="upload-v2__move-left-icon fas fa-chevron-left" />
        </span>
        <span v-if="position < total - 1" class="upload-v2__move-right" @click="$emit('moveRight')">
            <i class="upload-v2__move-right-icon fas fa-chevron-right" />
        </span>
    </footer>
</template>

<script>
    export default {
        name: 'PositionControls',
        props: {
            position: {
                type: Number,
                required: true,
            },
            total: {
                type: Number,
                required: true,
            },
        },
    };
</script>
