var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nice-date" }, [
    _c("span", { staticClass: "date-day" }, [
      _vm._v(_vm._s(_vm.$moment(_vm.date).date()))
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "date-sub" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.$moment(_vm.date).format("MMM")) +
          "\n        "
      ),
      _c("span", { staticClass: "date-year" }, [
        _vm._v(_vm._s(_vm.$moment(_vm.date).format("YYYY")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }