<template>
    <div class="actions-list">
        <slot name="first" />
        <slot name="second" />
        <span
            v-if="!showHiddenActions && $slots.hidden && $slots.hidden.length > 0"
            class="actions-list__action actions-list__action--expander btn btn--text"
            tabindex="0"
            data-cy="actions-list-expander"
            data-jest="show-hidden"
            aria-label="action expander button"
            role="link"
            @keypress.enter="showHiddenActions = true"
            @click="showHiddenActions = true"
        >
            <i class="actions-list__action-icon fas fa-ellipsis-h" />
        </span>
        <slot v-if="showHiddenActions" name="hidden" />
        <slot name="last" />
    </div>
</template>

<script>
    import Vue from 'vue';
    import BookmarkAction from './BookmarkAction.vue';
    import DeleteAction from './DeleteAction.vue';
    import MoveAction from './MoveAction.vue';
    import DocumentsAction from './DocumentsAction.vue';
    import EditAction from './EditAction.vue';
    import RatingAction from './RatingAction.vue';
    import LikeAction from './LikeAction.vue';
    import ReplyAction from './ReplyAction.vue';
    import ReportAction from './ReportAction.vue';
    import SubscribeAction from './SubscribeAction.vue';
    import CloseAction from './CloseAction.vue';
    import PermalinkAction from './PermalinkAction.vue';

    Vue.component(BookmarkAction.name, BookmarkAction);
    Vue.component(DeleteAction.name, DeleteAction);
    Vue.component(MoveAction.name, MoveAction);
    Vue.component(DocumentsAction.name, DocumentsAction);
    Vue.component(EditAction.name, EditAction);
    Vue.component(RatingAction.name, RatingAction);
    Vue.component(LikeAction.name, LikeAction);
    Vue.component(ReplyAction.name, ReplyAction);
    Vue.component(ReportAction.name, ReportAction);
    Vue.component(SubscribeAction.name, SubscribeAction);
    Vue.component(CloseAction.name, CloseAction);
    Vue.component(PermalinkAction.name, PermalinkAction);

    export default {
        name: 'ActionsList',
        props: {
            expandHiddenItems: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                showHiddenActions: this.expandHiddenItems,
            };
        },
    };
</script>

<style lang="scss">
    .actions-list {
        align-items: stretch;
        background: $color-grey-lightest;
        border-radius: 2px;
        display: inline-flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        min-height: 42px;

        &__action {
            align-items: center;
            display: inline-flex;
        }

        &__action-label {
            @media (max-width: 500px) {
                display: none;
            }
        }
    }
</style>
