<script>
    /* istanbul ignore file */
    export default {
        name: 'AutoResizeTextarea',
        mounted() {
            this.$nextTick(() => {
                this.$el.setAttribute('style', 'height', `${this.$el.scrollHeight}px`);
            });
        },
        methods: {
            resize(event) {
                const borderBottom = window.getComputedStyle(event.target, null).getPropertyValue('border-bottom-width') || '0px';
                const borderTop = window.getComputedStyle(event.target, null).getPropertyValue('border-top-width') || '0px';

                // eslint-disable-next-line no-param-reassign
                event.target.style.height = 'auto';
                // eslint-disable-next-line no-param-reassign
                event.target.style.height = `calc(${event.target.scrollHeight}px + ${borderBottom} + ${borderTop})`;
            },
        },
        render() {
            return this.$scopedSlots.default({
                resize: this.resize,
            });
        },
    };
</script>
