<template>
    <article class="article main-content" data-cy="main-item-content">
        <div class="main-content__body">
            <div class="main-content__wrapper">
                <div
                    v-if="currentAssignment && currentAssignment.usergroups && currentAssignment.usergroups.length"
                    class="main-content__status-usergroups-wrapper"
                >
                    <div class="main-content__usergroup-labels">
                        <usergroups-list :usergroups="currentAssignment.usergroups" />
                    </div>
                </div>

                <div class="article__description main-content__description redactor-content" v-html="currentAssignment.content" />

                <!-- <footer class="main-item__metrics metrics">
                    <div class="metrics__metric metrics__date">
                        <time v-b-tooltip="$toAbsoluteDate(new Date())" class="metrics__date-label date" :datetime="new Date()" tabindex="-1">
                            {{ new Date() }}
                        </time>
                    </div>
                </footer> -->

                <div v-if="visuals && visuals.length > 0 && $store.getters['configuration/getSetting']('screenWidth') < 900" class="main-item__visuals-mobile">
                    <visuals-carousel :visuals="visuals" thumbnail-key="300x300" thumbnail2x-key="500x500" />
                </div>
            </div>

            <div v-if="visuals && visuals.length > 0 && $store.getters['configuration/getSetting']('screenWidth') >= 900" class="main-item__visuals">
                <visuals-carousel :visuals="visuals" thumbnail-key="300x300" thumbnail2x-key="500x500" />
            </div>
        </div>
    </article>
</template>

<script>
    export default {
        props: {
            currentAssignment: {
                type: Object,
                default: () => {},
            },
        },
        computed: {
            visuals() {
                return this.currentAssignment?.attachments?.attachments ?? [];
            },
        },
    };
</script>

<style scoped>
    article.article.main-content {
        width: 100%;
    }
    .main-content__body {
        justify-content: space-between;
    }
    .main-item__visuals{
        margin-right: 0px;
    }
</style>
