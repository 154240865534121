<template>
    <div v-if="enabledElements.length > 0" class="widgets-container">
        <smooth-reflow tag="div" class="widgets-container__list">
            <template v-for="(element, index) in enabledElements">
                <custom-element
                    v-if="element.component === 'custom-element'"
                    :key="index"
                    :title="element.title"
                    :content="element.content"
                />
                <component
                    :is="element.component"
                    v-else
                    :key="index"
                    v-bind="getElementProps(element)"
                    :position="configuration"
                    @disable="disableElement(element.component)"
                    @enable="enableElement(element.component)"
                />
            </template>
        </smooth-reflow>
    </div>
</template>

<script>
    import CustomElement from '../Core/CustomElement/CustomElement.vue';
    import Widgets from './WidgetsList';

    export default {
        name: 'WidgetsContainer',
        components: {
            CustomElement,
            ...Widgets,
        },
        props: {
            configuration: {
                type: String,
                required: true,
                validator(value) {
                    return ['header', 'top', 'bottom', 'footer'].indexOf(value) !== -1;
                },
            },
        },
        computed: {
            elements() {
                return this.$store.getters['configuration/getElements'](this.configuration);
            },
            enabledElements() {
                return this.elements.filter((element) => element.enabled || element.enabled === undefined);
            },
        },
        methods: {
            getElementProps(element) {
                const props = {
                    title: element.title,
                    description: element.description,
                };

                if (element.settings) {
                    props.settings = element.settings;
                }

                return props;
            },
            disableElement(elementName) {
                this.$store.commit('configuration/disableElement', { location: this.configuration, elementName });
            },
            enableElement(elementName) {
                this.$store.commit('configuration/enableElement', { location: this.configuration, elementName });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .widgets-container {
        align-self: flex-start;
        background: #fff;
        border-radius: var(--main-border-radius);
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 100%;

        &__list {
            padding: $gutter-l $gutter-m;
            width: 100%;

            @include min-breakpoint(screen-sm-min) {
                padding-left: $gutter-l;
                padding-right: $gutter-l;
            }
        }
    }
</style>
