var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "main-footer" }, [
    _c("div", { staticClass: "main-footer__wrapper" }, [
      _c("div", { staticClass: "main-footer__content" }, [
        _vm.platformText
          ? _c("div", {
              staticClass: "main-footer__text",
              domProps: { innerHTML: _vm._s(_vm.platformText) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.footerMenuItems.length > 0
          ? _c(
              "nav",
              { staticClass: "main-footer__navigation footer-navigation" },
              [
                _c(
                  "ol",
                  { staticClass: "footer-navigation__list" },
                  _vm._l(_vm.footerMenuItems, function(item) {
                    return _c(
                      "li",
                      { key: item.key, staticClass: "footer-navigation__item" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "footer-navigation__link",
                            attrs: { href: item.url }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.label) +
                                "\n                        "
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.poweredByCmnty
        ? _c("div", { staticClass: "main-footer__credits credits" }, [
            _c(
              "a",
              {
                staticClass: "credits__link",
                attrs: {
                  href:
                    "https://cmnty.com?utm_source=platform&utm_medium=" +
                    _vm.platformName,
                  target: "_blank"
                }
              },
              [
                _vm._v("\n                Powered by\n                "),
                _c(
                  "svg",
                  {
                    staticClass: "credits__logo",
                    attrs: {
                      id: "cmnty-logo",
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 132 40.8"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M125 30.1c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2c0-1.2-1-2.2-2.2-2.2zm0 4.2c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M124.2 31.2h1c.2 0 .3 0 .4.1.2.1.3.3.3.5 0 .1 0 .2-.1.3l-.2.2c.1 0 .1.1.2.1 0 .1.1.1.1.3v.5c0 .1 0 .1.1.1h-.4v-.7c0-.1-.1-.2-.2-.3h-.9v.9h-.3v-2zm.9 1c.1 0 .2 0 .3-.1.1-.1.1-.2.1-.3 0-.2-.1-.3-.2-.3h-.9v.7h.7zM7.5 10.9c2.1-2.1 4.8-3.2 8-3.2 4.3 0 7.5 1.4 9.5 4.3 1.1 1.6 1.7 3.2 1.8 4.9h-5.5c-.3-1.3-.8-2.2-1.3-2.8-1-1.1-2.4-1.7-4.3-1.7s-3.5.8-4.6 2.4c-1.1 1.6-1.7 3.9-1.7 6.8s.6 5.1 1.8 6.6c1.2 1.5 2.7 2.2 4.5 2.2 1.9 0 3.3-.6 4.3-1.9.5-.7 1-1.7 1.4-3h5.4c-.5 2.8-1.7 5.2-3.6 6.9-1.9 1.8-4.4 2.7-7.4 2.7-3.7 0-6.6-1.2-8.7-3.6C5 29 3.9 25.7 3.9 21.4c0-4.5 1.2-8 3.6-10.5zm40.3-2.7h7.8v26.1h-5.1V12.4l-4.9 21.9h-5.3l-4.9-21.9v21.8h-5.1v-26h7.9l4.7 20.5 4.9-20.5zm13.5 0H67l10.4 18.2V8.2h5.1v26.1H77L66.4 15.8v18.5h-5.1V8.2zm45.2 0v4.6h-7.8v21.5h-5.5V12.8h-7.8V8.2h21.1z"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M112.8 19.8l-2.4-4.2H104l6 9.1v9.6h5.3v-9.6l10.3-19.5h-5.5z"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }