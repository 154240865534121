<template>
    <a
        v-if="!reported"
        ref="button"
        tabindex="0"
        class="actions-list__action report-action btn btn--text btn--action"
        data-cy="actions-list-report"
        aria-label="report action"
        href="#"
        @click="showModal"
    >
        <i class="report-action__icon fas fa-flag" />
        <b-tooltip :target="() => $refs.button" :disabled="screenWidth <= 900" :delay="{ show: 500, hide: 0 }" triggers="hover">
            {{ 'action.report'|trans() }}
        </b-tooltip>
        <modal-component class="report-action__confirm-modal" :show="isModalVisible" @close="hideModal">
            <template slot="title">
                {{ 'reporting.report.title'|trans() }}
            </template>
            <template slot="content">
                <p>
                    {{ 'reporting.report.description'|trans() }}
                </p>
                <div class="custom-controls custom-controls--vertical">
                    <label class="custom-controls__label">
                        <input
                            id="inappropriate-content"
                            v-model="reason"
                            class="custom-controls__input"
                            value="inappropriate"
                            type="radio"
                        >
                        <span class="custom-controls__indicator custom-controls__indicator--radio" for="inappropriate-content" />
                        <span class="custom-controls__description" for="inappropriate-content">
                            {{ 'reporting.report.inappropriate_content'|trans() }}
                        </span>
                    </label>
                    <label class="custom-controls__label">
                        <input
                            id="spam-irrelevant"
                            v-model="reason"
                            class="custom-controls__input"
                            value="spam"
                            type="radio"
                        >
                        <span class="custom-controls__indicator custom-controls__indicator--radio" for="inappropriate-content" />
                        <span class="custom-controls__description" for="spam-irrelevant">
                            {{ 'reporting.report.spam_irrelevant_content'|trans() }}
                        </span>
                    </label>
                    <label class="custom-controls__label">
                        <input
                            id="other"
                            v-model="reason"
                            class="custom-controls__input"
                            value="other"
                            type="radio"
                        >
                        <span class="custom-controls__indicator custom-controls__indicator--radio" for="inappropriate-content" />
                        <span class="custom-controls__description" for="other">
                            {{ 'reporting.report.other'|trans() }}
                        </span>
                    </label>
                </div>
            </template>
            <button type="button" class="btn btn--secondary" @click="hideModal">
                {{ 'action.cancel'|trans() }}
            </button>
            <button type="button" class="btn btn--delete" :disabled="reason === null" @click="report">
                {{ 'reporting.report.action'|trans() }}
            </button>
        </modal-component>
    </a>
</template>

<script>
    export default {
        name: 'ReportAction',
        props: {
            reported: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isModalVisible: false,
                reason: null,
            };
        },
        computed: {
            screenWidth() {
                return this.$store.getters['configuration/getSetting']('screenWidth');
            },
        },
        methods: {
            report() {
                this.disabled = true;
                this.hideModal();
                this.$emit('report', this.reason);
            },
            showModal() {
                this.isModalVisible = true;
            },
            hideModal() {
                this.isModalVisible = false;
            },
            created() {
                this.$translator.load('frontend');
            },
        },
    };
</script>
