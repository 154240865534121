import { render, staticRenderFns } from "./LanguageSwitch.vue?vue&type=template&id=0113c56b&"
import script from "./LanguageSwitch.vue?vue&type=script&lang=js&"
export * from "./LanguageSwitch.vue?vue&type=script&lang=js&"
import style0 from "./LanguageSwitch.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/platform/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0113c56b')) {
      api.createRecord('0113c56b', component.options)
    } else {
      api.reload('0113c56b', component.options)
    }
    module.hot.accept("./LanguageSwitch.vue?vue&type=template&id=0113c56b&", function () {
      api.rerender('0113c56b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Core/Localization/LanguageSwitch.vue"
export default component.exports