<template>
    <transition name="transition-promote-solution-modal" :duration="400">
        <div v-if="show" id="promote-solution-modal" class="modal promote-solution-modal" role="dialog">
            <div class="promote-solution-modal__backdrop modal-backdrop" @click="close" />
            <div class="promote-solution-modal__dialog modal-dialog">
                <div class="promote-solution-modal__modal-content modal-content">
                    <div class="promote-solution-modal__body">
                        <span class="promote-solution-modal__close-button" @click="close">
                            <i class="fa far fa-times" />
                        </span>
                        <div class="promote-solution-modal__slider-container">
                            <input
                                v-for="(slide, index) in slides"
                                :id="`promote-solution-modal-${randomInteger}-${index}`"
                                :key="index"
                                v-model="activeSlide"
                                class="promote-solution-modal__slides-input"
                                type="radio"
                                :value="index"
                                :name="`promote-solution-modal-${randomInteger}`"
                                @change="changeSlide(activeSlide)"
                            >

                            <div ref="carousel" class="promote-solution-modal__slides">
                                <div v-for="(slide, index) in slides" :key="index" class="promote-solution-modal__slide">
                                    <div class="promote-solution-modal__content">
                                        <h4 class="promote-solution-modal__title">{{ slide.title }}</h4>
                                        <p class="promote-solution-modal__description">{{ slide.description }}</p>
                                    </div>
                                    <img class="promote-solution-modal__image" :src="slide.image" :alt="`${solutionName} image ${index}`">
                                </div>
                            </div>
                            <ul class="promote-solution-modal__slides-bullets bullets">
                                <li
                                    v-for="(slide, index) in slides"
                                    :key="index"
                                    :class="{ 'promote-solution-modal__slides-bullet--active': index === activeSlide }"
                                    class="promote-solution-modal__slides-bullet"
                                >
                                    <label :for="`promote-solution-modal-${randomInteger}-${index}`" class="promote-solution-modal__slides-bullet-anchor" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import Siema from 'siema';

    export default {
        name: 'PromoteSolutionModal',
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            slides: {
                type: Array,
                required: true,
            },
            solutionName: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                carousel: null,
                randomInteger: Math.floor(Math.random() * Math.floor(999999)),
                activeSlide: 0,
                carouselTimeout: null,
            };
        },
        watch: {
            show(boolean) {
                if (boolean) {
                    this.$root.$emit('bv::hide::tooltip');
                    document.body.classList.add('modal-open');
                    this.$nextTick(this.initCarousel);
                } else {
                    document.body.classList.remove('modal-open');
                    if (this.carousel) {
                        this.carousel.destroy();
                    }
                }
            },
        },
        mounted() {
            document.body.appendChild(this.$el);
        },
        beforeDestroy() {
            document.body.removeChild(this.$el);
            document.body.classList.remove('modal-open');
        },
        methods: {
            initCarousel() {
                this.carousel = new Siema({
                    selector: this.$refs.carousel,
                    duration: 700,
                    onChange: () => {
                        clearTimeout(this.carouselTimeout);
                        this.activeSlide = this.carousel.currentSlide;
                        this.setCarouselTimeout();
                    },
                });
            },
            changeSlide(index) {
                clearTimeout(this.carouselTimeout);
                this.carousel.goTo(index);
            },
            setCarouselTimeout() {
                this.carouselTimeout = setTimeout(() => {
                    this.carousel.next();
                }, 6000);
            },
            close() {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .promote-solution-modal {
        align-items: center;
        background: none;
        border: 0;
        box-shadow: none;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        margin: 0;
        outline: 0;
        overflow: hidden;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1050;

        &__backdrop {
            background: rgba(0, 0, 0, .5);
            z-index: -1;
        }

        &__dialog {
            align-items: center;
            display: flex;
            max-width: calc(100% - 60px);
            width: 1024px;
            margin: 0 !important; //IE11 fix

            @media (max-width: 767px) {
                margin: 30px;
            }

            // IE11 fix
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                flex-direction: column;
            }
        }

        &__modal-content {
            padding: 0;
            width: 100%;

            // IE11 fix
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                max-width: 100%;
            }
        }

        &__body {
            background: #fff;
            border-radius: 8px;
            display: flex;
            margin: 0;
            padding: 0;
            position: relative;

            @media (max-width: 767px) {
                flex-direction: column;
            }
        }

        &__slider-container {
            display: flex;
            overflow: hidden;
            padding: 32px 32px 42px;
            position: relative;
            width: 100%;

            @media (max-width: 767px) {
                padding: 8px 8px 16px;
            }
        }

        &__content {
            padding: 8px 16px 16px;
            width: 50%;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &__slides {
            position: relative;
            width: 100%;
        }

        &__slides-input {
            display: none;
        }

        &__slide {
            display: flex;
            position: relative;
            flex-direction: row;

            @media (max-width: 767px) {
                flex-direction: column-reverse;
            }
        }

        &__image {
            border: 1px solid #dadada;
            border-radius: 4px;
            height: 100%;
            width: 50%;

            @media (max-width: 767px) {
                width: 100%;
            }

            // IE11 fix
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                height: auto;
                left: 50%;
                min-height: 100%;
                min-width: 100%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 100%;
            }
        }

        &__slides-bullets {
            bottom: 8px;
            display: flex;
            left: 50%;
            margin: 0;
            padding: 0;
            position: absolute;
            transform: translateX(-50%);
            z-index: 1;
        }

        &__slides-bullet {
            background-color: #cbcbcb;
            border-radius: 50%;
            height: 10px;
            list-style-type: none;
            margin-bottom: 5px;
            margin-left: 5px;
            margin-right: 5px;
            transition: all .2s;
            width: 10px;
            z-index: 2;

            &:hover {
                transform: scale(1.2);
            }

            &:hover,
            &--active {
                background-color: #516eb1;
            }
        }

        &__slides-bullet-anchor {
            border-radius: 50%;
            cursor: pointer;
            display: block;
            height: 10px;
            width: 10px;
        }

        &__title {
            font-size: 22px;
            font-weight: 400;
            margin: 0 0 16px;
            max-width: 100%;
        }

        &__description {
            font-size: 16px;
            font-weight: 300;
            line-height: 1.5;
            margin-bottom: 0;
            max-width: 100%;
        }

        &__call-to-action {
            background-color: #eb6331;
            border-radius: 4px;
            color: #fff;
            margin-top: 32px;
            padding: 12px 18px;
            transition: all .2s ease-in-out;

            &:hover,
            &:focus,
            &:active {
                background-color: #da5727;
                box-shadow: none;
                cursor: pointer;
                text-decoration: none;
                outline: none;
            }
        }

        &__close-button {
            color: #fff;
            font-size: 24px;
            font-weight: 400;
            opacity: 1;
            position: absolute;
            right: -22px;
            text-shadow: none;
            top: -32px;
            transition: all .2s ease-in-out;

            &:hover,
            &:focus,
            &:active {
                border: 0;
                box-shadow: none;
                color: #516eb1;
                cursor: pointer;
                transform: scale(1.15);
                outline: none;
            }
        }
    }
</style>

<style lang="scss">
    .promote-solution-modal {
        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }

        &__slides {
            > div {
                height: 100%;
            }
        }
    }

    .transition-promote-solution-modal {
        &-enter-active,
        &-leave-active {
            .modal-dialog {
                transition: all .4s ease-in-out;
            }

            .modal-backdrop {
                transition: all .4s ease-in-out;
            }
        }

        &-enter,
        &-leave-to {
            .modal-dialog {
                opacity: 0;
                transform: scale(0);
            }

            .modal-backdrop {
                opacity: 0;
            }
        }
    }
</style>
