import Vue from 'vue';

const store = {
    namespaced: true,
    state() {
        return {
            activeItem: {
                item: null,
                id: null,
            },
            activeIdea: {
                id: null,
            },
        };
    },
    getters: {
        activeItem: (state) => state.activeItem,
        activeIdea: (state) => state.activeIdea,
    },
    mutations: {
        activateItem(state, { item, id }) {
            if (item !== state.activeItem.item || id !== state.activeItem.id) {
                Vue.set(state, 'activeItem', { item, id });
            }
        },
        deactivateItem(state) {
            Vue.set(state, 'activeItem', { item: null, id: null });
        },
        activateIdea(state, { id }) {
            if (id !== state.activeIdea.id) {
                Vue.set(state, 'activeIdea', { id });
            }
        },
        deactivateIdea(state) {
            Vue.set(state, 'activeIdea', { id: null });
        },
    },
};

export default store;
