<template>
    <transition name="sidebar">
        <aside v-if="enabledElements.length > 0" class="main-container__sidebar sidebar" :class="{ 'sidebar--open': expanded }">
            <smooth-reflow tag="div" class="sidebar__list">
                <template v-for="(element, index) in enabledElements">
                    <custom-element
                        v-if="element.component === 'custom-element'"
                        :key="index"
                        :title="element.title"
                        :content="element.content"
                    />
                    <component
                        :is="element.component"
                        v-else
                        :key="index"
                        v-bind="getElementProps(element)"
                        position="sidebar"
                        @disable="disableElement(element.component)"
                        @enable="enableElement(element.component)"
                    />
                </template>
            </smooth-reflow>
        </aside>
    </transition>
</template>

<script>
    import CustomElement from '../../CustomElement/CustomElement.vue';
    import Widgets from '../../../Widget/WidgetsList';

    export default {
        name: 'SidebarComponent',
        components: {
            CustomElement,
            ...Widgets,
        },
        computed: {
            elements() {
                return this.$store.getters['configuration/getElements']('sidebar');
            },
            enabledElements() {
                return this.elements.filter((element) => element.enabled || element.enabled === undefined);
            },
            expanded() {
                return this.$store.getters['configuration/getSetting']('sidebarExpanded');
            },
        },
        methods: {
            getElementProps(element) {
                const props = {
                    title: element.title,
                    description: element.description,
                };

                if (element.settings) {
                    props.settings = element.settings;
                }

                return props;
            },
            disableElement(elementName) {
                this.$store.commit('configuration/disableElement', { location: 'sidebar', elementName });
            },
            enableElement(elementName) {
                this.$store.commit('configuration/enableElement', { location: 'sidebar', elementName });
            },
        },
    };
</script>
