<template>
    <div class="user-avatar">
        <a v-if="$can('view-profile') && linked && user.id !== 0" class="user-avatar__link" :href="`/user/view_profile/${user.id}`">
            <figure class="user-avatar__avatar-figure">
                <img class="user-avatar__avatar user-avatar__avatar--linked js-object-fit" :src="user.avatar.thumbnails['150x150-cropped']" :alt="(user.name || user.display_name) + 'profile image'">
            </figure>
        </a>
        <figure v-else-if="user.id === 0" class="user-avatar__avatar-figure">
            <default-avatar class="user-avatar__avatar" />
        </figure>
        <figure v-else class="user-avatar__avatar-figure">
            <img class="user-avatar__avatar js-object-fit" :src="user.avatar.thumbnails['150x150-cropped']" :alt="(user.name || user.display_name) + 'profile image'">
        </figure>
    </div>
</template>

<script>
    export default {
        name: 'UserAvatar',
        props: {
            linked: {
                type: Boolean,
                required: false,
                default: false,
            },
            user: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .user-avatar {
        &__link {
            display: inline-block;
            height: 100%;
            width: 100%;
        }

        &__avatar {
            border-radius: var(--avatar-border-radius);
            height: 100%;
            width: 100%;

            &--linked {
                transition: all .2s ease-in-out;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        &__avatar-figure {
            background: $color-grey-lighter;
            border-radius: var(--avatar-border-radius);
            height: 100%;
            margin: 0;
            width: 100%;
        }
    }
</style>
