<template>
    <div>
        <div v-if="showIframe" v-html="iframe"></div>
        <div v-else class="iframe-consent">
            <div class="iframe-consent__container">
                <p class="iframe-consent__description">
                    {{ 'cookie_consent.iframe_overlay.message'|trans({ '%content%': $trans(`cookie_consent.iframe_overlay.type.${type}`) }) }}
                </p>

                <button class="iframe-consent__load-button btn btn-primary btn--primary" @click="load">
                    {{ 'cookie_consent.iframe_overlay.load'|trans({ '%content%': $trans(`cookie_consent.iframe_overlay.type.${type}`) }) }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IframeConsent',
        props: {
            iframe: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                showIframe: false,
            };
        },
        methods: {
            load() {
                this.showIframe = true;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .iframe-consent {
        display: block;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;

        &:before {
            content: ' ';
            display: block;
            padding-top: 56.25%;
        }

        &--sidebar {

        }

        &__container {
            align-items: center;
            background: $color-grey-light;
            bottom: 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            left: 0;
            margin-bottom: $gutter-m;
            max-width: 500px;
            padding: $gutter-m;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;

            @include min-breakpoint(screen-sm-min) {
                padding: $gutter-l;
            }
        }

        &__description {
            font-size: $font-s;
            margin-bottom: 16px;

            @include min-breakpoint(screen-sm-min) {
                font-size: $font-m;
            }
         }
    }
</style>

<style lang="scss">
    .sidebar .iframe-consent {
        &__container {
            padding: $gutter-m;
        }

        &__description {
            font-size: $font-s;
        }
    }
</style>
