<template>
    <div class="usergroup-labels">
        <i class="usergroup-labels__icon fa fa-users" />
        <ol class="usergroup-labels__list">
            <li v-for="usergroup in usergroups" :key="usergroup.id" class="usergroup-labels__item">
                {{ usergroup.name }}
            </li>
        </ol>
    </div>
</template>

<script>
    export default {
        name: 'UsergroupsList',
        props: {
            usergroups: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .usergroup-labels {
        display: inline-flex;

        &__icon {
            align-items: center;
            align-self: flex-start;
            color: #555;
            display: inline-flex;
            flex-shrink: 0;
            font-size: 12px;
            height: 24px;
            margin-right: $gutter-xs;
        }

        &__list {
            @include reset-list();
            display: inline-flex;
            flex-wrap: wrap;
        }

        &__item {
            align-self: flex-start;
            background: $color-grey-light;
            border-radius: 10px;
            color: $color-grey-darker;
            font-size: 11px;
            margin: $gutter-xxs;
            padding: $gutter-xs $gutter-s;
        }
    }
</style>
