var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nps-input" },
    _vm._l(11, function(index) {
      return _c(
        "a",
        {
          key: index,
          staticClass: "nps-input__label",
          class: { "nps-input__label--selected": _vm.rating === index - 1 },
          attrs: { tabindex: "-1" },
          on: {
            click: function($event) {
              _vm.rate(index - 1)
            }
          }
        },
        [_vm._v("\n        " + _vm._s(index - 1) + "\n    ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }