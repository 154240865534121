export const ignoreErrors = [
    'AbortError',
    'NetworkError',
    'session will not be reconnected',
    'failed with status code AudioCallEnded',
    'failed with status code MeetingEnded',
    'failed with status code AudioJoinedFromAnotherDevice',
    'Navigation cancelled',
    'NavigationDuplicated',
    'failed to get video device',
    'failed to get audio device',
    'missing TURN credentials',
    'TypeError: geannuleerd',
    'TypeError: cancelled',
    'TypeError: annulé',
    'TypeError: avbruten',
    'TypeError: cancelado',
    'TypeError: The network connection was lost',
    'TypeError: La connexion internet semble interrompue',
];

export const blacklistUrls = [
    /\/custom-style\/javascript\.js/,
    /\/admin\/js\/*\.js/,
    /\/bundles\/*\/*\.js/,
    /\/vendor\/*\/*\.js/,
    /\/build\/*\/*\.js/,
];
