var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "comment odd",
      class: {
        "comment--selected": _vm.selected,
        "dashboard-mode-enabled": _vm.dashboardMode
      },
      attrs: { id: "comment-" + _vm.comment.id, "data-cy": "comment" }
    },
    [
      _c("user-avatar", {
        staticClass: "comment__avatar-figure",
        attrs: { linked: true, user: _vm.comment.author }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "comment__container" },
        [
          _c(
            "header",
            { staticClass: "comment__header" },
            [
              _c("user-avatar", {
                staticClass: "comment__header-avatar-figure",
                attrs: { linked: true, user: _vm.comment.author }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "comment__author" }, [
                _c(
                  "div",
                  { staticClass: "comment__author-wrote" },
                  [
                    _c("user-name", {
                      staticClass: "comment__author-name",
                      attrs: {
                        rel: "author",
                        linked: true,
                        user: _vm.comment.author
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "time",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip",
                        value: _vm.$toAbsoluteDate(_vm.comment.date_add),
                        expression: "$toAbsoluteDate(comment.date_add)"
                      }
                    ],
                    staticClass: "comment__author-date date",
                    attrs: {
                      datetime: _vm.comment.date_add,
                      tabindex: "-1",
                      "data-jest": "created-date"
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("toRelativeDate")(_vm.comment.date_add)) +
                        "\n                "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.visuals && _vm.visuals.length > 0 && !_vm.showEditForm
            ? _c("visuals-carousel", {
                ref: "carousel",
                staticClass: "comment__visuals",
                attrs: {
                  visuals: _vm.visuals,
                  "thumbnail-key": "400x300",
                  "thumbnail2x-key": "800x600"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "comment__text-wrapper" },
            [
              !_vm.showEditForm && !_vm.comment.deleted
                ? _c("div", {
                    staticClass: "comment__text",
                    attrs: { "data-jest": "content" },
                    domProps: { innerHTML: _vm._s(_vm.comment.html) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.comment.deleted
                ? _c(
                    "div",
                    {
                      staticClass: "comment__text comment__text--deleted",
                      attrs: { "data-jest": "deleted" }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("trans")("comment.delete.deleted")) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showEditForm
                ? _c(
                    "comment-form",
                    {
                      ref: "editForm",
                      staticClass: "comment__edit-form",
                      attrs: {
                        enabled: _vm.showEditForm,
                        "initial-content": _vm.comment.text,
                        identifier:
                          "journal:comments:" + _vm.comment.id + ":update",
                        "mention-url": _vm.mentionUrl
                      },
                      on: { save: _vm.update }
                    },
                    [
                      _c("cmnty-uploader", {
                        ref: "uploader",
                        attrs: {
                          slot: "uploader",
                          id: "attachments",
                          "initial-files": _vm.comment.attachments.attachments,
                          name: "attachments",
                          mapping: "journal_comment_attachment",
                          "translation-domain": "uploader_frontend"
                        },
                        slot: "uploader",
                        model: {
                          value: _vm.commentAttachments,
                          callback: function($$v) {
                            _vm.commentAttachments = $$v
                          },
                          expression: "commentAttachments"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "comments-form__button btn btn--text btn--sm",
                          attrs: { slot: "button" },
                          on: {
                            click: function($event) {
                              _vm.showEditForm = false
                            }
                          },
                          slot: "button"
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("trans")("action.cancel")) +
                              "\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("template", { slot: "primary-button-label" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("trans")("action.edit")) +
                            "\n                "
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.showEditForm
            ? _c(
                "actions-list",
                { staticClass: "comment__actions-list" },
                [
                  _c("journal-comment-liking", {
                    attrs: {
                      slot: "first",
                      "journal-comment-id": _vm.comment.id,
                      "like-total": _vm.comment.likes.total,
                      "has-liked": _vm.comment.likes.has_liked,
                      "can-like": _vm.$can("can-like-comment", _vm.comment)
                    },
                    slot: "first"
                  }),
                  _vm._v(" "),
                  _vm.$can("can-edit-comment", _vm.comment)
                    ? _c("edit-action", {
                        staticClass: "comment__action--edit",
                        attrs: { slot: "first" },
                        on: {
                          edit: function($event) {
                            _vm.showEditForm = true
                          }
                        },
                        slot: "first"
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("can-delete-comment", _vm.comment)
                    ? _c(
                        "delete-action",
                        {
                          staticClass: "comment__action--delete",
                          attrs: { slot: "hidden" },
                          on: {
                            delete: function($event) {
                              return _vm.removeComment(_vm.comment.id)
                            }
                          },
                          slot: "hidden"
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("trans")("comment.delete.title")
                                ) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("trans")("comment.delete.description")
                                ) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "confirm-label" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("trans")("comment.delete.action")
                                ) +
                                "\n                "
                            )
                          ])
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("report-action", {
                    attrs: { slot: "hidden", reported: _vm.comment.reported },
                    on: { report: _vm.report },
                    slot: "hidden"
                  }),
                  _vm._v(" "),
                  _c("permalink-action", {
                    staticClass: "comment__action--permalink",
                    attrs: { slot: "hidden" },
                    on: { "copy-permalink": _vm.copyPermalink },
                    slot: "hidden"
                  }),
                  _vm._v(" "),
                  _vm.dashboardMode
                    ? _c("add-to-notes-action", {
                        attrs: { slot: "last" },
                        on: { addToNotes: _vm.addToNotes },
                        slot: "last"
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dashboardMode
                    ? _c("add-to-backroom-action", {
                        attrs: { slot: "last" },
                        on: { addToBackroom: _vm.addToBackroom },
                        slot: "last"
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }