<template>
    <label class="form-label">
        <slot />
    </label>
</template>

<script>
    export default {
        name: 'FormLabel',
    };
</script>

<style lang="scss" scoped>
    .form-label {
        color: $color-grey-dark;
        font-size: $font-s;
    }
</style>
