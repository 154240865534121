var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-v2__loading" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "upload-v2__loading-filename" }, [
      _c("span", [
        _vm._v("\n            " + _vm._s(_vm.filename) + "\n        ")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "upload-v2__progress" }, [
      _c("div", {
        staticClass: "upload-v2__progress-bar",
        style: { width: _vm.progress + "%" }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-v2__loader" }, [
      _c("div", { staticClass: "rect1" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect2" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect3" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect4" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect5" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }