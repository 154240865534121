import AuthorizationChecker from './AuthorizationChecker';
import VoterRegistry from './VoterRegistry';

export default {
    install(Vue, { store }) {
        const authorizationChecker = new AuthorizationChecker(new VoterRegistry(), store);

        // Global methods / properties.
        // Use as Vue.authorizationChecker
        // eslint-disable-next-line no-param-reassign
        Vue.authorizationChecker = authorizationChecker;

        // Instance methods / properties
        // Use withing vue components as this.$authorizationChecker
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$authorizationChecker = authorizationChecker;

        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$can = (permission, payload) => authorizationChecker.isGranted(permission, payload);
    },
};
