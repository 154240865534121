/* istanbul ignore file */
const ForumModule = () => import(/* webpackChunkName: 'forum-module' */ './ForumModule.vue');
const ForumMain = () => import(/* webpackChunkName: 'forum-module' */ './ForumMain.vue');
const CategoryView = () => import(/* webpackChunkName: 'forum-module' */ './Category/CategoryView.vue');
const BoardView = () => import(/* webpackChunkName: 'forum-module' */ './Board/BoardView.vue');
const DiscussionView = () => import(/* webpackChunkName: 'forum-module' */ './DiscussionView/DiscussionView.vue');
const CreateDiscussion = () => import(/* webpackChunkName: 'forum-module' */ './DiscussionForm/CreateDiscussion.vue');
const EditDiscussion = () => import(/* webpackChunkName: 'forum-module' */ './DiscussionForm/EditDiscussion.vue');

export default {
    path: '/discussion-board',
    component: ForumModule,
    meta: { bodyClass: 'module-forum' },
    children: [
        {
            path: '/',
            name: 'ForumMain',
            component: ForumMain,
            meta: { bodyClass: 'module-forum__overview' },
        },
        {
            path: ':slug?-:id(\\d+)',
            name: 'BoardView',
            component: BoardView,
            meta: { bodyClass: 'module-forum__board-view' },
        },
        {
            path: 'categories/:slug?-:id(\\d+)',
            name: 'CategoryView',
            component: CategoryView,
            meta: { bodyClass: 'module-forum__category-view' },
        },
        {
            path: ':boardSlug?-:boardId(\\d+)/discussions/:slug?-:id(\\d+)',
            name: 'DiscussionView',
            component: DiscussionView,
            meta: {
                bodyClass: 'module-forum__discussion-view',
                tasklist: {
                    module: 'discussion_board_discussion',
                    itemIdParam: 'id',
                    actions: [
                        'forum_add_discussion_comment',
                        'forum_read_discussion',
                    ],
                },
            },
        },
        {
            path: ':slug?-:id(\\d+)/discussions/create',
            name: 'CreateDiscussion',
            component: CreateDiscussion,
            meta: { bodyClass: 'module-forum__create-discussion' },
        },
        {
            path: ':boardSlug?-:boardId(\\d+)/discussions/:slug?-:id(\\d+)/update',
            name: 'EditDiscussion',
            component: EditDiscussion,
            meta: { bodyClass: 'module-forum__edit-discussion' },
        },
    ],
};
