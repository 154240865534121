<template>
    <component :is="tag" class="toast" :class="`toast--${type}`">
        <i v-if="type === 'info'" class="toast__icon fas fa-info" />
        <i v-else-if="type === 'success'" class="toast__icon fas fa-check" />
        <i v-else-if="type === 'warning'" class="toast__icon fas fa-exclamation-triangle" />
        <i v-else-if="type === 'error'" class="toast__icon fas fa-exclamation" />
        <i v-else-if="type === 'points-rewarded'" class="toast__icon far fa-angle-double-up" />
        <i v-else-if="type === 'badge-rewarded'" class="toast__icon fas fa-trophy-alt" />
        <i v-else class="toast__icon fas fa-info" />

        <div v-if="containsHtml" class="toast__message" v-html="message" />
        <div v-else class="toast__message">
            {{ message }}
        </div>

        <div v-if="redirectUrl != ''" class="toast__buttons-container">
            <button @click="accept" class="toast__buttons toast__buttons--accept btn btn--sm">
                Yes
            </button>
            <button @click="remove" class="toast__buttons toast__buttons--decline btn btn--sm">
                No
            </button>
        </div>

        <a
            v-if="dismissible"
            class="toast__remove"
            tabindex="0"
            data-jest="remove"
            @click="remove"
        >
            <i class="toast__remove-icon fas fa-times" />
        </a>
    </component>
</template>

<script>
    export default {
        name: 'ToastComponent',
        props: {
            dismissible: {
                type: Boolean,
                default: false,
            },
            message: {
                type: String,
                required: true,
            },
            tag: {
                type: String,
                default: 'div',
            },
            type: {
                type: String,
                default: 'notice',
            },
            containsHtml: {
                type: Boolean,
                default: false,
            },
            redirectUrl: {
                type: String,
                default: '',
            },
        },
        methods: {
            remove() {
                this.$emit('remove');
            },
            accept() {
                this.$emit('accept');
            },
        },
    };
</script>

<style lang="scss" scoped>
    $toast-error: #e25945;
    $toast-success: #43c537;
    $toast-warning: #e4c72d;
    $toast-notice: #1fa4e5;
    $toast-points-rewarded: $toast-success;
    $toast-badge-rewarded: $toast-success;

    .toast {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .3);
        font-size: $font-s;
        margin: $gutter-xs 0;
        max-width: calc(100% - 16px);
        padding: $gutter-m $gutter-m $gutter-m calc(#{$gutter-m} + #{$gutter-s});
        position: relative;
        width: 300px;

        &--success {
            border-left: 4px solid $toast-success;

            .toast {
                &__icon {
                    background: $toast-success;
                }
            }
        }

        &--notice,
        &--info {
            border-left: 4px solid $toast-notice;

            .toast {
                &__icon {
                    background: $toast-notice;
                }
            }
        }

        &--warning {
            border-left: 4px solid $toast-warning;

            .toast {
                &__icon {
                    background: $toast-warning;
                }
            }
        }

        &--error {
            border-left: 4px solid $toast-error;

            .toast {
                &__icon {
                    background: $toast-error;
                }
            }
        }

        &--points-rewarded {
            border-left: 4px solid $toast-points-rewarded;

            .toast {
                &__icon {
                    background: $toast-points-rewarded;
                }
            }
        }

        &--badge-rewarded {
            border-left: 4px solid $toast-badge-rewarded;

            .toast {
                &__icon {
                    background: $toast-badge-rewarded;
                }
            }
        }

        &--full-width {
            width: 100%;
        }

        &__buttons-container {
            margin-top: $gutter-s;
        }

        &__buttons {
            font-weight: 800;

            &--accept {
                background: #43c537;
                margin-right: $gutter-s;
                color: #fff;
            }

            &--decline {
                background: #999999;
                margin-right: $gutter-s;
                color: #fff;
            }
        }

        &__icon {
            align-items: center;
            background: #999;
            border-radius: 50%;
            color: #fff;
            display: inline-flex;
            font-size: 18px;
            height: 30px;
            justify-content: center;
            left: -2px;
            margin: 0;
            overflow: hidden;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 30px;
        }

        &__message {
            margin: 0;

            a {
                color: $color-base;
            }

            h4 {
                font-size: $font-m;
                margin-bottom: $gutter-s;
            }
        }

        &__remove {
            align-items: center;
            color: #999;
            display: inline-flex;
            height: 24px;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            transition: all .2s;
            width: 24px;

            &:hover {
                color: #000;
                text-decoration: none;
                transform: scale(1.1);
            }
        }

        .fa-check,
        .fa-info,
        .fa-exclamation,
        .fa-exclamation-triangle {
            font-size: 14px;
        }
    }
</style>
