<template>
    <button class="actions-list__action btn--text" :class="textMode ? '' : 'btn'" data-cy="actions-list-reply" @click="$emit('addToBackroom')">
        <i v-if="!textMode" class="actions-list__action-icon fas fa-note-medical" />
        <span class="actions-list__action-label">
            Add to backroom
        </span>
    </button>
</template>

<script>
    export default {
        name: 'AddToBackroomAction',
        props: {
            textMode: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
    };
</script>
