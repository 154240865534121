import fetch from '../../Core/Http/fetch';
// import entryResponse from './entryResponse';

export default class {
    static async getObjectives() {
        const url = '/journal/objectives';

        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async fetchAssignments(filter, page = 1) {
        const url = `/journal/objectives?filters=${filter.id}&page=${page}`;

        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async fetchSingleAssignment(id, lang) {
        let url = `/journal/objectives/${id}`;
        if (lang) {
            url += `?locale=${lang}`;
        }
        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async fetchParticipants(objectiveId, page) {
        const url = `/journal/objectives/${objectiveId}/participants?page=${page}`;
        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async fetchEntries(entryId, page, searchParams = '', isUserView = false, order = 'ASC', lang = null) {
        const params = isUserView ? '&show_user_preview=true' : '';
        let url = `/journal/objectives/${entryId}/entries?page=${page}${searchParams}${params}&order=${order}`;
        if (lang) {
            url += `&locale=${lang}`;
        }
        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async fetchSingleEntry(entryId) {
        // const params = isUserView ? '&show_user_preview=true' : '';
        const url = `/journal/entries/${entryId}`;
        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async createNewEntry(assignmentId, payload) {
        const url = `/journal/objectives/${assignmentId}/entry`;
        return fetch(url, { method: 'POST', body: JSON.stringify(payload) })
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async updateEntry(id, payload) {
        const url = `/journal/entries/${id}`;
        return fetch(url, { method: 'PUT', body: JSON.stringify(payload) })
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async deleteEntry(entryId, payload) {
        const url = `/journal/entries/${entryId}`;
        return fetch(url, { method: 'DELETE', body: JSON.stringify(payload) });
    }

    static async bookmark(id, payload) {
        const url = `/_journal/entries/${id}/bookmark`;

        return fetch(url, { method: 'POST', body: JSON.stringify(payload) });
    }

    static async unbookmark(id, payload) {
        const url = `/_journal/entries/${id}/unbookmark`;

        return fetch(url, { method: 'POST', body: JSON.stringify(payload) });
    }

    static async bookmarkStatus(id) {
        const url = `/_journal/entries/${id}/bookmark-status`;
        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async like(id) {
        const url = `/_journal/entries/${id}/like`;
        return fetch(url, { method: 'POST' });
    }

    static async unlike(id) {
        const url = `/_journal/entries/${id}/unlike`;
        return fetch(url, { method: 'POST' });
    }

    static async fetchLikes(id, page) {
        const url = `/_journal/entries/${id}/likes?page=${page}`;

        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async addComment(entryId, payload) {
        const url = `/journal/entries/${entryId}/comments`;
        return fetch(url, { method: 'POST', body: JSON.stringify(payload) })
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async updateComment(commentId, payload) {
        const url = `/journal/entries/comments/${commentId}`;
        return fetch(url, { method: 'PUT', body: JSON.stringify(payload) })
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async deleteComment(commentId, payload) {
        const url = `/journal/entries/comments/${commentId}`;
        return fetch(url, { method: 'DELETE', body: JSON.stringify(payload) });
    }

    static async likeComment(id) {
        const url = `/_journal/entries/comments/${id}/like`;
        return fetch(url, { method: 'POST' });
    }

    static async unlikeComment(id) {
        const url = `/_journal/entries/comments/${id}/unlike`;
        return fetch(url, { method: 'POST' });
    }

    static async fetchCommentLikes(id, page) {
        const url = `/_journal/entries/comments/${id}/likes?page=${page}`;

        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async fetchUserGroups() {
        const url = '/pulse/usergroups/list';
        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async subscribeEntries(entryId, payload) {
        const url = `/_journal/entries/${entryId}/subscribe`;

        return fetch(url, { method: 'POST', body: JSON.stringify(payload) });
    }

    static async unsubscribeEntries(entryId, payload) {
        const url = `/_journal/entries/${entryId}/unsubscribe`;

        return fetch(url, { method: 'POST', body: JSON.stringify(payload) });
    }

    static async getMyJournalForWidget() {
        const url = '/journal/widget/entries';

        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async reportComment(id, payload) {
        const url = `/journal/entries/comments/${id}/report`;

        return fetch(url, { method: 'POST', body: JSON.stringify(payload) });
    }

    static async reportEntry(entryId, payload) {
        const url = `/journal/entries/${entryId}/report`;

        return fetch(url, { method: 'POST', body: JSON.stringify(payload) });
    }
}
