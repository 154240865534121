var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "journal-entry-wrapper journal-overview" },
    [
      _vm.$can("can-create-journal") ? _c("create-new-entry") : _vm._e(),
      _vm._v(" "),
      _c(
        "smooth-reflow",
        { staticClass: "journal-overview__entries", attrs: { tag: "div" } },
        [
          _vm.isEntryLoading
            ? [
                _c(
                  "div",
                  { staticClass: "journal-overview__entries-loader" },
                  [_c("loading-spinner")],
                  1
                )
              ]
            : _vm._l(_vm.entries, function(entry, index) {
                return _c("my-journal", { key: index, attrs: { entry: entry } })
              })
        ],
        2
      ),
      _vm._v(" "),
      _vm.entries.length &&
      _vm.entriesPagination.total > _vm.entriesPagination.limit
        ? _c("pagination-component", {
            staticClass: "article-overview__pagination",
            attrs: {
              "current-page": _vm.entriesPagination.page,
              "total-rows": _vm.entriesPagination.total,
              "per-page": _vm.entriesPagination.limit
            },
            on: { change: _vm.changeEntriesPage }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }