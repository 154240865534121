var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-component",
    {
      staticClass: "front-end__confirm-modal",
      attrs: { show: _vm.isCookieModalVisible }
    },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n        " + _vm._s(_vm.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("template", { slot: "content" }, [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.content) } })
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn--text", on: { click: _vm.decline } },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("trans")("common_decline", {}, "ci_global")) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn--primary", on: { click: _vm.accept } },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("trans")("common_accept", {}, "ci_global")) +
              "\n    "
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }