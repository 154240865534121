var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "button",
      staticClass: "move-action actions-list__action btn btn--action btn--text",
      attrs: {
        tabindex: "0",
        "data-cy": "actions-list-move",
        "data-jest": "button",
        "aria-label": "move action",
        href: "#"
      },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.initTargets($event)
        },
        click: _vm.initTargets
      }
    },
    [
      _c("i", { staticClass: "actions-list__action-icon far fa-arrows" }),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: function() {
              return _vm.$refs.button
            },
            disabled: _vm.screenWidth <= 900,
            delay: { show: 500, hide: 0 },
            triggers: "hover",
            "data-jest": "tooltip"
          }
        },
        [_vm._t("tooltip")],
        2
      ),
      _vm._v(" "),
      _c(
        "modal-component",
        {
          attrs: { show: _vm.showMoveModal },
          on: {
            close: function($event) {
              _vm.showMoveModal = false
            }
          }
        },
        [
          _c("template", { slot: "title" }, [_vm._t("title")], 2),
          _vm._v(" "),
          _c(
            "template",
            { slot: "content" },
            [
              _vm._t("content"),
              _vm._v(" "),
              _c("div", { staticClass: "move-action__wrapper" }, [
                _vm.targets.length
                  ? _c(
                      "ul",
                      { staticClass: "move-action__target-list" },
                      _vm._l(_vm.targets, function(target) {
                        return _c(
                          "li",
                          {
                            key: target.id,
                            staticClass: "move-action__target"
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "move-action__target-label",
                                class: {
                                  "move-action__target-label--selected":
                                    target.id === _vm.targetId
                                },
                                attrs: { "data-jest": "target" }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.targetId,
                                      expression: "targetId"
                                    }
                                  ],
                                  staticClass: "move-action__target-input",
                                  attrs: {
                                    type: "radio",
                                    name: "move[target]"
                                  },
                                  domProps: {
                                    value: target.id,
                                    checked: _vm._q(_vm.targetId, target.id)
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.targetId = target.id
                                    }
                                  }
                                }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(target.title) +
                                    "\n                        "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", [_c("loading-spinner")], 1)
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--text",
              on: {
                click: function($event) {
                  _vm.showMoveModal = false
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("trans")("action.cancel")) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--primary",
              attrs: {
                "data-cy": "move-confirmation-button",
                disabled: !_vm.targetId,
                "data-jest": "move"
              },
              on: {
                click: function($event) {
                  return _vm.move(_vm.targetId)
                }
              }
            },
            [_vm._t("confirm-label")],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }