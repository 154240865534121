import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import AccessDeniedError from '../Http/AccessDeniedError';
import { ignoreErrors, blacklistUrls } from '../../../../.sentryConfigs';

export default {
    install(Vue, { dsn, store, locale }) {
        if (process.env.NODE_ENV === 'production') {
            const version = store.getters['platform/getPlatformVersion'];

            Sentry.init({
                dsn,
                release: `cmnty-platform@${version}`,
                integrations: [
                    new Integrations.Vue({ Vue, attachProps: true }),
                ],
                beforeSend(event, hint) {
                    if (hint.originalException instanceof AccessDeniedError) {
                        return null;
                    }

                    return event;
                },
                ignoreErrors,
                blacklistUrls,
            });

            Sentry.configureScope((scope) => {
                scope.setUser({ id: store.getters['user/getUrn'] });
                scope.setTag('platform.code', store.getters['platform/getPlatformCode']);
                scope.setTag('platform.locale', locale);
                scope.setTag('codebase', 'Frontend');
            });
        }

        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$sentryLogger = (error) => {
            if (process.env.NODE_ENV === 'production') {
                // Sentry.captureException(error);
            } else {
                console.error('Sentry Logger: ', error);
            }
        };
    },
};
