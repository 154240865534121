<template>
    <div class="journal-alert-message" @click="handleClick">
        <div v-html="content" />
    </div>
</template>

<script>
    export default {
        props: {
            content: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                message: 'this is alert message',
            };
        },
        methods: {
            handleClick() {
                this.$emit('click');
            },
        },
    };
</script>

<style>
    .journal-alert-message {
        padding: 8px 35px 8px 14px;
        margin-bottom: 20px;
        background-color: #fcf8e3;
        border: 1px solid #fbeed5;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        color: #c09853;
        width: 100%;
    }
    .journal-alert-message span {
        color: var(--color-primary);
        cursor: pointer;
    }
</style>
