<template>
    <component :is="tag">
        <slot />
    </component>
</template>

<script>
    import SmoothReflow from 'vue-smooth-reflow';

    export default {
        name: 'SmoothReflow',
        mixins: [SmoothReflow],
        props: {
            tag: {
                type: String,
                default: 'div',
            },
            options: {
                type: Object,
                default() {
                    return {
                        transition: 'height 1s ease-in-out',
                        transitionEvent: {
                            selector: 'section',
                            propertyName: 'display',
                        },
                    };
                },
            },
        },
        mounted() {
            this.$smoothReflow(this.options);
        },
    };
</script>
