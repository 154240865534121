import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import cssVars from 'css-vars-ponyfill';
import Vue from 'vue';
import Meta from 'vue-meta';
import vueCustomElement from 'vue-custom-element';
import svg4everybody from 'svg4everybody';

import VueContentPlaceholders from 'vue-content-placeholders';

import '../styles/app.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGrid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './Router';
import store from './Store';
import ActiveItemsStore from './ActiveItemsStore';

import LocalizationPlugin from './Core/Localization/LocalizationPlugin';
import UrlGeneratorPlugin from './Core/Router/UrlGeneratorPlugin';
import CanPlugin from './Core/Authorization/CanPlugin';
import SortOrderPlugin from './Core/Filter/SortOrderPlugin';
import BreadcrumbPlugin from './Core/Breadcrumbs/BreadcrumbPlugin';

import SentryPlugin from './Core/Logger/SentryPlugin';

import IframeConsent from './Core/CookieConsent/IframeConsent.vue';

import App from './App.vue';
import AccessDeniedPlugin from './Core/Http/AccessDeniedPlugin';

cssVars({
    watch: true,
});

svg4everybody();

library.add(faGrid);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.use(Meta);
Vue.use(vueCustomElement);
Vue.use(VueContentPlaceholders);
Vue.use(LocalizationPlugin, { store, locale: document.documentElement.lang });
Vue.use(UrlGeneratorPlugin, { store });
Vue.use(CanPlugin, { store });
Vue.use(SortOrderPlugin);
Vue.use(BreadcrumbPlugin, { router, store });

Vue.use(SentryPlugin, { dsn: 'https://af3bc222584046faa92daabdc521d12b@sentry.io/1497114', store, locale: document.documentElement.lang });

Vue.use(AccessDeniedPlugin, { router });

Vue.customElement('iframe-consent', IframeConsent);

store.registerModule('activeItems', ActiveItemsStore);

// eslint-disable-next-line no-new
new Vue({
    router,
    store,
    components: {
        App,
    },
    render: (h) => h(App),
}).$mount('.vue-app');
