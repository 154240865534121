<template>
    <a
        ref="button"
        class="actions-list__action btn btn--action btn--text"
        tabindex="0"
        data-cy="actions-list-delete"
        aria-label="delete action"
        href="#"
        @keypress.enter="showDeleteModal = true"
        @click="showDeleteModal = true"
    >
        <i class="actions-list__action-icon fas fa-trash" />
        <b-tooltip :target="() => $refs.button" :disabled="screenWidth <= 900" :delay="{ show: 500, hide: 0 }" triggers="hover">
            {{ 'action.delete'|trans() }}
        </b-tooltip>
        <modal-component class="delete-action__confirm-modal" :show="showDeleteModal" data-jest="modal" @close="showDeleteModal = false">
            <template slot="title">
                <slot name="title" />
            </template>
            <template slot="content">
                <slot name="content" />
            </template>
            <button class="btn btn--text" data-jest="cancel" @click="showDeleteModal = false">
                {{ 'action.cancel'|trans }}
            </button>
            <button class="btn btn--delete" data-cy="delete-confirmation-button" data-jest="submit" @click="confirmDelete">
                <slot name="confirm-label" />
            </button>
        </modal-component>
    </a>
</template>

<script>
    export default {
        name: 'DeleteAction',
        data() {
            return {
                showDeleteModal: false,
            };
        },
        computed: {
            screenWidth() {
                return this.$store.getters['configuration/getSetting']('screenWidth');
            },
        },
        methods: {
            confirmDelete() {
                this.showDeleteModal = false;
                this.$emit('delete');
            },
        },
    };
</script>
