<template>
    <div class="rating-indicator" :class="{ 'rating-indicator--colored': colored }">
        <i v-for="index in fullStars" :key="`full-${index}`" class="rating-indicator__icon fas fa-star" />
        <i v-if="halfStar" key="half" class="rating-indicator__icon fas fa-star-half-alt" />
        <i v-for="index in emptyStars" :key="`empty-${index}`" class="rating-indicator__icon fal fa-star" />
    </div>
</template>

<script>
    export default {
        name: 'RatingIndicator',
        props: {
            rating: {
                type: Number,
                required: true,
            },
            colored: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        computed: {
            fullStars() {
                return Math.floor(this.rating);
            },
            halfStar() {
                const roundRating = Math.round(this.rating / 0.5) * 0.5;

                return (this.fullStars < roundRating);
            },
            emptyStars() {
                if (this.halfStar) {
                    return 5 - this.fullStars - 1;
                }

                return 5 - this.fullStars;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .rating-indicator {
        align-items: center;
        color: #fff;
        display: inline-flex;
        justify-content: center;

        &--colored {
            color: #f3a446;
        }

        &__icon {
            font-size: $font-m;

            &:not(:first-child) {
                margin-left: $gutter-xxs;
            }
        }
    }
</style>

<style lang="scss">
    [dir='rtl'] {
        .rating-indicator {
            flex-direction: row-reverse;
        }
    }
</style>
