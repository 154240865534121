<template>
    <div class="nps-input">
        <a
            v-for="index in 11"
            :key="index"
            class="nps-input__label"
            :class="{ 'nps-input__label--selected': rating === (index - 1) }"
            tabindex="-1"
            @click="rate((index - 1))"
        >
            {{ (index - 1) }}
        </a>
    </div>
</template>

<script>
    export default {
        name: 'NpsInput',
        props: {
            rated: {
                type: Boolean,
                required: true,
            },
            rating: {
                required: true,
                validator: (prop) => typeof prop === 'number' || prop === null,
            },
        },
        methods: {
            rate(rating) {
                if (this.rated) {
                    return;
                }

                this.$emit('rate', rating);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .nps-input {
        margin: 0 -#{$gutter-xxs} $gutter-s;
        width: calc(100% + 2 * #{$gutter-xxs});

        &__label {
            align-items: center;
            background: $color-grey-lighter;
            color: $color-grey-darkest;
            display: inline-flex;
            height: 50px;
            justify-content: center;
            margin: 0 $gutter-xxs;
            width: calc(100% / 11 - (2 * #{$gutter-xxs}));

            &:hover,
            &--selected {
                background: var(--color-primary);
                color: #fff;
                cursor: pointer;
                text-decoration: none;
            }

            &--selected {
                animation: selectRating .4s ease-in-out;
            }
        }
    }
</style>
