function filterVisuals(attachments) {
    if (attachments) {
        return attachments.filter((attachment) => ['image', 'video'].includes(attachment.type));
    }

    return [];
}

function filterDocuments(attachments) {
    if (attachments) {
        return attachments.filter((attachment) => attachment.type === 'document');
    }

    return [];
}

export { filterVisuals, filterDocuments };
