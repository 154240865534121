var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "journal-liking" },
    [
      _c("LikeAction", {
        attrs: {
          "has-liked": _vm.hasUserLiked,
          "total-likes": _vm.totalLikes,
          likes: _vm.likes,
          loading: _vm.loading,
          "can-like": _vm.canLike,
          "can-view-likes": true
        },
        on: { like: _vm.like, unlike: _vm.unlike, "load-likes": _vm.fetchLikes }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }