<template>
    <a v-if="$can('view-profile') && linked && user.id !== 0" class="user-name user-name--linked" :href="`/user/view_profile/${user.id}`">
        {{ user.name || user.display_name }}
        <i v-if="showCrownIcon" class="fa-solid fa-crown" />
        <i v-if="showEyeIcon" class="fa-solid fa-eye" />
    </a>
    <span v-else class="user-name">{{ user.name || user.display_name }}
        <i v-if="showCrownIcon" class="fa-solid fa-crown" />
        <i v-if="showEyeIcon" class="fa-solid fa-crown" />
    </span>
</template>

<script>
    import { MANAGERS, OBSERVER } from './Roles';

    export default {
        name: 'UserName',
        props: {
            linked: {
                type: Boolean,
                required: false,
                default: false,
            },
            user: {
                type: Object,
                required: true,
            },
            showRoleIcon: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                OBSERVER,
                MANAGERS,
            };
        },
        computed: {
            showCrownIcon() {
                if (!this.showRoleIcon) {
                    return false;
                }
                if (this.user && this.user.role && this.user.role.type && this.MANAGERS.includes(this.user.role.type)) {
                    return true;
                }
                return false;
            },
            showEyeIcon() {
                if (!this.showRoleIcon) {
                    return false;
                }
                if (this.user && this.user.role && this.user.role.type && this.OBSERVER.includes(this.user.role.type)) {
                    return true;
                }
                return false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .user-name {
        &--linked {
            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>

<style>
    :root {
        --username-link-color: #444;
    }
</style>
