var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "status-messages" },
    _vm._l(_vm.messages, function(message) {
      return _c(
        "div",
        { key: message.id, staticClass: "status-messages__message" },
        [
          _c("div", {
            staticClass: "status-messages__message-content",
            domProps: { innerHTML: _vm._s(message.content) }
          })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }