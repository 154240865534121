var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navigation-wrapper",
      class: "navigation-wrapper--" + _vm.platformLayout
    },
    [
      _vm.$store.getters["configuration/getSetting"]("screenWidth") >= 900
        ? _c("main-navigation", {
            attrs: {
              type: _vm.platformLayout,
              items: _vm.mainMenuItems,
              "user-items": _vm.userMenuItems
            }
          })
        : _c("mobile-navigation", {
            attrs: { items: _vm.mainMenuItems, "user-items": _vm.userMenuItems }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }