var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination" }, [
    _c(
      "a",
      {
        staticClass: "pagination__link pagination__link--previous",
        class: { "pagination__link--disabled": _vm.currentPage <= 1 },
        attrs: { "aria-label": "pagination previous", href: "#" },
        on: {
          click: function($event) {
            return _vm.goToPage(_vm.currentPage - 1)
          }
        }
      },
      [_c("i", { staticClass: "pagination__link-icon far fa-angle-left" })]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pagination__pages" },
      [
        _vm.showFirstDots
          ? [
              _c(
                "a",
                {
                  staticClass: "pagination__link pagination__link--page",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.goToPage(1)
                    }
                  }
                },
                [_vm._v("\n                1\n            ")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "pagination__ellipsis" }, [
                _vm._v("\n                ...\n            ")
              ])
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.pageList, function(page) {
          return [
            _c(
              "a",
              {
                key: page.number,
                staticClass: "pagination__link pagination__link--page",
                class: {
                  "pagination__link--active": _vm.currentPage === page.number
                },
                attrs: {
                  "aria-label": "paginate to page" + page.number,
                  href: "#"
                },
                on: {
                  click: function($event) {
                    return _vm.goToPage(page.number)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " + _vm._s(page.number) + "\n            "
                )
              ]
            )
          ]
        }),
        _vm._v(" "),
        _vm.showLastDots
          ? [
              _c("span", { staticClass: "pagination__ellipsis" }, [
                _vm._v("\n                ...\n            ")
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "pagination__link pagination__link--page",
                  attrs: {
                    "aria-label": "Go to page" + _vm.numberOfPages,
                    href: "#"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goToPage(_vm.numberOfPages)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.numberOfPages) +
                      "\n            "
                  )
                ]
              )
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "pagination__link pagination__link--next",
        class: {
          "pagination__link--disabled": _vm.currentPage >= _vm.numberOfPages
        },
        attrs: { "aria-label": "pagination next", href: "#" },
        on: {
          click: function($event) {
            return _vm.goToPage(_vm.currentPage + 1)
          }
        }
      },
      [_c("i", { staticClass: "pagination__link-icon far fa-angle-right" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }