var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "article main-content",
      attrs: { "data-cy": "main-item-content" }
    },
    [
      _c("div", { staticClass: "main-content__body" }, [
        _c("div", { staticClass: "main-content__wrapper" }, [
          _vm.currentAssignment &&
          _vm.currentAssignment.usergroups &&
          _vm.currentAssignment.usergroups.length
            ? _c(
                "div",
                { staticClass: "main-content__status-usergroups-wrapper" },
                [
                  _c(
                    "div",
                    { staticClass: "main-content__usergroup-labels" },
                    [
                      _c("usergroups-list", {
                        attrs: { usergroups: _vm.currentAssignment.usergroups }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "article__description main-content__description redactor-content",
            domProps: { innerHTML: _vm._s(_vm.currentAssignment.content) }
          }),
          _vm._v(" "),
          _vm.visuals &&
          _vm.visuals.length > 0 &&
          _vm.$store.getters["configuration/getSetting"]("screenWidth") < 900
            ? _c(
                "div",
                { staticClass: "main-item__visuals-mobile" },
                [
                  _c("visuals-carousel", {
                    attrs: {
                      visuals: _vm.visuals,
                      "thumbnail-key": "300x300",
                      "thumbnail2x-key": "500x500"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.visuals &&
        _vm.visuals.length > 0 &&
        _vm.$store.getters["configuration/getSetting"]("screenWidth") >= 900
          ? _c(
              "div",
              { staticClass: "main-item__visuals" },
              [
                _c("visuals-carousel", {
                  attrs: {
                    visuals: _vm.visuals,
                    "thumbnail-key": "300x300",
                    "thumbnail2x-key": "500x500"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }