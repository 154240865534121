<template lang="html">
    <section class="toasts">
        <transition-group tag="ul" class="toasts__list" name="toasts">
            <toast-component
                v-for="toast in toasts"
                :key="toast.id"
                class="toasts__toast"
                :dismissible="true"
                :redirect-url="toast.redirectUrl"
                :message="toast.message"
                tag="li"
                :type="toast.type"
                :contains-html="toast.containsHtml"
                @remove="removeToast(toast)"
                @accept="redirect(toast)"
            />
        </transition-group>
    </section>
</template>

<script>
    import Vue from 'vue';
    import store from '../../Store';
    import ToastsPlugin from './ToastsPlugin';
    import ToastComponent from './ToastComponent.vue';

    Vue.use(ToastsPlugin, { store });

    export default {
        name: 'ToastsContainer',
        components: {
            ToastComponent,
        },
        computed: {
            toasts() {
                return this.$store.getters['toasts/toasts'];
            },
        },
        mounted() {
            document.body.appendChild(this.$el);
        },
        methods: {
            removeToast(toast) {
                this.$store.dispatch('toasts/removeToast', toast);
            },
            redirect(toast) {
                window.location.href = toast.redirectUrl;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .toasts {
        bottom: 0;
        margin: 0;
        padding: 16px;
        position: fixed;
        right: 0;
        z-index: 1051; // 1 higher than modals from Bootstrap styling

        &__list {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: -#{$gutter-xs} 0;
            padding: 0;
        }
    }
</style>

<style lang="scss">
    .toasts {
        &-enter-active,
        &-leave-active {
            transition: all .7s ease-in-out;
        }

        &-enter-to,
        &-leave {
            max-height: 100px;
        }

        &-enter,
        &-leave-to {
            margin-bottom: 0;
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            padding-bottom: 0;
            padding-top: 0;
            transform: translateY(50px);
        }
    }
</style>
