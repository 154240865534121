<template>
    <header class="main-header">
        <div class="main-header__banner">
            <a class="main-header__logo" :href="pageOnLogin" aria-label="main header logo" />
        </div>
    </header>
</template>

<script>
    export default {
        name: 'MainHeader',
        data() {
            return {
                pageOnLogin: document.querySelector('meta[name="cmnty-page-on-login"]').content,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .main-header {
        flex-shrink: 0;
        position: relative;

        @include min-breakpoint(screen-sm-min) {
            margin-bottom: $gutter-l;
        }

        &__banner {
            background: no-repeat center center;
            background-color: var(--color-header);
            background-image: var(--image-header);
            background-position: center center;
            background-size: cover;
            height: 100px;
            margin-bottom: 0;
            width: 100%;
            display: flex;
            justify-content: var(--image-logo-position);
            align-items: center;

            @include min-breakpoint(screen-sm-min) {
                height: 150px;
            }
        }

        &__logo {
            background: no-repeat left center;
            background-image: var(--image-logo);
            background-size: contain;
            display: var(--logo-display-header);
            height: 110px;
            margin: 0 10px 0 10px;
            width: 200px;

            @include max-breakpoint(screen-xs-max) {
                background-position: center center;
                height: 60px;
                left: 50%;
                margin: 0 10px 0 10px;
                top: 50%;
                width: 150px;
            }
        }
    }
</style>
