const BlogModule = () => import(/* webpackChunkName: 'blog-module' */ './BlogModule.vue');
const ArticleOverview = () => import(/* webpackChunkName: 'blog-module' */ './ArticleOverview/ArticleOverview.vue');
const ArticleView = () => import(/* webpackChunkName: 'blog-module' */ './ArticleView/ArticleView.vue');

export default {
    path: '/blog',
    component: BlogModule,
    meta: { bodyClass: 'module-blog' },
    children: [
        {
            path: '',
            name: 'ArticleOverview',
            component: ArticleOverview,
            meta: { bodyClass: 'module-blog__article-overview' },
            children: [
                {
                    path: 'categories/:slug?-:id(\\d+)',
                    component: ArticleOverview,
                    alias: 'categories',
                },
            ],
        },
        {
            path: ':slug?-:id(\\d+)',
            name: 'ArticleView',
            component: ArticleView,
            meta: {
                bodyClass: 'module-blog__article',
                tasklist: {
                    module: 'blog',
                    itemIdParam: 'id',
                    actions: [
                        'blog_add_comment',
                        'blog_read_article',
                    ],
                },
            },
            props: true,
        },
    ],
};
