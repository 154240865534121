<template>
    <div class="actions-list__action liking-action">
        <a
            v-if="canLike"
            class="liking-action__like-action btn btn--text btn--action"
            :class="hasLiked ? 'liking-action__like-action--liked' : 'liking-action__like-action--not-liked'"
            tabindex="0"
            data-cy="actions-list-like"
            data-jest="button"
            aria-label="like action"
            href="#"
            @keypress.enter="toggleLike"
            @click="toggleLike"
        >
            <span class="liking-action__icon liking-action__icon--liked fas fa-heart" />
            <span class="liking-action__icon liking-action__icon--not-liked far fa-heart" />
        </a>
        <template v-if="canViewLikes">
            <a
                class="liking-action__show-action btn btn--text btn--action"
                tabindex="0"
                data-cy="actions-list-show-likes"
                data-jest="view-button"
                href="#"
                @click="(e) => showModal(e)"
            >
                <i v-if="!canLike" class="liking-action__show-action-icon fas fa-heart" data-jest="icon" />
                <span class="liking-action__show-action-count">
                    {{ totalLikes }}
                </span>
            </a>

            <modal-component :show="isModalVisible" class="liking__modal" data-jest="modal" @close="isModalVisible = false">
                <template slot="title">
                    {{ 'like.likes_amount'|transChoice(totalLikes) }}
                </template>
                <template slot="content">
                    <div v-if="totalLikes === 0" class="liking__no-likes">
                        {{ 'like.no_likes'|trans }}
                    </div>
                    <transition-group v-else name="likes-list" class="liking__list" tag="ul">
                        <li v-for="(like, index) in likes" :key="index" class="liking__list-item">
                            <user-avatar class="liking__list-avatar" :user="like.user" linked />
                            <user-name class="liking__list-username" :user="like.user" linked />
                        </li>
                    </transition-group>

                    <loading-spinner v-if="loading" class="liking__loading-spinner" />

                    <div v-else-if="likes.length < totalLikes" class="liking__load-more-container">
                        <button class="btn btn--soft" :disabled="loading" @click="loadLikes">
                            {{ 'action.load_more'|trans }}
                        </button>
                    </div>
                </template>
                <button class="liking__close-modal btn btn--primary" @click="isModalVisible = false">
                    {{ 'action.close'|trans }}
                </button>
            </modal-component>
        </template>
        <span v-else class="liking-action__show-action liking-action__show-action--disabled btn btn--text btn--action">
            <i v-if="!canLike" class="liking-action__show-action-icon fas fa-heart" />
            <span class="liking-action__show-action-count">
                {{ totalLikes }}
            </span>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'LikeAction',
        props: {
            canLike: {
                type: Boolean,
                default: false,
            },
            canViewLikes: {
                type: Boolean,
                default: false,
            },
            hasLiked: {
                type: Boolean,
                default: false,
                required: true,
            },
            totalLikes: {
                type: Number,
                default: 0,
            },
            likes: {
                type: Array,
                default() {
                    return [];
                },
            },
            loading: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                isModalVisible: false,
            };
        },
        methods: {
            toggleLike(event) {
                if (!this.canLike) {
                    return;
                }
                let action = 'like';
                if (this.hasLiked) {
                    action = 'unlike';
                }

                this.$emit(action, event);
            },
            showModal(e) {
                e.preventDefault();
                e.stopPropagation();
                if (this.likes.length === 0) {
                    this.loadLikes();
                }
                this.isModalVisible = true;
            },
            loadLikes() {
                this.$emit('load-likes');
            },
        },
    };
</script>

<style lang="scss" scoped>
    $like-color: #e25945;

    .liking-action {
        display: inline-flex;
        min-height: 42px;

        &:first-child {
            margin-left: $gutter-s;
        }

        &__like-action {
            align-items: center;
            color: $like-color;
            display: inline-flex;
            font-size: 18px;
            height: 100%;
            min-width: 0;

            &--liked {
                .liking-action {
                    &__icon {
                        &--liked {
                            animation: liking-pop-in .8s;
                        }

                        &--not-liked {
                            animation: liking-pop-out .8s;
                            display: none;
                        }
                    }
                }
            }

            &--not-liked {
                .liking-action {
                    &__icon {
                        &--liked {
                            animation: liking-pop-out .8s;
                            display: none;
                        }

                        &--not-liked {
                            animation: liking-pop-in .8s;
                        }
                    }
                }
            }
        }

        &__icon {
            margin: 0;
        }

        &__show-action {
            align-items: center;
            display: inline-flex;
            height: 100%;
            justify-content: center;
            min-width: 24px;

            &--disabled {
                pointer-events: none;
            }
        }

        /deep/ .liking-action {
            &__like-action,
            &__show-action {
                padding-right: $gutter-xs;
                padding-left: $gutter-xs;
            }

            &__show-action {
                margin-right: $gutter-xs;
            }
        }
    }

    .liking {
        &__list {
            display: flex;
            flex-direction: column;
            list-style: none;
            margin-bottom: 0;
            padding: 0 0 $gutter-l;
        }

        &__list-item {
            align-items: center;
            border-bottom: 1px solid #ededed;
            display: flex;
            min-height: 53px;
            padding: 8px 0;
        }

        &__list-avatar {
            flex-shrink: 0;
            height: 36px;
            margin: 0 0 0 $gutter-s;
            width: 36px;
        }

        &__list-username {
            color: #777;
            flex-grow: 1;
            margin-left: $gutter-m;
            text-decoration: none;
        }

        &__loading-spinner {
            margin: $gutter-m auto;
        }

        &__load-more-container {
            display: flex;
            justify-content: center;
        }
    }

    @keyframes liking-hover-pulse {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.15);
        }
    }

    @keyframes liking-pop-in {
        0% {
            display: none;
        }

        39% {
            display: none;
        }

        40% {
            display: inline-block;
            transform: scale(0);
        }

        70% {
            display: inline-block;
            transform: scale(1.4);
        }

        100% {
            display: inline-block;
            transform: scale(1);
        }
    }

    @keyframes liking-pop-out {
        0% {
            transform: scale(1);
        }

        39% {
            transform: scale(1.4);
        }

        40% {
            display: none;
            transform: scale(0);
        }

        100% {
            display: none;
        }
    }

    .likes-list {
        &-enter-active,
        &-leave-active {
            overflow: hidden;
            transition: all 1s;
        }

        &-enter,
        &-leave-to {
            height: 53px;
            opacity: 0;
        }

        &-enter {
            transform: translateY(30px);
        }

        &-leave-to {
            height: 0;
        }
    }
</style>
