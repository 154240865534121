<template>
    <article :id="`comment-${comment.id}`" class="comment odd" :class="{ 'comment--selected': selected, 'dashboard-mode-enabled': dashboardMode }" data-cy="comment">
        <user-avatar class="comment__avatar-figure" :linked="true" :user="comment.author" />
        <div class="comment__container">
            <header class="comment__header">
                <user-avatar class="comment__header-avatar-figure" :linked="true" :user="comment.author" />
                <div class="comment__author">
                    <div class="comment__author-wrote">
                        <user-name class="comment__author-name" rel="author" :linked="true" :user="comment.author" />
                    </div>
                    <time
                        v-b-tooltip="$toAbsoluteDate(comment.date_add)"
                        class="comment__author-date date"
                        :datetime="comment.date_add"
                        tabindex="-1"
                        data-jest="created-date"
                    >
                        {{ comment.date_add|toRelativeDate }}
                    </time>
                </div>
            </header>
            <visuals-carousel
                v-if="visuals && visuals.length > 0 && !showEditForm"
                ref="carousel"
                :visuals="visuals"
                thumbnail-key="400x300"
                thumbnail2x-key="800x600"
                class="comment__visuals"
            />
            <div class="comment__text-wrapper">
                <div v-if="!showEditForm && !comment.deleted" class="comment__text" data-jest="content" v-html="comment.html" />

                <div v-if="comment.deleted" class="comment__text comment__text--deleted" data-jest="deleted">
                    {{ 'comment.delete.deleted'|trans }}
                </div>

                <comment-form
                    v-if="showEditForm"
                    ref="editForm"
                    :enabled="showEditForm"
                    :initial-content="comment.text"
                    :identifier="`journal:comments:${comment.id}:update`"
                    :mention-url="mentionUrl"
                    class="comment__edit-form"
                    @save="update"
                >
                    <cmnty-uploader
                        id="attachments"
                        slot="uploader"
                        ref="uploader"
                        v-model="commentAttachments"
                        :initial-files="comment.attachments.attachments"
                        name="attachments"
                        mapping="journal_comment_attachment"
                        translation-domain="uploader_frontend"
                    />
                    <button slot="button" class="comments-form__button btn btn--text btn--sm" @click="showEditForm = false">
                        {{ 'action.cancel'|trans }}
                    </button>
                    <template slot="primary-button-label">
                        {{ 'action.edit'|trans }}
                    </template>
                </comment-form>
            </div>
            <actions-list v-if="!showEditForm" class="comment__actions-list">
                <journal-comment-liking
                    slot="first"
                    :journal-comment-id="comment.id"
                    :like-total="comment.likes.total"
                    :has-liked="comment.likes.has_liked"
                    :can-like="$can('can-like-comment', comment)"
                />
                <edit-action
                    v-if="$can('can-edit-comment', comment)"
                    slot="first"
                    class="comment__action--edit"
                    @edit="showEditForm = true"
                />
                <delete-action
                    v-if="$can('can-delete-comment', comment)"
                    slot="hidden"
                    class="comment__action--delete"
                    @delete="removeComment(comment.id)"
                >
                    <template slot="title">
                        {{ 'comment.delete.title'|trans() }}
                    </template>
                    <template slot="content">
                        {{ 'comment.delete.description'|trans() }}
                    </template>
                    <template slot="confirm-label">
                        {{ 'comment.delete.action'|trans() }}
                    </template>
                </delete-action>
                <report-action
                    slot="hidden"
                    :reported="comment.reported"
                    @report="report"
                />
                <permalink-action
                    slot="hidden"
                    class="comment__action--permalink"
                    @copy-permalink="copyPermalink"
                />
                <add-to-notes-action v-if="dashboardMode" slot="last" @addToNotes="addToNotes" />
                <add-to-backroom-action v-if="dashboardMode" slot="last" @addToBackroom="addToBackroom" />
            </actions-list>
        </div>
    </article>
</template>

<script>
    import Vue from 'vue';
    import { mapActions } from 'vuex';
    import VueClipboard from 'vue-clipboard2';
    import VueScrollTo from 'vue-scrollto';
    import JournalCommentLiking from './JournalCommentLiking.vue';
    import AccessDeniedError from '../../../../Core/Http/AccessDeniedError';
    import { filterVisuals } from '../../../../Core/Attachments/AttachmentsFilter';
    import CmntyUploader from '../../../../Core/Attachments/Uploader/CmntyUploader.vue';
    import AddToNotesAction from '../../../../Core/Actions/AddToNotesAction.vue';
    import AddToBackroomAction from '../../../../Core/Actions/AddToBackroomAction.vue';

    Vue.use(VueClipboard);

    const CommentForm = () => import(/* webpackChunkName: 'comment-form' */ '../../../../Core/Comments/CommentForm.vue');

    export default {
        name: 'ArticleComments',
        components: {
            AddToNotesAction,
            AddToBackroomAction,
            JournalCommentLiking,
            CommentForm,
            CmntyUploader,
        },
        props: {
            comment: {
                type: Object,
                required: true,
            },
            entryId: {
                type: Number,
                required: true,
            },
            objectiveId: {
                type: Number,
                required: true,
            },
            dashboardMode: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                commentsFormIsEnabled: true,
                sortOrder: this.$sortOrderStorage.getSortOrder('threads', { identifier: 'activity', label: 'sort.activity' }),
                sortOrderOptions: [
                    { identifier: 'activity', label: 'sort.activity' },
                    { identifier: 'new', label: 'sort.new' },
                    { identifier: 'old', label: 'sort.old' },
                ],
                showEditForm: false,
                commentAttachments: [],
            };
        },
        computed: {
            visuals() {
                if (this.comment.deleted) {
                    return [];
                }

                return filterVisuals(this.comment.attachments.attachments);
            },
            threads() {
                return this.$store.getters['blog/articleComments/getThreads'];
            },
            pagination() {
                return this.$store.getters['blog/articleComments/getPagination'];
            },
            totalComments() {
                return this.comment.comment_count;
            },
            isLoggedIn() {
                return this.$store.getters['user/isLoggedIn'];
            },
            mentionUrl() {
                return '';
            },
            likes() {
                return this.comment.likes;
            },
            selected() {
                return this.$store.getters['journal/getSelectedComment'] === this.comment.id;
            },
        },
        mounted() {
            if (this.selected) {
                this.scrollTimeout = setTimeout(() => {
                    VueScrollTo.scrollTo(this.$el, 700, {
                        offset: -100,
                    });
                }, 500);
            }
        },
        methods: {
            ...mapActions('journal', ['updateComment', 'deleteComment']),
            async update(text) {
                const payload = {
                    text,
                    attachments: this.commentAttachments,
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.updateComment({ entryId: this.entryId, commentId: this.comment.id, payload });
                this.showEditForm = false;
                this.$toaster.success(this.$trans('journal.journal_entry.comments.comment_edit_success', {}, 'journal_frontend'), true);
            },
            async removeComment(commentId) {
                const payload = {
                    _token: this.$store.getters['user/getCSRFToken'],
                };
                await this.deleteComment({ entryId: this.entryId, commentId, payload });
                this.$toaster.success(
                    this.$trans('journal.journal_entry.comments.comment_delete_success', {}, 'journal_frontend'),
                    true,
                );
            },
            async report(reason) {
                try {
                    await this.$store.dispatch('journal/reportComment', { entryId: this.entryId, commentId: this.comment.id, reason });

                    this.$toaster.success(this.$trans('reporting.report.event'), true);
                } catch (error) {
                    if (error instanceof AccessDeniedError) {
                        throw error;
                    }

                    this.$toaster.error(this.$trans('reporting.report.error'), true);
                }
            },
            async copyPermalink() {
                // const permalink = this.$url('cmnty_task_list_journal_objectives_view', {
                //     id: this.objectiveId,
                //     entry_id: this.entryId,
                //     comment_id: this.comment.id,
                // });

                const permalink = `${document.location.origin}/journal/show/${this.objectiveId}?entry_id=${this.entryId}&comment=${this.comment.id}`;
                await this.$copyText(permalink);

                this.$toaster.success(this.$trans('comments.permalink_copied', {}, 'frontend'), true);
            },
            addToNotes() {
                this.$emit('add-to-notes', this.comment);
            },
            addToBackroom() {
                this.$emit('add-to-backroom', this.comment);
            },
        },
    };
</script>

<style scoped>
 li {
    border-top: 1px solid var(--color-primary);
}

.comment {
    padding: 10px 0.694444%;
}
.comment.odd {
    background: #fff;
}
.comment {
    float: left;
    width: 98.611111111111%;
    padding: 20px 0.694444444444%;
    background: #F5F5F5;
    list-style: none;
    border-bottom: 1px solid #dadada;
}
figure.avatar {
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 24px;
    widows: 24px;
}
.avatar-shape {
    border-radius: var(--avatar-border-radius) !important;
    overflow: hidden;
}
.journal-avatar-figure {
    flex-shrink: 0;
    height: 60px;
    margin-right: 16px;
    width: 60px;
}

</style>
<style lang="scss" scoped>
    .comment {
        display: flex;
        position: relative;
        z-index: 0;

        &:not(:first-child) {
            border-top: 1px solid $color-grey-light;
        }

        &--selected {
            &:before {
                background: var(--color-primary-tint-85);
                border-radius: $main-radius;
                content: ' ';
                height: calc(100% + #{$gutter-m});
                left: -#{$gutter-m};
                top: -#{$gutter-m};
                position: absolute;
                width: calc(100% + 2 * #{$gutter-m});
                z-index: -1;

                @include max-breakpoint(screen-xs-max) {
                    height: calc(100% + #{$gutter-s});
                    left: -#{$gutter-s};
                    top: -#{$gutter-s};
                    width: calc(100% + 2 * #{$gutter-s});
                }
            }
        }

        &__controls {
            display: inline-flex;
            position: absolute;
            right: 0;
            transition: opacity .2s;
        }

        &__avatar-figure {
            flex-shrink: 0;
            height: 50px;
            margin-right: $gutter-m;
            width: 50px;

            @include max-breakpoint(screen-xs-max) {
                display: none;
            }
        }

        &__header-avatar-figure {
            height: 40px;
            margin-bottom: 0;
            margin-right: $gutter-s;
            width: 40px;

            @include min-breakpoint(screen-sm-min) {
                display: none;
                margin-left: $gutter-m;
            }
        }

        &__header {
            display: flex;
            margin-bottom: $gutter-s;
        }

        &__visuals {
            align-self: flex-start;
            margin-bottom: $gutter-m;
            position: relative;
        }

        &__container {
            align-items: flex-start;
            border-bottom: 1px solid $color-grey-lightest;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding-bottom: $gutter-m;
            width: 100%;
        }

        &__author {
            align-items: center;
            color: $color-grey-darkest;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $gutter-xs;
        }

        &__author-name {
            &[href] {
                &:hover {
                    color: $color-base;
                    text-decoration: underline;
                }
            }
        }

        &__author-date {
            margin-left: $gutter-s;

            &:before {
                background: $color-grey-dark;
                border-radius: 50%;
                content: ' ';
                display: inline-flex;
                height: 5px;
                margin-right: $gutter-s;
                width: 5px;
            }
        }

        &__author-date-link {
            &:hover {
                color: $color-base;
            }
        }

        &__text-wrapper {
            display: flex;
            width: 100%;
        }

        &__unread-indicator {
            margin-right: $gutter-s;
            transform: translateY(.5rem);
        }

        &__text {
            flex-grow: 1;
            line-height: 1.5;
            max-width: $max-content-width;
            width: 100%;

            &--deleted {
                font-size: .9rem;
                font-style: italic;
                opacity: .7;
            }
        }

        &__bookmark-action {
            border-radius: 50%;
            margin-right: $gutter-m;
        }

        &__like {
            margin-left: auto;

            &:before {
                display: none;
            }
        }

        &__soft-actions {
            margin-top: $gutter-m;
        }

        &__edit-form,
        &__reply-form {
            align-items: flex-end;
            background: $color-grey-lighter;
            border-radius: 12px;
            flex-direction: column;
            padding: $gutter-m;
        }

        &__reply-form {
            margin-top: $gutter-l;
        }

        &__actions-list {
            margin-left: auto;
            margin-top: $gutter-m;
        }
        &.dashboard-mode-enabled {
             padding-bottom: 0px;

            .comment__actions-list {
                display: none;
            }
            .comment__text {
                max-width: 100%;
            }
            .comment__container:hover {
                .comment__actions-list {
                    display: flex;
                    margin-left: 0;
                    margin-top: 8px;
                }
            }

        }
    }

    .thread {
        &__reply {
            .comment {
                &__avatar-figure,
                &__header-avatar-figure {
                    height: 32px;
                    width: 32px;
                }
            }
        }
    }

</style>

<style lang="scss">
    .comment {
        &__text {
            p {
                word-break: break-word;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                color: $color-base;
                text-decoration: underline;
            }
        }
    }
</style>
