import LowerAllHands from './LowerAllHands';
import AccessBackroom from './AccessBackroom';
import AccessInviteLinks from './AccessInviteLinks';
import SendMessagesInGeneralChat from './SendMessagesInGeneralChat';
import Present from './Present';
import PerformPresentCheck from './PerformPresentCheck';
import ShareVideo from './ShareVideo';
import ShareScreen from './ShareScreen';
import DrawOnWhiteboard from './DrawOnWhiteboard';
import ControlWhiteboard from './ControlWhiteboard';
import ClearWhiteboard from './ClearWhiteboard';
import CreateWhiteboardSnapshot from './CreateWhiteboardSnapshot';
import StartPoll from './StartPoll';
import EndPoll from './EndPoll';
import AnswerPoll from './AnswerPoll';
import WatchPollAnswers from './WatchPollAnswers';
import SeePollRespondents from './SeePollRespondents';
import SubscribeToBackroomChat from './Chat/SubscribeToBackroomChat';
import SubscribeToPrivateChatWithModerators from './Chat/SubscribeToPrivateChatWithModerators';
import SubscribeToPrivateChatWithMembers from './Chat/SubscribeToPrivateChatWithMembers';
import SubscribeToModeratorsWithIndividualObserverChat from './Chat/SubscribeToModeratorsWithIndividualObserverChat';
import SendMessagesInChannel from './Chat/SendMessagesInChannel';
import CreateMoments from './CreateMoments';
import ChangeVisibility from './ChangeVisibility';
import DebugFocus from './DebugFocus';
import AccessWaitingRoom from './AccessWaitingRoom';
import SubscribeToSupportChat from './Chat/SubscribeToSupportChat';

export default {
    'lower-all-hands': LowerAllHands,
    'access-backroom': AccessBackroom,
    'access-invite-links': AccessInviteLinks,
    'send-messages-in-general-chat': SendMessagesInGeneralChat,
    present: Present,
    'perform-present-check': PerformPresentCheck,
    'share-video': ShareVideo,
    'share-screen': ShareScreen,
    'draw-on-whiteboard': DrawOnWhiteboard,
    'control-whiteboard': ControlWhiteboard,
    'clear-whiteboard': ClearWhiteboard,
    'create-whiteboard-snapshot': CreateWhiteboardSnapshot,
    'start-poll': StartPoll,
    'end-poll': EndPoll,
    'answer-poll': AnswerPoll,
    'watch-poll-answers': WatchPollAnswers,
    'see-poll-respondents': SeePollRespondents,
    'subscribe-to-backroom-chat': SubscribeToBackroomChat,
    'subscribe-to-support-chat': SubscribeToSupportChat,
    'subscribe-to-moderators-with-individual-observer-chat': SubscribeToModeratorsWithIndividualObserverChat,
    'subscribe-to-private-chat-with-moderators': SubscribeToPrivateChatWithModerators,
    'subscribe-to-private-chat-with-members': SubscribeToPrivateChatWithMembers,
    'send-messages-in-channel': SendMessagesInChannel,
    'create-moments': CreateMoments,
    'change-visibility': ChangeVisibility,
    'debug-focus': DebugFocus,
    'access-waiting-room': AccessWaitingRoom,
};
