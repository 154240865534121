<template>
    <span class="upload-v2__delete" @click="$emit('deleteUpload')">
        <i class="upload-v2__delete-icon fas fa-times" />
    </span>
</template>

<script>
    export default {
        name: 'DeleteIcon',
    };
</script>
