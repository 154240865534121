<template>
    <a
        ref="button"
        class="move-action actions-list__action btn btn--action btn--text"
        tabindex="0"
        data-cy="actions-list-move"
        data-jest="button"
        aria-label="move action"
        href="#"
        @keypress.enter="initTargets"
        @click="initTargets"
    >
        <i class="actions-list__action-icon far fa-arrows" />
        <b-tooltip
            :target="() => $refs.button"
            :disabled="screenWidth <= 900"
            :delay="{ show: 500, hide: 0 }"
            triggers="hover"
            data-jest="tooltip"
        >
            <slot name="tooltip" />
        </b-tooltip>
        <modal-component :show="showMoveModal" @close="showMoveModal = false">
            <template slot="title">
                <slot name="title" />
            </template>
            <template slot="content">
                <slot name="content" />
                <div class="move-action__wrapper">
                    <ul v-if="targets.length" class="move-action__target-list">
                        <li v-for="target in targets" :key="target.id" class="move-action__target">
                            <label class="move-action__target-label" :class="{ 'move-action__target-label--selected': target.id === targetId }" data-jest="target">
                                <input
                                    v-model="targetId"
                                    class="move-action__target-input"
                                    type="radio"
                                    name="move[target]"
                                    :value="target.id"
                                >
                                {{ target.title }}
                            </label>
                        </li>
                    </ul>
                    <div v-else>
                        <loading-spinner />
                    </div>
                </div>

            </template>
            <button class="btn btn--text" @click="showMoveModal = false">
                {{ 'action.cancel'|trans }}
            </button>
            <button
                class="btn btn--primary"
                data-cy="move-confirmation-button"
                :disabled="!targetId"
                data-jest="move"
                @click="move(targetId)"
            >
                <slot name="confirm-label" />
            </button>
        </modal-component>
    </a>
</template>

<script>
    export default {
        name: 'MoveAction',
        props: {
            showModal: {
                type: Boolean,
                required: true,
            },
            targets: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                targetId: null,
                showMoveModal: this.showModal,
            };
        },
        computed: {
            screenWidth() {
                return this.$store.getters['configuration/getSetting']('screenWidth');
            },
        },
        methods: {
            move() {
                this.showMoveModal = false;
                this.$emit('move', this.targetId);
            },
            initTargets() {
                this.showMoveModal = true;
                this.$emit('init-targets');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .move-action {
        &__wrapper {
            max-height: calc(100vh - 300px);
            overflow: auto;
        }

        &__target-list {
            @include reset-list();
            display: flex;
            flex-direction: column;
        }

        &__target {
            display: flex;
            margin-bottom: $gutter-s;
            width: 100%;
        }

        &__target-label {
            border: 1px solid $color-grey-light;
            border-radius: 4px;
            cursor: pointer;
            font-size: $font-s;
            margin: 0;
            padding: $gutter-s;
            transition: all .2s ease-in-out;
            width: 100%;

            &:hover {
                border-color: $color-base;
            }

            &--selected {
                background: $color-base;
                border-color: $color-base;
                color: #fff;
            }
        }

        &__target-input {
            display: none;
        }
    }
</style>
