<template>
    <div class="actions-list__action rating-action">
        <template v-if="canRate">
            <a
                class="rating-action__rate-action btn btn--text btn--action"
                :class="{ 'rating-action__rate-action--rated': rating.user_rating }"
                tabindex="-1"
                data-cy="actions-list-rate"
                aria-label="rating action"
                href="#"
                @click="(e)=>showModalToRate(e)"
            >
                <i v-if="rating.user_rating" class="rating-action__icon fas fa-star" />
                <i v-else class="rating-action__icon far fa-star" />
            </a>
            <modal-component :show="showRateModal" class="rating-action__modal" @close="showRateModal = false">
                <template slot="title">
                    <slot name="rate-label">
                        {{ 'rate.share_vote'|trans }}
                    </slot>
                </template>
                <template slot="content">
                    <div class="rating-action__stars-container">
                        <loading-spinner v-if="loading" class="rating-action__loading-spinner" />

                        <form class="rating-action__form" :class="{ 'rating-action__form--loading': loading }" data-cy="rate-modal-form">
                            <input
                                v-for="index in 5"
                                :id="`${randomString}-${index}`"
                                :key="`input-${index}`"
                                v-model="ratingModel"
                                type="radio"
                                :value="index"
                                :disabled="loading"
                                name="rating"
                                class="rating-action__input"
                                data-cy="rate-modal-rate-button"
                                @change="(e) => rate(index)"
                            >

                            <div class="rating-action__stars">
                                <label
                                    v-for="index in 5"
                                    :key="`label-${6-index}`"
                                    :for="`${randomString}-${6 - index}`"
                                    class="rating-action__label"
                                    :class="{ 'rating-action__label--selected': ratingModel === (6 - index) }"
                                >
                                    <span class="rating-action__star" data-cy="rate-modal-rate-star">
                                        <i class="rating-action__star-icon fas fa-star" />
                                    </span>
                                </label>
                            </div>
                        </form>
                    </div>
                </template>
                <button class="rating-action__close-modal btn btn--primary" data-cy="rate-modal-close" @click="showRateModal = false">
                    {{ 'action.close'|trans }}
                </button>
            </modal-component>
        </template>

        <template v-if="canViewRating">
            <a class="rating-action__show-action btn btn--text btn--action" tabindex="-1" data-cy="actions-list-show-ratings" @click="(e)=>showModal(e)">
                <span class="rating-action__average" data-cy="actions-list-rating-average">
                    {{ rating.average }}
                </span>
                <small class="rating-action__total" data-cy="actions-list-ratings-total">
                    ({{ rating.total }})
                </small>
            </a>
            <modal-component :show="showRatingsModal" class="rating-action__modal" @close="showRatingsModal = false">
                <template slot="title">
                    {{ 'rate.ratings_amount'|transChoice(rating.total) }}
                </template>
                <template slot="content">
                    <div v-if="rating.total === 0" class="rating-action__no-ratings">
                        {{ 'rate.no_ratings'|trans }}
                    </div>
                    <transition-group v-else name="rating-list" class="rating-action__list" tag="ul">
                        <li v-for="(userRating, index) in ratings" :key="index" class="rating-action__list-item">
                            <user-avatar class="rating-action__list-avatar" :user="userRating.user" linked />
                            <div class="rating-action__list-content">
                                <user-name class="rating-action__list-username" :user="userRating.user" linked />
                                <rating-indicator :rating="userRating.rating" :colored="true" class="rating-action__list-rating" />
                            </div>
                        </li>
                    </transition-group>

                    <loading-spinner v-if="loadingRatings" class="rating-action__loading-ratings-spinner" />

                    <div v-else-if="ratings.length < rating.total" class="rating-action__load-more-container">
                        <button class="btn btn--soft" :disabled="loadingRatings" @click="(e)=>loadRatings(e)">
                            {{ 'action.load_more'|trans }}
                        </button>
                    </div>
                </template>
                <button class="rating-action__close-modal btn btn--primary" data-cy="ratings-modal-close" @click="showRatingsModal = false">
                    {{ 'action.close'|trans }}
                </button>
            </modal-component>
        </template>
        <span v-else class="rating-action__show-action rating-action__show-action--disabled btn btn--text btn--action">
            <i v-if="!canRate" class="rating-action__icon fas fa-star" />
            <span class="rating-action__average" data-cy="actions-list-rating-average">
                {{ rating.average }}
            </span>
            <small class="rating-action__total" data-cy="actions-list-ratings-total">
                ({{ rating.total }})
            </small>
        </span>
    </div>
</template>

<script>
    import RatingIndicator from '../Voting/Rating/RatingIndicator.vue';
    import { RandomStringGenerator } from '../../Helpers';

    export default {
        name: 'RatingAction',
        components: {
            RatingIndicator,
        },
        props: {
            rating: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                required: false,
                default: false,
            },
            canRate: {
                type: Boolean,
                required: true,
            },
            canViewRating: {
                type: Boolean,
                required: true,
            },
            ratings: {
                type: Array,
                default() {
                    return [];
                },
            },
            loadingRatings: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                randomString: RandomStringGenerator(),
                ratingModel: this.rating.user_rating,
                showRateModal: false,
                showRatingsModal: false,
            };
        },
        watch: {
            rating(newVal) {
                this.ratingModel = newVal.user_rating;
            },
        },
        methods: {
            rate(rating) {
                if (!this.canRate) {
                    return;
                }

                this.$emit('rate', rating);
            },
            showModal(e) {
                e.preventDefault();
                e.stopPropagation();
                if (this.canViewRating) {
                    if (this.ratings.length === 0) {
                        this.loadRatings(e);
                    }
                    this.showRatingsModal = true;
                }
            },
            showModalToRate(e) {
                e.preventDefault();
                e.stopPropagation();
                if (this.canRate) {
                    this.showRateModal = true;
                }
            },
            loadRatings(e) {
                e.preventDefault();
                e.stopPropagation();
                this.$emit('load-ratings');
            },
        },
    };
</script>

<style lang="scss" scoped>
    $color-rating-star: #f3a446;

   .rating-action {
       display: inline-flex;

       &:first-child {
           margin-left: $gutter-s;
       }

       &__rate-action {
           align-items: center;
           color: $color-rating-star;
           display: inline-flex;
           height: 100%;
       }

       &__show-action {
           align-items: center;
           display: inline-flex;
           height: 100%;

           &--disabled {
               pointer-events: none;
           }
       }

       &__total {
           margin-left: $gutter-xs;

           @media (max-width: 359px) {
               display: none;
           }
       }

       /deep/ .rating-action {
           &__rate-action,
           &__show-action {
               padding-left: $gutter-xs;
               padding-right: $gutter-xs;
           }

           &__show-action {
               margin-right: $gutter-xs;
           }
       }

       &__form {
           align-items: center;
           background: $color-grey-lightest;
           border-radius: 4px;
           display: flex;
           justify-content: center;
           padding: $gutter-s;
           transition: opacity .2s ease-in-out;

           &--loading {
               opacity: .1;
           }
       }

       &__input {
           display: none;
       }

       &__stars {
           display: flex;
           flex-direction: row-reverse;
           justify-content: center;
       }

       &__label {
           color: $color-grey-light;
           font-size: 26px;
           margin-bottom: 0;

           &:not(:first-child) {
               margin-right: $gutter-s;
           }

           &--selected {
               animation: ratingScale .6s ease-in-out;
               color: $color-rating-star;

               ~ * {
                   color: $color-rating-star;
               }
           }

           &:hover {
               color: $color-base;
               cursor: pointer;

               ~ * {
                   color: $color-base;
               }
           }
       }

       &__loading-spinner {
           left: 50%;
           position: absolute;
           top: 50%;
           transform: translateY(-50%) translateX(-50%);
       }

       // Modal
       &__list {
           display: flex;
           flex-direction: column;
           list-style: none;
           margin-bottom: 0;
           padding: 0 0 $gutter-l;
       }

       &__list-item {
           align-items: center;
           border-bottom: 1px solid $color-grey-lighter;
           display: flex;
           padding: $gutter-s 0;
       }

       &__list-avatar {
           flex-shrink: 0;
           height: 36px;
           margin: 0 0 0 $gutter-s;
           width: 36px;
       }

       &__list-content {
           display: flex;
           flex-direction: column;
           margin-left: $gutter-m;
       }

       &__list-username {
           color: $color-grey-dark;
           flex-grow: 1;
           text-decoration: none;
       }

       &__list-rating {
           margin-top: $gutter-xxs;
       }

       &__loading-ratings-spinner {
           margin: $gutter-m auto;
       }

       &__load-more-container {
           display: flex;
           justify-content: center;
       }

       &__modal-title {
           text-align: center;
           width: 100%;
       }
   }

   .rating-list {
       &-enter-active,
       &-leave-active {
           overflow: hidden;
           transition: all 1s;
       }

       &-enter,
       &-leave-to {
           height: 53px;
           opacity: 0;
       }

       &-enter {
           transform: translateY(30px);
       }

       &-leave-to {
           height: 0;
       }
   }
</style>

<style lang="scss">
    .rating-action {
        &__modal {
            .modal {
                &__body {
                    overflow: auto;
                }
            }
        }
    }
</style>
