var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-v2 upload-v2--small" },
    [
      _c(
        "transition-group",
        {
          staticClass: "upload-v2__list",
          attrs: { name: "upload-v2-list", tag: "ol" }
        },
        [
          _vm.allowUpload
            ? _c("file-input", {
                key: "upload-input",
                attrs: {
                  uploader: _vm.uploader,
                  multiple: _vm.multiple,
                  disabled: _vm.isDisabled,
                  "disabled-reason": _vm.disabledReason
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.files, function(file, index) {
            return _c(
              "li",
              { key: file.id, staticClass: "upload-v2__item" },
              [
                file.status === "submitted"
                  ? _c("upload-progress", {
                      attrs: {
                        id: file.id,
                        uploader: _vm.uploader,
                        filename: file.filename
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                file.status === "upload successful"
                  ? _c(
                      "upload",
                      {
                        attrs: {
                          name: _vm.name,
                          file: file.response,
                          "max-files": _vm.maxFiles
                        }
                      },
                      [
                        _c("delete-icon", {
                          attrs: { slot: "top" },
                          on: {
                            deleteUpload: function($event) {
                              return _vm.deleteUpload(index)
                            }
                          },
                          slot: "top"
                        }),
                        _vm._v(" "),
                        _vm.multiple
                          ? _c("position-controls", {
                              attrs: {
                                slot: "footer",
                                position: index,
                                total: _vm.files.length
                              },
                              on: {
                                moveLeft: function($event) {
                                  return _vm.moveLeft(index)
                                },
                                moveRight: function($event) {
                                  return _vm.moveRight(index)
                                }
                              },
                              slot: "footer"
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                file.status === "upload failed"
                  ? _c(
                      "upload-errors",
                      { attrs: { errors: file.response.errors } },
                      [
                        _c("delete-icon", {
                          attrs: { slot: "top" },
                          on: {
                            deleteUpload: function($event) {
                              return _vm.deleteUpload(index)
                            }
                          },
                          slot: "top"
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }