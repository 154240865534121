var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "toasts" },
    [
      _c(
        "transition-group",
        { staticClass: "toasts__list", attrs: { tag: "ul", name: "toasts" } },
        _vm._l(_vm.toasts, function(toast) {
          return _c("toast-component", {
            key: toast.id,
            staticClass: "toasts__toast",
            attrs: {
              dismissible: true,
              "redirect-url": toast.redirectUrl,
              message: toast.message,
              tag: "li",
              type: toast.type,
              "contains-html": toast.containsHtml
            },
            on: {
              remove: function($event) {
                return _vm.removeToast(toast)
              },
              accept: function($event) {
                return _vm.redirect(toast)
              }
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }