var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      staticClass: "journal-sidebar allow-swipe js-allow-scroll",
      attrs: { id: "sidebar" }
    },
    [
      _c("div", { staticClass: "widget-filters" }, [
        _c("h2", [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("trans")(
                  "journal.filter_options",
                  {},
                  "journal_frontend"
                )
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    id: "journal-sidebar-input-group-1",
                    label: _vm.$trans(
                      "journal.sidebar.specific_keyword",
                      {},
                      "journal_frontend"
                    ),
                    "label-for": "journal-sidebar-input-1"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "journal-sidebar-input-1",
                      type: "text",
                      placeholder: "Search for",
                      required: ""
                    },
                    model: {
                      value: _vm.formValues.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.formValues, "keyword", $$v)
                      },
                      expression: "formValues.keyword"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    id: "journal-sidebar-input-group-2",
                    label: _vm.$trans(
                      "journal.specific_groups",
                      {},
                      "journal_frontend"
                    ),
                    "label-for": "journal-sidebar-input-2"
                  }
                },
                [
                  _c("b-form-select", {
                    staticClass: " form-control custom-select",
                    attrs: {
                      id: "input-2",
                      options: _vm.userGroupList,
                      label: _vm.$trans(
                        "journal.specific_groups",
                        {},
                        "journal_frontend"
                      ),
                      "aria-label": _vm.$trans(
                        "journal.specific_groups",
                        {},
                        "journal_frontend"
                      ),
                      size: "md"
                    },
                    model: {
                      value: _vm.formValues.userGroup,
                      callback: function($$v) {
                        _vm.$set(_vm.formValues, "userGroup", $$v)
                      },
                      expression: "formValues.userGroup"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    id: "input-group-3",
                    label: _vm.$trans(
                      "journal.startdate",
                      {},
                      "journal_frontend"
                    ),
                    "label-for": "date_start"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formValues.startDate,
                        expression: "formValues.startDate"
                      }
                    ],
                    staticClass: "datepicker form-control",
                    attrs: {
                      id: "date_start",
                      type: "date",
                      name: "date_start",
                      size: "15",
                      placeholder: "dd/mm/yyyy"
                    },
                    domProps: { value: _vm.formValues.startDate },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formValues,
                          "startDate",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-4",
                  attrs: {
                    id: "input-group-4",
                    label: _vm.$trans(
                      "journal.enddate",
                      {},
                      "journal_frontend"
                    ),
                    "label-for": "date_end"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formValues.endDate,
                        expression: "formValues.endDate"
                      }
                    ],
                    staticClass: "datepicker form-control",
                    attrs: {
                      id: "date_end",
                      type: "date",
                      name: "date_end",
                      size: "15",
                      placeholder: "dd/mm/yyyy"
                    },
                    domProps: { value: _vm.formValues.endDate },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.formValues, "endDate", $event.target.value)
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "button-group mb-4 form_buttons" }, [
            _c(
              "button",
              {
                staticClass: "form__button btn btn--primary",
                attrs: { type: "submit" },
                on: { click: _vm.updateResults }
              },
              [
                _c("i", { staticClass: "fa-light fa-arrows-rotate mr-xs" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm._f("trans")(
                        "journal.update_results",
                        {},
                        "journal_frontend"
                      )
                    ) +
                    "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "form__button btn btn--text",
                on: { click: _vm.resetFilter }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm._f("trans")(
                        "journal.reset_filters",
                        {},
                        "journal_frontend"
                      )
                    ) +
                    "\n                "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "widget-participants" }, [
        _c("h2", [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("trans")("journal.participants", {}, "journal_frontend")
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "ul",
              {
                staticClass: "liking__list",
                attrs: { name: "likes-list", tag: "ul" }
              },
              _vm._l(_vm.participants, function(participant, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "liking__list-item",
                    on: {
                      click: function($event) {
                        return _vm.setUserFilter(participant.id)
                      }
                    }
                  },
                  [
                    _c("user-avatar", {
                      staticClass: "liking__list-avatar",
                      attrs: { user: participant }
                    }),
                    _vm._v(" "),
                    _c("user-name", {
                      staticClass: "liking__list-username",
                      attrs: { user: participant }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.participants.length &&
            _vm.participantsPagination.total > _vm.participantsPagination.limit
              ? _c("pagination-component", {
                  staticClass: "article-overview__pagination",
                  attrs: {
                    "current-page": _vm.participantsPagination.page,
                    "total-rows": _vm.participantsPagination.total,
                    "per-page": _vm.participantsPagination.limit
                  },
                  on: { change: _vm.changePage }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }