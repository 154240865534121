<template>
    <a
        ref="button"
        tabindex="0"
        class="actions-list__action bookmark-action  btn--text"
        :class="textMode ? '' : 'btn'"
        data-cy="actions-list-bookmark"
        aria-label="bookmark action"
        href="#"
        @keypress.enter="toggleBookmark"
        @click="toggleBookmark"
    >
        <template v-if="bookmarked">
            <i class="bookmark-action__toggle-icon--full fas fa-bookmark" data-jest="unbookmark-icon" />
            <span v-if="textMode">{{ 'action.unbookmark'|trans() }}</span>
            <b-tooltip :target="() => $refs.button" :disabled="screenWidth <= 900 || !showTooltip" :delay="{ show: 500, hide: 0 }" triggers="hover">
                {{ 'action.unbookmark'|trans() }}
            </b-tooltip>
        </template>
        <template v-else>
            <i class="bookmark-action__toggle-icon--open far fa-bookmark" data-jest="bookmark-icon" />
            <span v-if="textMode">{{ 'action.bookmark'|trans() }}</span>
            <b-tooltip :target="() => $refs.button" :disabled="screenWidth <= 900 || !showTooltip" :delay="{ show: 500, hide: 0 }">
                {{ 'action.bookmark'|trans() }}
            </b-tooltip>
            <modal-component class="bookmark-action__confirm-modal" :show="isModalVisible" data-jest="modal" @close="hideModal">
                <template slot="title">
                    {{ 'bookmark.category.name'|trans() }}
                </template>
                <template slot="content">
                    <v-select
                        :options="categories"
                        :placeholder="'bookmark.category.placeholder'|trans()"
                        :dir="dir"
                        taggable
                        push-tags
                        select-on-tab
                        @input="input"
                    >
                        <template slot="no-options">
                            {{ 'bookmark.category.no_categories'|trans() }}
                        </template>
                    </v-select>
                    <span v-if="hasValidationErrors && validate" class="bookmark-action__error error-text" data-jest="validation-error">
                        {{ 'bookmark.category.error'|trans() }}
                    </span>
                </template>
                <button type="button" class="btn btn--secondary" data-jest="cancel" @click="hideModal">
                    {{ 'action.cancel'|trans() }}
                </button>
                <button
                    type="button"
                    class="btn btn--primary"
                    :disabled="selectedCategory === null || hasValidationErrors"
                    data-jest="submit"
                    @click="bookmark"
                >
                    {{ 'action.ok'|trans() }}
                </button>
            </modal-component>
        </template>
    </a>
</template>

<script>
    import vSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    export default {
        name: 'BookmarkAction',
        components: {
            vSelect,
        },
        props: {
            bookmarked: {
                type: Boolean,
                required: true,
            },
            textMode: {
                type: Boolean,
                required: false,
                default: false,
            },
            showTooltip: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        data() {
            return {
                isModalVisible: false,
                selectedCategory: null,
                validate: false,
                dir: document.dir,
            };
        },
        computed: {
            screenWidth() {
                return this.$store.getters['configuration/getSetting']('screenWidth');
            },
            trimmedCategory() {
                if (!this.selectedCategory) {
                    return null;
                }

                return this.selectedCategory.trim();
            },
            hasValidationErrors() {
                if (!this.selectedCategory) {
                    return true;
                }

                return this.trimmedCategory.trim().length <= 0 || this.trimmedCategory.trim().length > 30;
            },
            categories() {
                return this.$store.getters['bookmarkCategories/allCategories'];
            },
        },
        methods: {
            toggleBookmark() {
                if (this.bookmarked) {
                    this.$emit('unbookmark');
                } else {
                    this.showModal();
                }
            },
            bookmark() {
                this.validate = true;
                if (this.hasValidationErrors) {
                    return;
                }

                this.hideModal();
                this.$emit('bookmark', this.trimmedCategory);
            },
            showModal() {
                this.isModalVisible = true;
                this.$store.dispatch('bookmarkCategories/fetchCategories');
            },
            hideModal() {
                this.isModalVisible = false;
            },
            input(value) {
                this.selectedCategory = value;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .bookmark-action {
        &__toggle-icon {
            &--open {
                color: #999;
            }

            &--full {
                color: var(--color-primary);
            }
        }

        &__error {
            display: inline-block;
            line-height: 1.2;
            margin-top: $gutter-s;
        }
    }
</style>

<style lang="scss">
    [dir='rtl'] {
        .vs__actions {
            .vs__clear {
                margin-right: 6px;
            }
        }
    }
</style>
