<template>
    <div v-if="filters.length > 1 && activeFilter" class="custom-filter">
        <b-dropdown
            variant="link"
            right
            class="custom-filter__dropdown-container"
            toggle-class="btn--sm btn--soft"
        >
            <template slot="button-content">
                <i v-if="showIcon" class="custom-filter__dropdown-filter-icon fas fa-filter" />
                {{ activeFilter.name }}
            </template>
            <b-dropdown-item
                v-for="filter in filters"
                :key="filter.id"
                :data-cy="`filter-${filter.id ? filter.id : filter.name}`"
                data-jest="filter"
                @click="changeFilter(filter)"
            >
                {{ filter.name }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        name: 'FilterDropdown',
        props: {
            filters: {
                type: Array,
                required: true,
            },
            activeFilter: {
                type: Object,
                required: false,
                default() {
                    return this.filters[0];
                },
            },
            showIcon: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        methods: {
            changeFilter(filter) {
                if (this.activeFilter.id === filter.id) {
                    return;
                }

                this.$emit('filter', filter);
            },
        },
    };
</script>

<style lang="scss">
    .custom-filter {
        .dropdown-menu {
            max-height: 250px;
            overflow: auto;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                max-height: none;
                top: 100% !important;
                transform: none !important;
            }
        }
    }
</style>
