/* istanbul ignore file */
import BreadcrumbApi from './BreadcrumbApi';

export default {
    install(Vue, { router, store }) {
        router.afterEach(async (to, from) => {
            if (!from || !from.name) {
                // Probably a full page refresh, ignore...
                return;
            }

            try {
                const payload = {
                    requestUri: to.fullPath,
                    _token: store.getters['user/getCSRFToken'],
                };

                await BreadcrumbApi.add(payload);
            } catch (error) {
                // This should never crash the actual application.
            }
        });
    },
};
