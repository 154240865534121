<template>
    <section class="journal-entry-wrapper journal-overview">
        <create-new-entry v-if="$can('can-create-journal')"/>
        <smooth-reflow tag="div" class="journal-overview__entries">
            <template v-if="isEntryLoading">
                <div class="journal-overview__entries-loader">
                    <loading-spinner />
                </div>
            </template>
            <template v-else>
                <my-journal v-for="(entry, index) in entries" :key="index" :entry="entry" />
            </template>
        </smooth-reflow>
        <pagination-component
            v-if="entries.length && entriesPagination.total > entriesPagination.limit"
            class="article-overview__pagination"
            :current-page="entriesPagination.page"
            :total-rows="entriesPagination.total"
            :per-page="entriesPagination.limit"
            @change="changeEntriesPage"
        />
    </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import CreateNewEntry from './CreateNewEntry.vue';
    import MyJournal from './MyJournal.vue';

    export default {
        components: {
            CreateNewEntry,
            MyJournal,
        },
        props: {
            entry: {
                type: Array,
                default: () => {},
            },
        },
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                entries: 'journal/getEntries',
                entriesPagination: 'journal/getEntriesPagination',
                isEntryLoading: 'journal/isEntryLoading',
            }),
        },
        methods: {
            ...mapActions('journal', ['fetchEntries']),
            async changeEntriesPage(page) {
                const { id } = this.$route.params;
                await this.fetchEntries({ assignmentId: id, isUserView: true, page });
            },
        },
    };
</script>

<style scoped lang="scss">
    .journal-entry-wrapper {
        width: 100%;
    }
    .journal-overview {
        &__entries-loader {
            align-items: center;
            background: $color-grey-lightest;
            border-radius: var(--main-border-radius);
            display: inline-flex;
            grid-column: 1/-1;
            height: 80px;
            justify-content: center;
            width: 100%;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                margin: $gutter-m;
            }
        }
    }
</style>
