import { render, staticRenderFns } from "./IframeConsent.vue?vue&type=template&id=0dfa4f34&scoped=true&"
import script from "./IframeConsent.vue?vue&type=script&lang=js&"
export * from "./IframeConsent.vue?vue&type=script&lang=js&"
import style0 from "./IframeConsent.vue?vue&type=style&index=0&id=0dfa4f34&lang=scss&scoped=true&"
import style1 from "./IframeConsent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dfa4f34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/platform/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0dfa4f34')) {
      api.createRecord('0dfa4f34', component.options)
    } else {
      api.reload('0dfa4f34', component.options)
    }
    module.hot.accept("./IframeConsent.vue?vue&type=template&id=0dfa4f34&scoped=true&", function () {
      api.rerender('0dfa4f34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Core/CookieConsent/IframeConsent.vue"
export default component.exports