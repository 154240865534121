<template>
    <div class="upload-v2__inner">
        <slot name="top" />

        <div class="upload-v2__body">
            <figure v-if="file.type == 'image'" class="upload-v2__figure">
                <img class="upload-v2__img" :src="thumbnail" :alt="file.original_name">
            </figure>

            <figure v-else-if="file.type == 'video'" class="upload-v2__figure">
                <span class="upload-v2__video-icon fas fa-play" />
                <div class="upload-v2__video-filename">
                    <span>{{ file.original_name }}</span>
                </div>
            </figure>

            <div v-else class="upload-v2__document">
                <span class="upload-v2__document-icon file-icon file-icon--md" :data-extension="file.extension" />
                <div class="upload-v2__document-filename">
                    <span>{{ file.original_name }}</span>
                </div>
            </div>
            <span class="upload-v2__limit-msg">
                {{ 'uploader.max_files_limit_reached'|trans({ '%maxFiles%': maxFiles }, 'uploader_frontend') }}
            </span>
        </div>

        <slot name="footer" />

        <input class="upload-v2__item-hidden" type="hidden" :name="name" :value="file.id">
    </div>
</template>

<script>
    export default {
        name: 'Upload',
        props: {
            name: {
                type: String,
                required: true,
            },
            file: {
                type: Object,
                required: true,
            },
            maxFiles: {
                type: Number,
                default: 25,
            },
        },
        computed: {
            thumbnail() {
                return this.file.thumbnails['150x150'] || this.file.original_url;
            },
        },
    };
</script>
