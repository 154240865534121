<template>
    <a
        ref="button"
        class="actions-list__action btn btn--text btn--action"
        tabindex="0"
        data-cy="actions-list-edit"
        data-jest="button"
        aria-label="edit action"
        href="#"
        @keypress.enter="$emit('edit')"
        @click="$emit('edit')"
    >
        <i class="actions-list__action-icon fas fa-pencil" />
        <b-tooltip :target="() => $refs.button" :disabled="screenWidth <= 900" :delay="{ show: 500, hide: 0 }" data-jest="tooltip">
            {{ 'action.edit'|trans() }}
        </b-tooltip>
    </a>
</template>

<script>
    export default {
        name: 'EditAction',
        computed: {
            screenWidth() {
                return this.$store.getters['configuration/getSetting']('screenWidth');
            },
        },
    };
</script>
